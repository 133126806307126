#select-community {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background:  url(/dist/assets/images/select-community-bg.svg) #F3FAFF no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;

  .sc__back-button {
    background: #fff;
    box-shadow: 0 4px 20px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    width: 120px;
    height: 36px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #29394D;
    border: 1px solid #6D7F92;
    cursor: pointer;

    &_img {
      margin-right: 1rem;
      height: 13px;
      margin-bottom: 4px;
    }

    &:hover {
      color: #6D7F92;
      border: 1px solid rgba(109, 127, 146, 0.2);
      .sc__back-button_img{
        filter: invert(49%) sepia(10%) saturate(793%) hue-rotate(170deg) brightness(98%) contrast(89%);
      }
    }
  }

  .sc__container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 3rem;
    padding: 2rem 0 2rem 0;
  }

  .sc__header {
    width: 94%;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F1F2F4;
    z-index: 1;

    * {
      font-style: normal;
      margin: 0;
    }

    h3 {
      line-height: 28px;
      color: #29394D;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 20px;
    }

    span {
      color: #6D7F92;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .sc__header_logo {
    background: url(/dist/assets/svg/logo.svg) white center no-repeat;
    background-size: 80px;
    border-radius: 50%;
    position: relative;
    bottom: 180px;
    width: 10rem;
    height: 10rem;
  }

  .sc__communities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -8rem;
  }

  .sc__community {
    justify-content: flex-start;
    height: auto;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 0 4rem 6rem 4rem;
    &-title {
      position: relative;
      flex-direction: column;
      display: flex;
      align-items: center;
      width: 14rem;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #29394D;
      text-transform: uppercase;
      font-family: Roboto;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
      overflow: hidden;
      &.selected, &:hover {
        color: #18A0FB;
      }
    }

    &-logo {
      position: relative;
      cursor: pointer;
      border-radius: 50%;
      width: 14rem;
      height: 14rem;
      margin-bottom: 1rem;
      text-align: center;
      box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
      border: 3px solid #F1F2F4;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: inline-block;
        vertical-align: middle;
        /* width: 14rem;
        height: 14rem; */
        max-width: 140px;
        max-height: 140px;
        /* top: 2rem; */
        position: relative;
        user-select: none;
      }
    }

    &-container {
      position: absolute;
      width: 291px;
      height: 173px;
      margin-top: 6.7rem;
      background: #F1F2F4;
      border-radius: 20px;
    }

    &-unauthorised {
      width: 250px;
      position: absolute;
      top: 20rem;

      img {
        left: 20px;
      }

      span {
        padding-left: 9px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
        position: absolute;
      }
    }

    &.disabled {
      .sc__community-title, .sc__community-logo, .sc__community-container {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
      }
    }

    &.selected, &:hover {
      .sc__community-title, .sc__community-container {
        color: #18A0FB;
        opacity: 1;

      }

      .sc__community-logo {
        border-color: #18A0FB;
        background-color: #c7e8fe;
      }
    }
  }


}
