.page-header {
    position: relative;
    height: 96px;
    text-transform: uppercase;
    background: #FFFAF3;
    border-radius: 0px 0px 10px 10px;
    margin: 0 16px;
    display: flex;
    margin-bottom: 1rem;
    z-index: 0;

    &__icon {
         display: flex;
         width: 9%;
         justify-content: flex-end;
         position: relative;

        &::after {
             content: '';
             position: absolute;
             display: block;
             right: 0;
             top: 50%;
             transform: translateY(-50%);
             height: 60px;
             width: 1px;
             background-color: #FFFFFF;
             box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
         }
        img {
            width: 36px;
            padding-right: 12px;
            margin: 18px 0;
        }
    }

    &__info {
         width: 51%;
         display: flex;
         align-items: center;
         padding-left: 34px;

        * {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            margin: 0;
        }

        h3 {
            font-size: 24px !important;
            line-height: 28px;
            color: #3C4E64;
            font-weight: normal !important;
        }

        p {
            font-size: 12px;
            line-height: 14px;
            color: #3C4E64;
        }
    }

    &__children {
         width: 50%;
         display: flex;
         align-items: center;
         justify-content: flex-end;
         padding-right: 5%;
         text-transform: initial;
    }

    .btn-drop-down {
        &__expand {
            padding-right: 38px;
            position: relative;
            margin-left: 15px;
            i {
                position: absolute;
                top: 50%;
                right: 0;
                font-size: 32px;
                transform: translateY(-50%);
                margin-right: 8px;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 44px;
                width: 1px;
                background: rgba(222, 129, 47, 0.25);
            }
        }
        &__item {
            position: absolute;
            top: 72px;
            right: 70px;
            transition: top .3s, border-radius 0.6s;
            z-index: -1;
            color: #29394d !important;
            font-size: 14px;
            font-weight: 400;
            border-radius: inherit;
            @for $i from 1 to 10 {
                &:nth-of-type($i) {
                    top: calc(($i - 2) * 44px + 32px);
                    transition-delay: calc(($i - 2) * .2s);
                    z-index: -$i;
                }
            }
            &.reduced {
                top: 26px;
                box-shadow: none;
                border-radius: 50px;
            }
        }
    }
}
