.ttp-release-container {
    h1 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        text-transform: uppercase;
        color: #3C4E64;
        background-color: #FFFAF3;
        padding: 20px 200px;
        margin: 0;
        text-align: right;
        height: 60px;
    }

    .ttp-release-wrapper {
        ul:first-of-type {
            margin: 0;
            color: #FFFAF3;
            list-style-type: none;
            text-transform: initial;
            background-color: #e2e2e2;

            li {
                text-align: right;
                float: right;
                width: 340px;
                padding: 30px 10px;
                display: inline-block;
                font-size: 1.2em;
                white-space: nowrap;
            }

            li:first-child {
                text-align: left;
                float: left;

                img {
                    width: 200px;
                    height: auto;
                }
            }
        }

        ul {
            padding-left: 5em;
        }

        blockquote:first-of-type {
            background: #f9f9f9;
            border-left: 10px solid #ccc;
            margin: 1.5em 10px;
            padding: 0.5em 10px;
            quotes: "\201C""\201D""\2018""\2019";

            &:before {
                color: #ccc;
                content: open-quote;
                font-size: 4em;
                line-height: 0.1em;
                margin-right: 0.25em;
                vertical-align: -0.4em;
            }

            &:after {
                content: " ";
            }

            p {
                display: inline;
            }
        }

        & > h2 {
            color: #F7953E;
            text-transform: capitalize;
            font-weight: bold;
            clear: both;

            &:before {
                font-family: 'Material Icons';
                font-weight: 400;
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                word-wrap: normal;
                -moz-font-feature-settings: 'liga';
                font-feature-settings: 'liga';
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
                content: "chevron_right";
            }
        }

        & > h3 {
            color: #5081be;
            text-transform: capitalize;
            font-weight: bold;
            clear: both;
        }

        & > .go-back {
            background-color: #FFFAF3;
            color: #F7953E !important;
            padding: 10px;
            border-radius: 3px;
            text-align: center;
            margin-top: 20px;
        }

        img {
            width: 65%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
