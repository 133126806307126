@import "dynamic-chart.pcss";

.accordion {
  margin: 3em auto;
  width: 100%;

  .toggle {
    display: none;
  }

  .option {
    position: relative;
    margin-bottom: 1em;
    border: 1px solid #F8F9FA;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.02);
    border-radius: 5px;
  }

  .title,
  .content {
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.2s;
  }

  .title {
    padding: .5rem 1rem;
    display: block;
    background: #F1F2F4;
    border-bottom: 1px solid #F1F2F4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Roboto", "Helvetica","Arial",sans-serif;
    line-height: 44px;
    letter-spacing: 0.02em;
    height: 60px;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #29394D;

    &:after,
    &:before {
      content: '';
      position: absolute;
      right: 1.25em;
      top: 1.25em;
      width: 2px;
      height: 0.75em;
      background-color: #29394D ;
      transition: all 0.2s;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  .content {
    background: #F8F9FA;
    height: 0;

    .mdl-grid {
      background: inherit;
      padding-bottom: unset;
      canvas {
        background: #fff;
        padding-bottom: .5rem;
      }
    }

    p {
      margin: 0;
      padding: 0.5em 1em 1em;
      font-size: 0.9em;
      line-height: 1.5;
    }
  }

  .toggle:checked {
    & + .title {
      background: #F8F9FA;
    }

    & + .title + .content {
      display: inline;
    }

    & + .title:before {
      transform: rotate(90deg) !important;
    }
  }

  .form {
    justify-content: center;
    .mdl-grid {
      width: 100%;
    }

    #dynamic-chart-type {
      height: 64px;
      margin: 0;
      width: 100%;
      font-size: 16px;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23F7953E%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;
    }
  }
}

#population-overview {

  .mdl-grid {
    padding-bottom: 2rem;
  }

  .total {
    h4 {
      font-size: 14px;
      margin-bottom: 0;
      margin-top: 2rem;
    }

    p {
      font-size: 70px;
      margin: 0;
      padding: 0 0 0 1rem;
      white-space: nowrap;
      line-height: 1;
    }
  }

  .gender-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      height: 120px;
    }

    &.female {
      h4 {
        color: #fb185c;
      }
    }
    &.male {
      h4 {
        color: #18a0fb;
      }
    }

    .stats {
      padding: 0 0 0 1rem;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        margin-bottom: 0;
      }
      h4 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}