.ttp-hq-container {
    background-color: #FAFBFB;
    padding: 30px 45px;

    .ttp-width {
        max-width: 91%;
    }

    .folder-employee-form {
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
        border-radius: 5px;
        padding: 1em;

        .role-type {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #6D7F92;
        }
        .role-principal {
            &__label {
                padding-right: 10px;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 23px;
                color: #3C4E64;
                &.active {
                    color: #E68443;
                }
            }

        }
    }


    .add-employee {
        display: inline-flex;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #3C4E64 !important;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
        border-radius: 5px;
        margin-left: 10px;
        padding-right: 10px;
        &-icon {
            border: none;
            width: 34px;
            height: 34px;
        }
    }


    .delete-employee_dialog {
        display: flex;
        padding: 20px;
        padding-top: 40px;
        justify-content: center;
    }
    .hq-button {
        line-height: 20px;
        height: 47px;
        margin-left: 10px;
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5;
        }
        &.hq-small {
            line-height: 15px;
            height: 35px;
            margin-left: 7px;
            padding: 12px 15px;
        }
    }

    .hq-content__header {
        padding: 15px 20px;
        padding-top: 50px;
        &-title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-transform: uppercase;
            color: #EA944D;
            display: block;
            padding-top: 10px;
        }
        &-subtitle {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #6D7F92;
            display: block;
            padding-top: 10px;
        }
    }
}

.hq-page-header {
    margin: 0;
    border-bottom: 1px solid rgba(251, 202, 158, 0.5);;
    border-radius: 0px;
}

.hq-steps {
    display: flex;
    list-style-type: none;
    background-color: #FFF;
    padding-left: 22px;
    position: absolute;
    margin: 0;
    margin-top: -28px;
    margin-left: 3px;
    background-color: transparent;
    .hq-step-item {
        display: flex;
        align-items: center;
        width: 220px;
        height: 56px;
        background-color: #F8F9FA;
        line-height: 56px;
        font-size: 1.2em;
        font-weight: 500;
        text-transform: uppercase;
        color: rgba(109, 127, 146, 0.5);
        margin-left: -25px;
        /* border-top: 4px solid #FFF; */
        border-bottom: 4px solid #FFF;
        .hq-step-text {
            background-color: #F8F9FA;
            width: 100%;
            text-align: center;
        }
        .hq-step-number {
            width: 100%;
            text-align: center;
            font-size: .8em;
            width: 30px;
            height: 22px;
            border: 4px solid #F1F2F4;
            border-radius: 50%;
            color: rgba(109, 127, 146, 0.5);
            background: linear-gradient(180deg, #F8F9FA 0%, #F1F2F4 100%);
        }
    }

    .hq-step-item::before {
        content: '';
        top: 0;
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 28px solid #FFF;
    }

    .hq-step-item-elem {
        display: flex;
    }
    .hq-step-item-elem.is-active {
        .hq-step-text  {
            color: #FFF;
            background-color: #F7953E;
        }
        .hq-step-number{
            color: #FFF;
            background: linear-gradient(180deg, #F7953E 0%, #EA944D 100%);
        }
        .hq-step-item {
            background-color: #F7953E;
        }

        &.is-current{
            .hq-step-text  {
                background-color: #E27338;
            }
            .hq-step-number{
                background: linear-gradient(180deg, #E27338 0%, #EA944D 100%);
            }
            .hq-step-item {
                background-color: #E27338;
            }
        }
    }

    .hq-step-item-elem::after {
        content: '';
        top: 0;
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 28px solid #F8F9FA;
        margin-right: 4px;
        /* margin-top: 4px; */
    }
    .hq-step-item-elem.is-active::after {
        border-left: 28px solid #F7953E;

    }
    .hq-step-item-elem.is-active.is-current::after {
        border-left: 28px solid #E27338;
    }
}

.ttp-form__hq-container {
    background-color: #FFF;
    margin-top: 40px;

    input:read-only{
        cursor: not-allowed;
        opacity: .5;
    }

    .ttp-form {
        position: relative;
    }

    .ttp-form__field{
        margin-top: 5px;
    }

    .ttp-form__label {
        .tooltip-help {
            font-size: 17px;
            color: #3C4E64;
        }
    }
    .ttp-form .ttp-form-filled {
        background-color: #FFFAF3;
        border: 1px solid rgba(247, 149, 62, 0.5);
    }

    .ttp-folder-langue_preferences {
        margin-top: 0;

        &_wrapper {
            height: 3.1rem;
            padding: 7px;

            .drop-down, .drop-up {
                color: #000;
                font-size: 28px;
                /* height: 40px; */
                opacity: .5;
            }
        }
    }

    #iban-group #iban-fields-wrapper {
        margin-top: 0;

        .iban-bloc {
            position: relative;
            margin-top: 0;
        }

        .remove-iban {
            top: 0px;
            right: 3px;
        }

        .check-iban {
            left: auto;
            right: 0;
            margin-left: auto;
            margin-top: 15px;
            margin-right: 5px;
        }
    }

    #iban-group i.add-iban, i.add-address {
            border: 1px solid #F1F2F4;
            border-radius: 5px;
            outline: none;
            background-color: #F8F9FA;
    }

    .hq-wtb-channel-form {
        .hq-wtb-channel.active {
            border: 1px solid rgba(247, 149, 62, 0.5);
            background-color: #FFFAF3;
        }

        .hq-wtb-channel {
            border: 1px solid 1px solid #F1F2F4;
            box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
            &__logo.billtobox {
                position: relative;
                background-image: url(/dist/assets/images/btb_hq-channel_logo.svg);
                background-position: center;
                background-repeat: no-repeat;
                height: 120px;
                width: 240px;
            }
            &__logo.mail {
                position: relative;
                background-image: url(/dist/assets/images/mail_hq-channel_logo.svg);
                background-position: center;
                background-repeat: no-repeat;
                height: 120px;
                width: 240px;
            }

            &__logo::after {
                content: '';
                position: absolute;
                display: block;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 60px;
                width: 1px;
                background-color: #F1F2F4;
                box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
            }

            &__info {
                padding: 30px 0 30px 30px;
                &-title {
                    display: block;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: #3C4E64;
                    padding:5px;
                }
                &-desc {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #6D7F92;
                    display: block;
                    padding:5px;
                }
            }
            &__action {
                    display: flex;
                    align-items: center;
                    max-width: 150px;
                    /* padding-top: 30px; */
                    justify-content: flex-end;

                    &-title {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        color: #3C4E64;
                        padding-left:5px;
                        padding-right:10px;
                    }
                }
        }
    }

    .hq-btb-vas-form {
        position: relative;
        .hq-btb-vas {
            width: calc(50% - 1.15rem);
            padding:2px;
            border: 1px solid rgba(247, 149, 62, 0.5);
            background-color: #FFF;
            border: 1px solid #F1F2F4;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
            border-radius: 5px;

            &.active {
                background-color: #FFFAF3;
                border: 0.5px solid rgba(247, 149, 62, 0.5);

                .hq-btb-vas__footer {
                    background-color: #fcf1e2;
                    color: #F7953E !important;
                    .ttp-channel-valid {
                        color: #F7953E !important;
                    }
                }
            }

            &.error {
                background-color: #FFFAF3;
                border: 0.5px solid rgba(247, 149, 62, 0.5);

                .hq-btb-vas__footer {
                    background-color: rgba(255, 86, 86, 0.1);
                    color: #FF5656 !important;;
                    .ttp-channel-valid{
                        color: #FF5656 !important;
                    }
                }
            }
            &__logo.billtoboxfiles {
                position: relative;
                background-image: url(/dist/assets/images/btb-new-2.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 65px;
                height: 120px;
                width: 120px;
            }
            &__logo.adm {
                position: relative;
                background-image: url(/dist/assets/images/adm_logo.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 65px;
                height: 120px;
                width: 120px;
            }
            &__logo.onea {
                position: relative;
                background-image: url(/dist/assets/images/onea-vas.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 65px;
                height: 120px;
                width: 120px;
            }
            &__logo.yuki {
                position: relative;
                background-image: url(/dist/assets/images/yuki-vas.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 65px;
                height: 120px;
                width: 120px;
            }

            &__logo::after {
                content: '';
                position: absolute;
                display: block;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 60px;
                width: 1px;
                background-color: #F1F2F4;
                box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
            }

            &__info {
                padding: 30px 0 30px 30px;
                &-title {
                    display: block;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: #3C4E64;
                    padding:5px;
                }
                &-desc {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #6D7F92;
                    display: block;
                    padding:5px;
                }
            }
            &__status {
                    display: flex;
                    align-items: center;
                    max-width: 150px;
                    /* padding-top: 30px; */
                    justify-content: flex-end;

                    &-title {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        color: #3C4E64;
                        padding-left:5px;
                        padding-right:10px;
                    }
            }
            &__footer {
                width: 100%;
                min-height: 23px;
                padding: 10px;
                background-color: #fcfcfc;
                display:flex;
                align-items: center;
                .hq-btb-vas__button {
                    cursor: pointer;
                    color: #3C4E64;
                    border: 0.5px solid rgba(109, 127, 146, 0.5);
                    box-sizing: border-box;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-family: Roboto;
                    font-weight: 500;
                    text-align: center;
                    height: 25px;
                    margin-right: 10px;
                    i {
                        font-size: 15px;
                    }
                }

                .hq-btb-vas__desc{
                    margin-right: 10px;
                }
            }
        }
    }
}

#ttp-root .ttp-dialog {
    padding: 0;
    background: #FFFAF3;
    overflow: inherit;
    
    i.ttp-icon-close {
        cursor: pointer;
        color: #3C4E64;
        position: absolute;
        right: -13px;
        top: -13px;
        z-index: 99;
        background-color: #FFF;
        border-radius: 50%;
        padding: 4px;
        font-size: 17px;
    }
    &__header {
        color: #EA944D;
        background: #FFFAF3;

        h1, h2, h3, h4, h5 {
            display: flex;
            align-items: center;
            padding: 0 10px;

            i {
                margin-right: 5px;
            }
        }

    }


    &__body {
        background: #F8F9FA;
        border-top: 1px solid #f6e1c3;
    }
}

