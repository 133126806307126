#create-import {
    margin-bottom: 4rem;

    .ttp-btn {
        &.success {
            margin: 1rem 1rem 1rem auto;
            float: right;
        }
    }

    #import-form {
        background: #ffffff;
        border: 0.5px solid #f1f2f4;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
        border-radius: 5px;
        min-height: 32rem;
        padding-top: 24px;
        padding-bottom: 24px;

        .import_rules_header {
            position: relative;
            background-color: #ffffff;
            cursor: pointer;
            margin: 0rem 2rem;
            display: grid;
            font-family: Roboto;
            text-transform: uppercase;
            font-style: normal;
            .rules_title-text {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #ea944d;
            }
            .rules_desc-text {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: #8f9ead;
                margin-top: 5px;
            }
        }
        .ttp-rules-item {
            position: relative;
            background-color: #ffffff;
            color: #6d7f92;
            text-align: center;
            display: block;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background-size: contain;
            border-radius: 5px;
            margin: 2rem;
            border-top: none;
            border: 1px solid #f7953e;
            box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);

            .ttp-rules-item__title {
                display: flex;
                background: #fff;
                box-sizing: border-box;
                border-radius: 5px;
                position: relative;
                min-height: 4rem;
                border-bottom: solid 1px #ccc;
                &__name {
                    margin-left: 2rem;
                    #ruleTitle {
                        color: black;
                        pointer-events: none;
                        box-sizing: content-box;
                    }
                }
                &__dropdown {
                    align-items: center;
                    cursor: pointer;
                    position: absolute;
                    right: 12px;
                    top: 0.8rem;
                    display: flex;
                    .menu {
                        position: relative;
                        li {
                            padding: 0 16px;
                            &:hover {
                                background-color: #eee;
                            }
                        }
                    }
                }
                &__edit {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        color: #f7953e;
                    }
                }
                &__divider {
                    width: 2px;
                    height: 22px;
                    background: rgba(109, 127, 146, 0.2);
                    border-radius: 1px;
                    margin: auto 15px;
                    cursor: pointer;
                }
                &__expand {
                    display: flex;
                    align-items: center;
                    font-size: 30px;
                    color: #29394d;
                    cursor: pointer;
                    &:hover {
                        transform: rotate(180deg);
                    }
                }
            }
            .ttp-rules-item__exp {
                margin: 10px;
                display: flex;
                padding: 0px 0 9px 0;
                &_span {
                    margin: 17px 1rem auto 1rem;
                    font-family: Roboto;
                    color: #29394d;
                }
            }
            .ttp-rules-item__condition , .ttp-rules-item__operation {
                display: flex;
                background: #f1f2f4;
                border: 0.5px solid #f1f2f4;
                box-sizing: border-box;
                border-radius: 3px;
                padding: 0 25px 0 27px;
                margin-left: 2%;
                width: 96%;
                .ttp-form {
                    margin: 12px 0;
                }
                .ttp-form__select,
                .ttp-form__field {
                    background-color: white;
                    border: 1px solid #b2bcc6;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin: 0;
                }
                .remove-exp {
                    background: #ffffff;
                    opacity: 0.5;
                    border: 0.5px solid rgba(254, 55, 69, 0.2);
                    box-sizing: border-box;
                    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
                    border-radius: 4px;
                    margin-top: 6px;

                    &:hover {
                        background-color: rgba(254, 55, 69, 0.2);
                    }
                }
                .new-exp {
                    background: #ffffff;
                    border: 0.5px solid rgba(178, 188, 198, 0.49);
                    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
                    border-radius: 4px;
                    margin-top: 6px;
                    width: 27px;
                    height: 27px;

                    &:hover {
                        background-color: #06D9B11F;
                    }
                }
                .tokenfield {
                    border: 1px solid #b2bcc6;
                    background: #fff;
                    padding: 15px 0 0 0;
                    border-radius: 4px;
                    overflow-y: auto;
                    margin-top: 0px;
                    min-height: 36px;
                }
            }
            .second-operand {
                display: inline;
            }
        }
        .import_rules_note {
            padding: 1rem;
            background-color: rgba(239, 131, 24, 0.05);
            border-top-right-radius: 0;
            border: 1px solid rgba(239, 131, 24, 0.1);
            display: grid;
            box-sizing: border-box;
            min-height: 4rem;
            margin: 5rem 9rem;
        }
        .import_error {
            border-radius: 5px;
            position: relative;
            min-height: 2rem;
            width: 204px;
            left: 77%;
            padding-left: 17px;
            display: none;
            &__text {
                font-family: Roboto;
                text-align: center;
                font-size: 14px;
                display: flex;
                justify-content: center;
                color: #ff5656;
                font-weight: 500;
            }
        }
        .import_addRule-btn {
            display: grid;
            background: #fff;
            border: 1px solid rgba(235, 153, 81, 0.5);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
            box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
            border-radius: 5px;
            position: relative;
            min-height: 4rem;
            margin: 2rem;
            &:hover {
                background: #fffaf3;
            }
            &_text {
                font-family: Roboto;
                text-align: center;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                cursor: pointer;
            }
        }
        .import_upload-container {
            min-height: 22rem;
            position: relative;
            background-color: #ffffff;
            color: #6d7f92;
            text-align: center;
            cursor: pointer;
            display: grid;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border: 1px dashed #b2bcc6;
            max-height: 3rem;
            background-size: contain;
            border-radius: 5px;
            margin: 2rem 4rem;

            &:hover {
                border: 1px dashed #f7953e;

                .import_upload-container_text {
                    color: #f7953e;
                }

                .import_upload-container_svg {
                    filter: invert(63%) sepia(42%) saturate(823%) hue-rotate(332deg) brightness(156%) contrast(94%);
                }
            }

            .import_upload-container_content {
                margin-top: 2rem;
            }

            input#upload-file {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .import_prototype {
            position: absolute;
            left: 50%;
            bottom: 20px;
            width: fit-content;
            transform: translateX(-50%);

            .btn {
                margin: 0;
            }
        }
    }

    #map-columns-container {
        background: #fff;
        padding: 2rem 3rem 0 3rem;
        border-radius: 5px;
        position: relative;
        border: 1px solid #f7953e;
        box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        padding-bottom: 20px;
        .column-imported-file {
            margin: -2rem -3rem 0 -3rem;
            height: 100%;
            display: flex;
            background: #fff;
            box-sizing: border-box;
            position: relative;
            border-radius: 5px;

            .column-imported-file__ico {
                display: flex;
                width: 5%;
                justify-content: flex-end;
                position: relative;
                align-items: center;
            }

            .column-imported-file__close {
                cursor: pointer;
                color: #6d7f92;
                position: absolute;
                right: 1rem;
                top: 50%;
                z-index: 99;
                transform: translateY(-50%);

                &:hover {
                    color: #f7953e;
                }
            }

            .column-imported-file__name {
                display: flex;
                align-items: center;
                padding-left: 12px;
                .file_name_label {
                    font-size: 1.8rem !important;
                    font-weight: 500 !important;
                }

            }

            .column-imported-file__name * {
                font-weight: 500;
                font-size: 14px;
                text-transform: lowercase;
                color: #29394d;
                border: none;
                line-height: 60px;



            }

            #upload-input {
                width: 300px;
            }
        }

        & > .mdl-grid {
            padding-left: 0;
            padding-right: 0;
        }

        .columns-container {
            display: flex;
            justify-content: space-between;
        }

        #db-columns-container,
        #sheet-columns-container {
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            align-self: flex-start;
        }

        #db-columns-container .column {
            border-left: 6px solid #b2bcc6;
            border-radius: 2px 5px 5px 2px;
        }

        #sheet-columns-container .column {
            border-right: 6px solid #b2bcc6;
            border-radius: 5px 2px 2px 5px;
        }

        #column-sheet-message {
            text-align: center;
            font-size: 1rem;
            font-weight: 300;
            padding: 2rem;
            border: 1px solid #18a0fb;
            border-radius: 6px;
            margin: 4rem;
        }

        .column-header {
            width: 230px;
            font-family: Roboto;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #0c0c0c;
            background-color: #f7d29a;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 20px;
            border: 0.5px solid #4f4d4d;
            box-sizing: border-box;
            border-radius: 5px 2px 13px 5px;
            margin: auto;
        }

        .column {
            line-height: 40px;
            color: #2a3a4c;
            font-size: 1rem;
            padding-left: 20px;
            padding-right: 1rem;
            letter-spacing: 0.085rem;
            background-color: #ffffff;
            border: 0.5px solid #b2bcc6;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px rgba(41, 57, 77, 0.1);
            min-width: 132px;
            position: relative;
            width: 274px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            margin: 8px auto auto;
            order: 100;

            span {
                color: #f7953e;
                position: absolute;
                right: 6px;
                top: 5px;
                font-size: 1.6rem;
                line-height: 1;
                font-weight: 500;
            }
        }

        .jtk-endpoint:hover {
            cursor: grab;
        }

        .jtk-endpoint-connected {
            border-radius: 10px;

            svg circle {
                fill: #f7953e;
            }
        }

        .jtk-endpoint {
            background-color: white;
            border: 2px solid #fff;
        }

        .sourceEndpoint {
            margin-left: -4px;
        }

        .import-info {
            font-size: 12px;
            color: #6d7f92;
            font-weight: 300;
            margin: auto 0;
            line-height: 14px;
            text-align: center;
        }

        .import-info strong {
            color: #fe3745;
        }
    }
    .preview-container {
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 3px;
        padding: 3px;
        margin: 15px;

        #preview-table {
            th {
                background-color: #eee;
                text-align: left;
            }
            td{
                text-align: left;
            }
        }
    }
    #preview-info {
        text-align: center;
        font-size: 12px;
    }

    &.reduced {
        margin-bottom: 0;
        #import-form {
            width: 100%;
            border: none;
            box-shadow: none;
        }
    }
}

.import-status {
    padding: 0.2rem 0.5rem;
    text-transform: uppercase;
    border-radius: 3rem;
    float: right;
    background: #fff;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    box-shadow: 1px 1px 2px 2px rgba(241, 242, 244, 0.5);
    color: #6d7f92;
    position: relative;
    overflow: hidden;
    z-index: 1;

    i {
        font-size: 12px;
        line-height: 1;
        margin-right: 6px;
    }

    .done {
        color: #02af8e;
    }

    .progress {
        color: #d1a02d;
    }

    .failed {
        color: #ff636e;
    }

    .progress-bar {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #c7e8fe;
        z-index: -1;
    }
}

.link-icon {
    background: #f1f2f4;
    color: #6d7f92;
    border-radius: 50px;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 4px;
    left: 25%;

    &.errors {
        background: #fbeff0;

        i,
        .link-label {
            color: hsl(11, 63%, 51%);
        }
    }

    i {
        font-size: 19px;
        line-height: 1.8;
        margin-right: 8px;
        margin-left: 8px;
    }

    &:hover {
        width: 110px;

        .link-label {
            display: initial;
        }
    }

    .link-label {
        font-size: 12px;
        line-height: 14px;
        color: #f7953e;
        top: 0.7rem;
        display: none;
    }
}

.ttp-import-data-table {
    a {
        align-items: center !important;
        display: flex !important;
        -webkit-box-align: center !important;
        i {
            margin-right: 6px;
        }
    }

    li:hover {
        background: #eee !important;
    }
}

dialog.ttp-load-rule-dialog {
    width: 600px;
    .ttp-dialog-button {
        margin-top: 0;
    }
}

.link-none {
    position: absolute;
    top: 25px;
    right: 62%;
}
