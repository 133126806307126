.avatar-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #DDD, #CCC, #C5C5C5, #BBB, #B5B5B5, #AAA);
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}
.initials {
    position: relative;
    font-size: 50px;
    line-height: 0px;
    color: #fff;
    font-family: "Courier New", monospace;
    font-weight: bold;
}

#profile-img {
    .ttp-profile{
        margin: 82px auto 0;
    }

    .ttp-photo-original, .dz-img-prev {
        width: 100%;
        height: 100%;
    }
    .ttp-profile-organization-container {
        min-width: 218px;
        .ttp-photo-original {
            border: 0;
        }
        i {
            text-align: center;
            line-height: 218px;
            background: #fcfdff;
        }

        .ttp-photo-preview-sub-container {
            overflow: hidden;
            border-radius: 50%;
            height: 100%;
        }
    }
    .ttp-avatar .ttp-photo-original, .ttp-avatar .ttp-no-photo, .ttp-avatar .dz-img-prev {
        border-radius: 50%;
    }
    .ttp-no-photo {
        font-size:169px;
        color: $blue01;
    }
    h3 {
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 36px;
        height: 36px;
        font-weight: 300;
        color: $black04;
        margin: 0 0 10px 0;
    }
    small {
        color: $blue1;
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-weight: 100;
    }
    .ttp-photo-error {
        color:  #d50000;
    }
    .ttp-profile-organization-name .ttp-photo-error {
        margin-top: 30px;
        display: block;
        font-size: 1em;
    }

    .ttp-photo-droppable-hover {
        border: 2px dashed #5997d1 !important;
        opacity: 0.7;
    }
    .ttp-photo-progress {
        width: 132px !important;
    }
}

#ttp-cover-photo {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 218px;
    overflow: hidden;
    margin: 26px;
    border-radius: 10px;
    background-color: #F3FAFF;

    .mdl-tooltip {
        font-size: 11px;
        font-weight: 100;
        background-color: $orange1;
    }

    .ttp-cover-photo-exist {
        display: none;
    }

    &:hover {
        .ttp-cover-photo-exist {
            display: inline-block;
        }
    }

    .ttp-photo-original, .ttp-no-photo, .dz-img-prev {
        position: relative;
        width: 100%;
        min-width: 974px;
    }
    .ttp-photo-preview img {
        border-radius: 0%;
    }
    .ttp-photo-preview {
        overflow: hidden;
        height: 100%;
        width: 100%;
        margin: 0;
    }
    .ttp-no-photo {
        font-size:132px;
        color: $blue01;
    }

    .ttp-cover-save-button-wraper, .ttp-drag-photo-wrapper{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
        z-index: 5000;
    }

    small {
        color: $black04;
        display: block;
        font-size: .8rem;
        line-height: 1.5rem;
        width: 160px;
        font-weight: 100;
    }
    .ttp-photo-error {
        color:  #d50000;
    }
    .ttp-photo-droppable-hover {
        border: 2px dashed #5997d1 !important;
        opacity: 0.7;
    }
    .ttp-photo-progress {
        width: 132px !important;
    }

    .ttp-actions {
        font-size: 14px;
        color: #18A0FB;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
        border-radius: 4px;
        border: none;
        font-weight: normal;
        margin: 0 10px;
        img {
            margin-right: 15px;
        }
        &:hover {
            background: #F1F2F4;
        }
    }

}

#ttp-drag-photo, #ttp-save-drag-photo, #ttp-cancel-drag-photo {
    position: relative;
    margin:0;
}


#ttp-root .ttp-profile-organization > div:first-child{
    height: 215px;
    padding-top: 15px;
}
#ttp-root .ttp-profile-organization #ttp-cover-photo.ttp-cover-photo{
    z-index: 0;
    height: 230px;
}

.ttp-edit-organization {
    display: flex;
    flex-direction: row-reverse;
}


/**
*  Organization profile
*/

.ttp-profile-organization {
    .mdl-typography--headline{
        font-size: 28px;
        margin-bottom: 8px;
    }
    #profile-img > .ttp-grid-start > div:first-child{
        margin-top: 60px;
    }
    > .ttp-grid.mdl-cell{
        margin: 26px 0 100px 0;
    }
    .ttp-profile-organization-name .ttp-grid{
        width: 35%
    }
    .ttp-profile-organization-name{
        margin: 230px auto auto 0;
        z-index: 1;
    }
    .ttp-text-validated{
        color: #7ebe50;
    }
    i.ttp-text-validated{
        margin-top: -6px;
    }
    .ttp-photo {
        text-align: center;
        a {
            color: $blue1;
            text-decoration: none;
        }
        small {
            font-weight: 100;
        }
    }
    .ttp-organization-photo {
        font-size:10rem;
        color: $blue02;
    }
    .ttp-add-admin {
        margin-top: 38px;
        text-align: right;
    }

    .mdl-list__item-primary-content span:not(:first-child) {
        color: $blue1;
        font-weight: 300;
        margin-left: $margin1;
    }
    .mdl-list__item-primary-content span:first-child {
        width: 150px;
    }
    ul .mdl-list__item:not(:last-child) {
        border-bottom: 1px solid $blue02;
    }
    & > div:first-child {
        margin: auto;
    }
    #profile-menu {
        margin-top: 120px;
    }
}

.ttp-profile-organization-bottom {
    margin: auto;
    .mdl-tabs__panel {
        padding: 0 $padding3;
        .mdl-grid {
            display: flex;
            justify-content: space-around;
        }
    }
}
