#ttp-gdpr-stepper {
    .mdl-stepper {
        max-width: none;
        box-shadow: none;
        font-family: inherit;
        .mdl-step.is-active .mdl-step__label-indicator{
            background-color: $blue1;
        }
        .mdl-step.is-active .mdl-step__actions [data-stepper-back]{
            margin-left: 0;
        }
        .mdl-step__content, .mdl-step{
            height: auto;
        }
        .mdl-step__content {
            padding: 2rem 0;
        }
        .mdl-data-table td, .mdl-data-table th {
            text-transform: uppercase;
            padding: .5rem 2rem;
        }
        .mdl-step__label {
            margin-bottom: 0;
            padding: 18px 24px;
            background-color: rgba(0,0,0,0.02);
            &:hover {
                background-color: rgba(0,0,0,0.04);
            }
        }
        .mdl-step:not(:last-child)::after {
            width: 2px;
            background-color: rgba(0,0,0,0.04);
        }
        .mdl-step--completed .mdl-step__label-indicator {
            background-color: #4cb71a;
        }
        .mdl-step__title-text {
            font-size: 16px;
        }
        iframe {
            border: none;
            width: 100%;
        }
    }
    .ttp-gdpr-stepper__content {
        margin: 0 0 2rem;
        .ttp-button {
            margin: 25px auto 0;
        }
        .ttp-button {
            margin: 0;
        }
        table {
            margin-top: 1rem;
        }
        #contact-chart {
            margin: 1.5rem auto;
        }
    }
    .mdl-checkbox {
        height: auto;
    }
    .ttp-button{
        margin: 2rem auto;
    }
    #enabling-gdpr-errors{
        div{
            padding: 1rem;
        }
    }
    .ttp-container{
        margin-top: 1rem;
    }

    #emailing-iframe {
        min-height: 600px;
    }
}

.gdpr-logo{
    background: url(../images/gdpr-flag.png) no-repeat;
    height: 70px;
    width: 70px;
    background-size: 70px;
    margin: auto;
}
