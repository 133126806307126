
.btn {
    min-width: 180px;
    padding: 0 1rem;
    height: 44px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    background: #FFFFFF;
    border: 1px solid rgba(255, 226, 184, 0.3);
    border-radius: 30px;
    font-family: Roboto;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    margin-left: 22px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      box-shadow: none;
    }

    i.material-icons {
      margin-right: 12px;
      font-size: 1.4rem;
    }

    &.secondary {
      background: #FFFFFF;
      letter-spacing: 0.06em;
      color: #F7953E !important;
    }

    &.primary {
      background: #06D9B1;
      letter-spacing: 0.04em;
      color: #FFFFFF !important;      
    }

    &.light {
      background: #F1F2F4;;
      letter-spacing: 0.06em;
      color: #6D7F92 !important;
      border: 0.5px solid #B2BCC6;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      text-transform: capitalize;
    }

    &.btn-edit-next{
      display: none;
      position: absolute;
      top: 72px;
      right: 70px;
      padding-top: 10px;
      width: 320px;
    }

    &.expand_more_btn{
      min-width: 60px;
      font-size: 0;
      margin-left: 0;
      background: #08d9b1;
      color: white !important;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &.btn-edit {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      width: 257px;
    }
}

.ttp-body-button {
  background: #FFFFFF;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #000;
  display: flex;
  align-items: center;
  padding: 12px 63px;
  text-transform: uppercase;
  outline: none;
  height: 44px;
  cursor: pointer;

  &.primary {
    border-color: #06D9B1;
    color: #06D9B1;
  }

  &.secondary {
    border-color: #F7953E;
    color: #F7953E;
  }

  &.cancel {
    color: #6D7F92;
    background: #F8F9FA;
    margin: 0 1.5rem;
  }

  &:hover {
    border-color: transparent;
    color: #fff !important;
    
    &.primary {
      background-color: #06D9B1;
    }
  
    &.secondary {
      background-color: #F7953E;
    }
    &.cancel {
      background-color: #6D7F92;
    }
  }
}