.dynamic-chart-container {
  height: fit-content;
  background: #eee;
  padding-bottom: 2rem;

  .form {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3rem;
    border-bottom: 1px solid #eee;  
    align-items: baseline;

    .bases, .filters {
      display: flex;
      justify-content: space-between;
      box-shadow: 5px 5px 10px 2px rgba(200,200,200, 0.2);
      border: 1px solid rgba(249, 229, 212, 0.5);
      margin-bottom: 1rem;
      h4 {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 300;
        color: #3c4e63;
        margin: 0 0 4px 0;
        img {
          margin-right: 6px;
          width: 24px;
        }
      }
    }

    .actions {
      .mdl-cell {
        margin: 0 16px 10px -8px;
      }
    }
  }

  .mdl-textfield {
    max-height: min-content !important;
    min-height: 75px;
  }

  .chart {
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;
    canvas {
      display: none;
    }
  }

  .select {
    position: relative;
    background: #fff;
    padding: .4rem 1rem;
    margin: 0 0 1rem 0;
    width: 49%;

    & > div {
      padding-bottom: 0.5rem;
    }

    .mdl-textfield__label {
      strong {
        color: #F21;
        font-size: 16px;
      }
    }

    .mdl-textfield__input {
      position: relative;
      display: flex;
      font-size: 18px;
      min-height: 20px; 
    }

    &_wrapper {
      display: flex;
      align-items: baseline;
      cursor: pointer;
      text-transform: uppercase;
      flex-wrap: wrap;
      height: auto;
    }

    &__input {
      background: transparent;
      border: none;
      width: 100px;
      height: auto;
      font-size: 18px;
      line-height: 20px;
    }

    .tt-menu {
      z-index: 1;
      position: absolute;
      display: none;
      left: 0;
      right: 0;
      width: auto !important;
    }

    .material-icons {
      font-size: 20px;
      color: $blue08;
    }

    .drop-down,
    .drop-up {
      position: absolute;
      right: 0;
    }

    .drop-up {
      display: none;
    }

    li {
      padding: .3rem 0;
      list-style-type: none;
      text-transform: uppercase;

      &:hover {
        background: #f8f9fa;
      }
    }

    &-label {
      padding-left: .5rem;
    }

    .loader {
      position: absolute;
      right: -4px;
      top: 10px;
    }
  }

  #btn-generate-chart {
    min-width: 64px;
    width: 100%;
    height: 64px;
    border-radius: 2px;
    order: 999;
    display: none;
    padding: 0 0 0 14px;
    margin: 0 0 1rem 0;
    position: relative;
    text-align: left;

    .material-icons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }

    .loader {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 15px;
      right: 1rem;
    }

    .material-icons {
      font-size: 2.5rem;
      margin: 0;
    }
  }
}