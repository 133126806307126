.ttp-edit-group-dialog {
    width: 500px;
    h4 {
        text-align: center;
    }

    button {
        margin: 0 0 0 1rem !important;
    }
}

dialog {
    position: absolute * 0 * 0;
    size: fit-content;
    margin: auto;
    border: solid;
    padding: 3em;
    background: #F8F9FA;
    color: black;
    display: none;
}

dialog[open] {
    display: block;
}

dialog + .backdrop {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(41, 57, 77, 0.4);
}

/* for small devices, modal dialogs go full-screen */
@media screen and (max-width: 540px) {
    dialog[_polyfill_modal] {  /* TODO: implement */
        top: 0;
        width: auto;
        margin: 1em;
    }
}

._dialog_overlay {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    display: none;
}

.ttp-dialog {
    border-radius: 5px;
    padding: 42px 1rem 1rem !important;
    overflow: inherit;
    
    .ttp-dialog-header {
        margin: -42px -1rem auto;
        width: auto;
        height: 50px;
        border-radius: 5px 5px 0 0;
        border: 0.5px solid rgba(247, 149, 62, 0.25);
        background: $bridal-heath;
        display: flex;
        padding-left: 2rem;

        p {
            color: #EA944D;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 20px;
            padding: 10px 12px 10px;
            line-height: 35px;
        }

        img {
            height: 27px;
            padding-top: 11px;
        }
    }

    .ttp-dialog-close {
        position: absolute;
        top: -18px;
        right: -18px;
        width: 36px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        border-radius: 50%;
        cursor: pointer;
        margin: 0;

        &:hover {
            background: #FFFFFF;
        }

        img {
            position: absolute;
            width: 36px;
            height: 14px;
            top: 10px;
            left: 0;
        }
    }

    .ttp-dialog-buttons-container {
        .ttp-dialog-button {
            height: 44px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 5px;
            outline: none;
        }

        .ttp-button-save {
            background-color: #F7953E;
            color: #FFFFFF;
            width: 168px;
            border: none;
            box-shadow: 0px 2px 20px rgba(41, 57, 77, 0.2);
            &:hover {
                box-shadow: none
            }
        }

        .ttp-button-cancel {
            color: #6D7F92;
            border: 1px solid rgba(109, 127, 146, 0.5);
            width: 126px;
            background: transparent;
            &:hover {
                box-shadow: 0px 2px 20px rgba(41, 57, 77, 0.2);
            }
        }

        .ttp-dialog-button-success {
            background-color: hsl(95, 46%, 53%);
            color: #FFFFFF;
        }

        .ttp-dialog-button-danger {
            background-color: hsl(11, 63%, 51%);
            color: #FFFFFF;
        }

        .ttp-dialog-button-settings {
            background-color: #FFFFFF;
            color: #6D7F92;
            border: 0.5px solid rgba(109, 127, 146, 0.5);
        }

        a {
            color: #FFFFFF !important;
        }
    }
}

.ttp-manage-authorizations-dialog{
    width: 50rem;
    .mdl-dialog__actions > :first-child{
        margin: 0;
    }
    .ttp-select{
        width: 15rem;
        margin-left: 2rem;
    }
    .mdl-dialog__content, h3{
        text-align: center;
        padding: 0 1.2rem;
    }
    .detail{
        margin: 0;
        color: #9a9a9a;
        font-size: .9rem;
    }
    .ttp-dialog-buttons-container {
        padding: 2rem 8px 0 24px;
    }
    .details-container {
        display: flex;
        padding-top: 3rem;
        .tooltip-help {
            padding: 0;
        }
    }
}

.ttp-photo-crop-dialog {
    top: 80px;
    min-width:600px;
    h4 {
        margin: 0 auto;
        margin-bottom: 20px;
        border:0px;
        text-align: center;
    }
}

.ttp-dialog-confirm {
    width:600px;
    h4 {
        text-align: center;
        span {
            display: inline-block;
            text-align: left;
            vertical-align: top;
            max-width: 91%;
        }
    }
}

#ttp-root .ttp-directory_apply-conf {
    h3.js-title-tmpl {
        margin-bottom: 0px;
    }

    .js-configure-button-tmpl .ttp-is-auto_approve {
        width: 180px;
        align-self: center;
    }

    .ttp-directory-app-button-wrapper {
        margin-top: 0px;
        padding-top: 10px;
    }

    .ttp-dialog-button-danger {
        width: unset;
    }
}

.mdl-dialog__actions>*:first-child, dialog .close-dialog{
    margin-top: 130px;
}
.ttp-confirm-dialog .mdl-dialog__actions>*{
    margin-top: $margin1;
}

.ttp-roles-dialog__footer {
    padding-top: 7px;

    .ttp-save-affected-roles {
        margin-right: 0px;
    }

    .ttp-dialog-buttons-container {
        margin-top: 6rem;

        .ttp-button-cancel {
            margin-right: 1rem;
        }
    }
}

.ttp-create-invitation-dialog {
    width: 65%;
    min-width: 800px;
    padding : 2%;

    .ttp-select {
        &[name="visitorType"] {
            text-transform: uppercase;
        }
    }

    .mdl-dialog__actions>*:first-child {
        margin-top: 0;
    }
    .icon-container {
        position: absolute;
        right: -3px;
        top: calc(50% - 0px);
    }
    .loader {
        position: relative;
        height: 20px;
        width: 20px;
        display: inline-block;
        animation: around 5.4s infinite;
    }
    .loader::after, .loader::before {
        content: "";
        background: white;
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-color: #ff866a #ff866a transparent transparent;
        border-style: solid;
        border-radius: 20px;
        box-sizing: border-box;
        top: 0;
        left: 0;
        animation: around 0.7s ease-in-out infinite;
    }
    .loader::after {
        animation: around 0.7s ease-in-out 0.1s infinite;
        background: transparent;
    }
}

.show-import-open-dialog, #ttp-send-document-form, #import-file-form {
    position: absolute;
    width: 40%;

    .mdl-button--file {
        input {
            cursor: pointer;
            height: 100%;
            right: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 300px;
            z-index: 4;
        }
    }

    .mdl-textfield--file {
        .mdl-textfield__input {
            box-sizing: border-box;
            width: calc(100% - 32px);
        }

        .mdl-button--file {
            right: 0;
            color: $blue1;
        }

        .mdl-button--file:hover {
            color: #fff;
        }
    }

    .mdl-textfield--file {
        width: 60%;
    }

    textarea {
        border: 1px solid $gray1;
        padding: 10px;
    }

    textarea + .mdl-textfield__label {
        padding: 22px 11px;
    }

    .prototype {
        margin: 10px 15px 0;
    }

    .copy {
        width: 100%;
    }

    .uploadProto {
        display: flex;
    }
}

.confirm-quit-community-dialog[open] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.confirm-quit-community-dialog {
    width: 500px;
    height: 210px;

    .ttp-dialog-content {
        justify-content: center;
        display: grid;
        margin: auto auto 1.5rem;
        text-align: center;

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
        }

        .leave {
            color: #FF636E;
        }

        span {
            line-height: 16px;
            color: #6D7F92;
        }

        img {
            &:first-child {
                margin: -5rem 0 1rem;
            }

            &:nth-child(2) {
                position: absolute;
                top: 3.5rem;
                left: 20rem;
                &.img-alert-circle {
                    top: 5.5rem;
                    left: 19.85rem;
                }
            }
        }
    }

    .ttp-dialog-buttons-container {
        margin: 0 1.5rem 1rem 0;
        justify-content: center;

        .ttp-dialog-button {
            margin-top: 0;
            width: 200px;
            height: 34px;
        }

        .ttp-button-cancel {
            margin-right: 1.5rem;
            background: #6D7F92;
            color: white;
        }

        .ttp-button-save {
            background: #FF636E;
        }
    }
}