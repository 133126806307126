/* ----  User profile page  ---- */
#profile-menu {
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 30px;
  hr {
    border-top: 1px solid #f1f2f4;
    width: 100%;
  }
  .mdl-layout__container {
    position: inherit;
  }
  .mdl-layout__header {
    margin: 48px 0;
    box-shadow: none;
    background-color: #fff;
  }
  .ttp-profile-tab {
    .mdl-cell {
      margin: 8px;
    }
    /*margin: 0 auto;
    background-color: #fff;
    hr {
        border-top: 1px solid #F1F2F4;
        width: 100%;
    }*/
    .mdl-grid {
      padding: 0px;
    }
  }
  .ttp-textarea-container {
    margin-top: 20px;
  }

  .icon-plus {
    border-color: #18a0fb;
  }

  .ttp-form-button {
    display: flex;
    .ttp-body-button {
      padding-left: 20px;
      padding-right: 20px;
      &.secondary {
        margin-right: 1rem;
        color: #d7dbe0;
        border: 1px solid #d7dbe0;
      }
      &:hover {
        &.secondary {
          background: #d7dbe0;
        }
      }
    }
  }
}

/* remove this later */

.under-construction {
  text-align: center;
  margin-top: 5rem;
}

#ttp-profile-email,
#ttp-profile-phone {
  .mdl-textfield {
    padding-bottom: 0;
  }
  .mdl-tooltip {
    background: rgba(24, 160, 251, 0.8);
  }

  .mdl-radio {
    height: unset;
    &__button {
      pointer-events: none;
    }
  }
}

#ttp-root #profile-menu {
  .mdl-radio__inner-circle {
    background: #18a0fb;
  }
  .mdl-radio.is-checked .mdl-radio__outer-circle {
    border-color: #18a0fb;
  }

  .radio-container {
    height: 30px;
  }
}
