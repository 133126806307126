
.ttp-ob-page-header {
    position: relative;
    height: 170px;
    text-transform: uppercase;
    background: #29394D;
    /* border-radius: 0px 0px 10px 10px; */
    /* margin: 0 16px; */
    display: flex;
    margin-bottom: 1rem;
    z-index: 0;

    &__icon {
         display: flex;
         width: 9%;
         justify-content: flex-end;
         position: relative;

        &::after {
             content: '';
             position: absolute;
             display: block;
             right: 0;
             top: 50%;
             transform: translateY(-50%);
             height: 60px;
             width: 1px;
             background-color: #FFFFFF;
             box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
         }
        img {
            width: 36px;
            padding-right: 12px;
            margin: 18px 0;
        }
    }

    &__info {
         width: 51%;
         display: flex;
         align-items: flex-end;
         padding-left: 70px;
         height: 60px;

        * {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            margin: 0;
        }

        h3 {
            font-size: 20px !important;
            line-height: 23px;
            color: #FFFAF3;
            font-weight: normal !important;
            font-style: normal;
            font-family: Roboto;
        }

        p {
            font-size: 12px;
            line-height: 14px;
            color: #FFFAF3;
        }
    }
}

.ttp-ob-container {
    /* background-color: #FAFBFB; */
    /* padding: 30px 45px; */
    top: -140px;
    position: relative;

    min-height: 500px;

    .ttp-width {
        max-width: 91%;
    }

    .folder-employee-form {
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
        border-radius: 5px;
        padding: 1em;

        .role-type {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #6D7F92;
        }
        .role-principal {
            &__label {
                padding-right: 10px;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 23px;
                color: #3C4E64;
                &.active {
                    color: #E68443;
                }
            }

        }
    }

    .home-container {
        background-color: #FFF;
        margin-top: 85px;
    }

    .add-employee {
        display: inline-flex;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #3C4E64 !important;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
        border-radius: 5px;
        margin-left: 10px;
        padding-right: 10px;
        &-icon {
            border: none;
            width: 34px;
            height: 34px;
        }
    }
    

    .delete-employee_dialog {
        display: flex;
        padding: 20px;
        padding-top: 40px;
        justify-content: center;
    }
    .ob-button {
        background: #F7953E;
        color: #FFF !important;
        line-height: 20px;
        height: 47px;
        margin-left: 10px;
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5;
        }
        &.ob-small {
            line-height: 15px;
            height: 35px;
            margin-left: 7px;
            padding: 12px 15px;
        }
        &:hover {
            color: #F7953E !important;
            background: #FFF;
            border: 1px solid #F7953E;
        }
    }

    .ob-address-toggle {
        align-items: center;
        justify-content: center !important;

        &__label {
            padding: 0px 10px;
        }
    }

    .ob-content__header {
        padding: 15px 20px;
        padding-top: 50px;
        &-title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-transform: uppercase;
            color: #EA944D;
            display: block;
            padding-top: 10px;
        }
        &-subtitle {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            color: #6D7F92;
            display: block;
            padding-top: 10px;
        }
    }
}

.ob-page-header {
    margin: 0;
    border-bottom: 1px solid rgba(251, 202, 158, 0.5);;
    border-radius: 0px;
}

.ob-header {
    background-color: #29394D;
    width: 100%;
    height: 100%;
    position: relative;

    .ob-steps {
        display: flex;
        list-style-type: none;
        position: relative;
        /* z-index: 1000; */
        margin: 0;
        background-color: transparent;
        justify-content: center;
        /* height: 120px; */
        /* background-color: #29394D; */
        .ob-step-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #B2BCC6;
            text-transform: uppercase;
            min-width: 250px;
            cursor: pointer;
            /* border: 1px solid grey; */


            .ob-step-text {
                width: 100%;
                text-align: center;
                color: #B2BCC6 !important;
            }
            .ob-step-shape {
                display: block;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 12px;

                &__circle {
                    border: 4px solid #B2BCC6;
                    width: 1.7rem;
                    height: 1.65rem;
                    border-radius: 50%;
                    background: #FFFFFF;
                }
                &__line {
                    width: calc(50% - .75rem);
                    height: 4px;
                    background: #B2BCC6;
                    /* margin-top: calc(25% - .63rem); */
                }
            }
        }

        .ob-step-item-elem.is-current {
            .ob-step-item {
                .ob-step-text {
                    color: #F7953E !important;
                }
                .ob-step-shape {
                    &__circle {
                        border-color: #F7953E;
                    }
                }
            }
            
        }
    }

    .ob-steps-footer {
        position: absolute;
        top: calc(100% - 1.1rem);
        width: 100%;
        height: 40px;
        background-color: #FFF;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
}

.ttp-form__ob-container {
    background-color: #FFF;
    margin-top: 40px;

    input:read-only{
        cursor: not-allowed;
        opacity: .5;
    }

    .ob-lr-warning {
        i {
            font-size: 14px;
            margin-right: 5px;
        }
        color: orange;
    }

    .ttp-form {
        position: relative;

        &__block {
            border-top: 1px solid rgb(241, 242, 244);
            padding-top: 10px;
        }
    }

    .ttp-form__field{
        margin-top: 5px;
    }
    
    .ttp-form__label {
        .tooltip-help {
            font-size: 17px;
            color: #3C4E64;
        }
    }
    .ttp-form .ttp-form-filled {
        background-color: #FFFAF3;
        border: 1px solid rgba(247, 149, 62, 0.5);

        &.disabled {
            opacity: 0.5;
            user-select: none;
            pointer-events: none;
        }
    }

    .ttp-folder-langue_preferences {
        margin-top: 0;

        &_wrapper {
            height: 3.1rem;
            padding: 7px;

            .drop-down, .drop-up {
                color: #000;
                font-size: 28px;
                /* height: 40px; */
                opacity: .5;
            }
        }
    }

    #iban-group #iban-fields-wrapper {
        margin-top: 0;

        .iban-bloc {
            position: relative;
            margin-top: 0;
        }

        .remove-iban {
            top: 0px;
            right: 3px;
        }
        
        .check-iban {
            left: auto;
            right: 0;
            margin-left: auto;
            margin-top: 15px;
            margin-right: 5px;
        }
    }

    #iban-group i.add-iban, i.add-address {
            border: 1px solid #F1F2F4;
            border-radius: 5px;
            outline: none;
            background-color: #F8F9FA;
    }

    .ttp-card__actions {
        align-items: center !important;
        text-align: left !important;
    }

    .ttp-card__body {
        margin: 1rem;
    }
}

#ttp-root .ttp-dialog {
    padding: 0;
    background: #FFFAF3;
    overflow: inherit;
    
    i.ttp-icon-close {
        cursor: pointer;
        color: #3C4E64;
        position: absolute;
        right: -13px;
        top: -13px;
        z-index: 99;
        background-color: #FFF;
        border-radius: 50%;
        padding: 4px;
        font-size: 17px;
    }
    &__header {
        color: #EA944D;
        background: #FFFAF3;
        
        h1, h2, h3, h4, h5 {
            display: flex;
            align-items: center;
            padding: 0 10px;

            i {
                margin-right: 5px;
            }
        }
        
    }


    &__body {
        background: #F8F9FA;
        border-top: 1px solid #f6e1c3;
    }
}

#select-organization {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background:  url(/dist/assets/images/select-community-bg.svg) #F3FAFF no-repeat center center fixed; */
  background-size: cover;
  min-height: 100vh;

  .ob__back-button {
    background: #fff;
    box-shadow: 0 4px 20px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    width: 120px;
    height: 36px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #29394D;
    border: 1px solid #6D7F92;
    cursor: pointer;

    &_img {
      margin-right: 1rem;
      height: 13px;
      margin-bottom: 4px;
    }

    &:hover {
      color: #6D7F92;
      border: 1px solid rgba(109, 127, 146, 0.2);
      .ob__back-button_img{
        filter: invert(49%) sepia(10%) saturate(793%) hue-rotate(170deg) brightness(98%) contrast(89%);
      }
    }
  }

  .ob__container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 3rem;
    padding: 2rem 0 2rem 0;

    &.ob_select-org__container {
        margin-top: 32px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
  }

  .ob__header {
    width: 94%;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F1F2F4;
    z-index: 1;

    * {
      font-style: normal;
      margin: 0;
    }

    h3 {
      line-height: 28px;
      color: #29394D;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 20px;
    }

    span {
      color: #6D7F92;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ob__header_logo {
    background: url(/dist/assets/svg/logo.svg) white center no-repeat;
    background-size: 80px;
    border-radius: 50%;
    position: relative;
    bottom: 180px;
    width: 10rem;
    height: 10rem;
  }

  .ob__communities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* margin-top: -8rem; */
  }

  .ob__community {
    justify-content: flex-start;
    height: auto;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 0 4rem 6rem 4rem;
    &-title {
      position: relative;
      flex-direction: column;
      display: flex;
      align-items: center;
      width: 19rem;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #29394D;
      text-transform: uppercase;
      font-family: Roboto;

      display: inline-block;
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
      overflow: hidden;

      &.selected, &:hover {
        color: #18A0FB;
      }
    }

    &-logo {
      position: relative;
      cursor: pointer;
      border-radius: 50%;
      width: 14rem;
      height: 14rem;
      margin-bottom: 1rem;
      text-align: center;
      box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
      border: 3px solid #F1F2F4;
      background-color: white;


      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: inline-block;
        vertical-align: middle;
        /* width: 14rem;
        height: 14rem; */
        max-width: 140px;
        max-height: 140px;
        /* top: 2rem; */
        position: relative;
        user-select: none;
      }
    }

    .empty-avatar {
        color: #6d7f92;
        font-size: 2rem;
        background: #FFF;
    }
    &-container {
      position: absolute;
      width: 291px;
      height: 173px;
      margin-top: 6.7rem;
      background: #F1F2F4;
      border-radius: 20px;
    }

    &-unauthorised {
      width: 250px;
      position: absolute;
      top: 20rem;

      img {
        left: 20px;
      }

      span {
        padding-left: 9px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
        position: absolute;
      }
    }

    &.disabled {
      .ob__community-title, .ob__community-logo, .ob__community-container {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
      }
    }

    &.selected, &:hover {
      .ob__community-title, .ob__community-container {
        color: #18A0FB;
        opacity: 1;

      }

      .ob__community-logo {
        border-color: #18A0FB;
        background-color: #c7e8fe;
      }
    }
  }


}

