.social-links {
    padding: .2rem;
    margin: 0 .05rem;
    position: relative;
    width: auto;
    &:hover {
        background-color: #F1F2F4;
        .social-links__wrapper {
            opacity: 1;
            visibility: visible;
        }
    }
    &__header {
        background: #F1F2F4;
        font-size: 14px;
        text-align: center;
        color: #2495E1;
        padding: 1.5rem 1rem;
        line-height: 0.2rem;
        font-family: Roboto;
        text-transform: none;
        font-weight: normal;
    }

    &__body {
        background: #F1F2F4;

        & > ul {
            margin: 0 0.25 0.25;
            border-top: 2px solid #fff;
        }
    }

    &__wrapper {
        top: calc(100% + .6rem);
        padding: 0;
        width: 23rem;
        background: #F1F2F4;
        position: absolute;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
        border-radius: 0px 0px 4px 4px;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s all ease-in-out;
        z-index: 6;
        cursor: default;
    }

    .active::after{
        content: " ";
        width: 8px;
        height: 8px;
        position: absolute;
        border: 1px solid #fff;
        background-color: red;
        bottom: 8px;
        right: 12px;
        border-radius: 50%;
    }

    .sub-menu__dropdown {

        span {
            font-size: 0.8rem;
            text-transform: none;
        }

        & > li {
            line-height: 0.2rem;
            padding: 1.5rem 1rem .25rem;
            font-size: 12px;
            color: #29394D;
            text-transform: none;
            font-weight: normal;
            background: #fff;

            &:hover {
                background: #F8F9FA;
                a {
                    color: #18A0FB;
                }
            }
            &:not(:last-child){
                border-bottom: 1px solid #F8F9FA;
            }
            a {
                font-size: 0.75rem;
                line-height: 1.1rem;
                text-transform: none;
                color: #29394D;
            }
            &.not-read {
                background: #F1F2F4;
            }
            &:last-child {
                border-bottom: none;
            }

            .infos {
                padding-top: .5rem;
                text-align: right;
                font-style: normal;
                font-weight: 300;
                font-size: 10px;
                color: #6D7F92;
            }
        }
    }
    .e-box-icon {
        width: 2rem !important;
        padding-left: .2rem;
        padding-bottom: .3rem;
        height: 2rem;
    }
    .badge {
        position: absolute;
        background: #FE3745;
        color: #fff;
        border-radius: 50%;
        width: 1.2rem;
        height: 1.2rem;
        text-align: center;
        font-size: .8rem;
        bottom: 0.4rem;
        right: .2rem;
        font-weight: 500;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.social-links-login {
    a img {
        width: 3em;
        margin: 6px;
    }
}