div.tagsinput {
    border: 1px solid #b2bcc6;
    background: #fff;
    padding: 5px;
    border-radius: 4px;
    overflow-y: auto;
    margin-top: 0;

    span.tag {
        border: 1px solid rgba(239, 131, 24, 0.5);
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        display: block;
        float: left;
        padding-left: 9px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-family: Roboto;
        font-size: 13px;
        border-radius: 15px;
        color: #f7953e;
        margin-top: 8px;
        height: 23px;
        line-height: 22px;
        padding-right: 5px;

        a {
            font-weight: bold;
            color: #82ad2b;
            text-decoration: none;
            font-size: 11px;
        }

        &:hover {
            background-color: #f7953e;
            span {
                color: white;
            }
        }
    }

    input {
        margin: 0px;
        font-family: Roboto;
        font-size: 13px;
        border: 1px solid transparent;
        background: transparent;
        color: #000;
        outline: 0px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding-top: 8px;
        padding-bottom: 2px;
        width: 100% !important;
        box-sizing: content-box;
    }

    div {
        display: block;
        float: left;
        width: calc(100% - 10px);
    }
}
.tags_clear {
    clear: both;
    width: 100%;
    height: 0px;
}
.not_valid {
    background: #fbd8db !important;
    color: #90111a !important;
}
