.subscription {
    &-header {
        margin: 9px 0 -.5rem;
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__community {
            display: flex;
            align-items: center;
            margin-left: 2rem;

            .logo {
                background-repeat: no-repeat;
                background-size: contain;
                width: 110px;
                height: 55px;
                display: inline-block;
            }

            .name {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #29394D;
                margin-left: 18px;
            }
        }

        .drop-down-btn {
            margin-left: auto;
            position: relative;

            ul {
                list-style: none;
                padding: 8px 0;
                background: #FFFFFF;
                border: 1px solid  #F1F2F4;
                border-radius: 3px;
                position: absolute;
                right: 2rem;
                margin-top: -2.7rem;
                border: 1px solid #F1F2F4;
                box-sizing: border-box;
                box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
                display: none;

                li {
                    white-space: nowrap;
                }

                a {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #FF636E !important;
                    padding: 2rem;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                color: #18A0FB;

                span {
                    background: unset;
                }

                ul {
                    display: block;
                }
            }
        }
    }

    &__item {
        font-family: sans-serif;
        letter-spacing: 0;
        align-items: flex-start;
        border-bottom: 1px solid $ gray1;

        .material-icons {
            font-size: 1.8rem;
        }

        &:last-child {
            border-bottom: none;
        }

        &-primary {
            display: block !important;
        }

        &-subscription {
            li {
                .mdl-list__item-primary-content {
                    height: auto;
                }
            }
        }

        .mdl-checkbox {
            height: 2.2rem;
        }

        .mdl-checkbox__label {
            margin-left: .3rem;
        }
    }

    .subscription-content {
        &_header {
            font-family: Roboto;
            font-weight: normal;
            line-height: 16px;
            color: #6D7F92;
            margin: 7px;
        }

        &_switch.mdl-switch.is-upgraded {
            left: 14px;
            padding-left: 18px;
        }

        background-color: #F8F9FA;
        padding: 13px 25px;
        margin: 7px;
        height: auto;
        display: inline-flex;
        border-left: 2px solid #b2bcc6;
        border-radius: 0 5px 5px 0;

        &.border{
            border-color: #18A0FB;;
        }



        &.mdl-cell--6-col, .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
            width: calc(50% - 16px);
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #29394D;

            &.mdl-switch__label {
                left: -45px;
                top: 10px;
                color: #6D7F92;
                font-size: 12px;
            }
        }

        div {
            margin-top: .5rem;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            color: #6D7F92;
        }

        .note {
            font-size: .8rem;
            font-style: italic;
            margin-top: .8rem;
            font-weight: 300;
        }
    }

    .mandatory {
        color: hsl(122.7, 38.3%, 45.1%);
    }
}

.subscription-profile {
    background: #FFFFFF;
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 27px;
    margin: 20px auto;
}
