$field-height: 44px;

.ttp-form {
    margin: 12px 0;
    &__label {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #3C4E64;
        margin: 0 12px;
    }
    &> div {
        margin-top: 5px;
    }
    &__field {
        box-sizing: border-box;
        border-radius: 4px;
        height: $field-height;
        width: 100%;
        padding: 14px 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #29394D;
        background: #FAFBFB;
        border: 1px solid #F1F2F4;
    }
    &__select {
        background: #F1F2F4;
        color: #3C4E64;
        margin-top: 5px;
        padding-right: 30px;
        background-image: -webkit-linear-gradient(45deg, transparent 50%, rgba(255,134,106, .8) 60%), -webkit-linear-gradient(315deg, rgba(255,134,106, .8) 40%, transparent 50%), -webkit-linear-gradient(left, #fff, #fff);
        background-image: url("/dist/assets/svg/polygon.svg");
        background-origin: content-box;
        background-position: right -1rem center;
        background-repeat: no-repeat;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
        border: none;
        cursor: pointer;
        appearance: none;
        outline: none;
    }
    &__search {
        background: #F1F2F4;
        color: #3C4E64;
        margin-top: 5px;
        padding-right: 30px;
        background-image: -webkit-linear-gradient(45deg, transparent 50%, rgba(255,134,106, .8) 60%), -webkit-linear-gradient(315deg, rgba(255,134,106, .8) 40%, transparent 50%), -webkit-linear-gradient(left, #fff, #fff);
        background-image: url("/dist/assets/svg/search.svg");
        background-origin: content-box;
        background-position: right -1rem center;
        background-repeat: no-repeat;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
        border: none;
        cursor: pointer;
        appearance: none;
        outline: none;
    }
    .ttp-error {
        display: inline-block;
        width: 100%;
        font-size: 12px;
        text-align: right;
        color: #FF5656;
    }
    .ttp-form-filled {
        background-color: #F3FAFF;
        border: 1px solid #F3FAFF;
    }
    &__buttons {
        margin: 40px 0;
    }
}