$checkbox-height: 20px;
$background-color:#FFF;
$duration: .3s;

@-moz-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: calc($checkbox-height/2); }
}

@-webkit-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: calc($checkbox-height/2); }
}

@keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: calc($checkbox-height/2);  }
}

@keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: calc($checkbox-height * 1.1); }
}
@-webkit-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: calc($checkbox-height * 1.1); }
}
@-moz-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: calc($checkbox-height * 1.1); }
}

input[type=checkbox]{
    display:none;
}
.check-box-container {
    display: flex;
    align-items: center;
}

.check-box {
    height: $checkbox-height;
    width: $checkbox-height;
    position: relative;
    display: block;
    box-sizing: border-box;
    transition: border-color ease $duration/2;
    cursor:pointer;
    border: 1px solid rgba(109, 127, 146, 0.5);
    border-radius: 2px;
    background: #fff;

    &::before, &::after {
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: calc($checkbox-height * .2);
        background-color: $sea-buckthorn;
        display: block;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        -o-transform-origin: left top;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 2px;
        content: ' ';
        -webkit-transition: opacity ease .5;
        -moz-transition: opacity ease .5;
        transition: opacity ease .5;
    }

    &::before {
        top: calc($checkbox-height * .72);
        left: calc($checkbox-height * .41);
        box-shadow: 0 0 0 calc($checkbox-height * .05) $background-color;
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }

    &::after {
        top: calc($checkbox-height * .37);
        left: calc($checkbox-height * .05);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    &__title {
        margin-left: .8rem;
    }
}

input[type=checkbox]:checked + .check-box,
.check-box.checked {
    border-color:$sea-buckthorn;

    &::after{
        height: calc($checkbox-height * .5);
        -moz-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -o-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -webkit-animation: dothabottomcheck $duration/2 ease 0s forwards;
        animation: dothabottomcheck $duration/2 ease 0s forwards;
    }

    &::before{
        height: calc($checkbox-height * 1.2);
        -moz-animation: dothatopcheck $duration ease 0s forwards;
        -o-animation: dothatopcheck $duration ease 0s forwards;
        -webkit-animation: dothatopcheck $duration ease 0s forwards;
        animation: dothatopcheck $duration ease 0s forwards;
    }
}

input[type=checkbox]:checked + .check-box.light,
.check-box.checked.light {
    border-color:$dodger-blue;
}

.light.check-box {
    &::before, &::after {
        background-color: $dodger-blue;
    }
}