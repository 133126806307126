
.btb-folder-settings {
    display: flex;

    .btb-validation_wrapper {
        margin-bottom: 5px;
    }

    &__status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 5px;

        .ttp-label-folders.ttp-label {
            height: 20px;
            display: block;
            width: 13px;
        }
    }
    &__buttons {
        width: 25px;
        display: flex;
        align-content: center;
        align-self: auto;
        align-items: center;
        flex-direction: column;

        img {
            &:hover {
                opacity: .7;
            }
        }
    }
    &__content {
        width: auto;
        min-width: 100px;
        margin-left: auto;
        padding-left: 20px;
    }
    &__vas, &__stats {
        justify-content: center;
        display: flex;
    }

    &__vas {
        &__tag {
            cursor: pointer;
            background-color: $gray1;
            padding: .1em .7em;
            border-radius: 6px;
            font-weight: 100;
            &:hover {
                background-color: $gray02
            }

            &.off {
                background-color: #FF5656;
                color: #FFF;
            }
            &.on {
                background-color: #2495E1;
                color: #FFF;
            }
            &.warning {
                /* background-color: #2495E1;
                color: #FFF; */
            }
        }
    }

    &__stats {
        border-radius: 32px;
        margin-top: .3em;
        margin-right: .1em;
        
        &__AP, &__AR, &__delays {
            display:flex;

            &:hover {
                .btb-folder-settings__stats__AR__details,
                .btb-folder-settings__stats__AP__details,
                .btb-folder-settings__stats__delays__details {
                    display: block !important;
                }
            }

            &__details {
                position: absolute;
                display: none;
                z-index: 1000000;
                background-color: #FFF;
                min-width: 200px;
                top: 65px;
                right: -15px;
                box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
                border-radius: 4px 0px 4px 4px;
                padding: 7px;

                .stats__list  {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: .5em 0;

                    &__item {
                        display: flex;
                        width: 100%;
                        text-align: left;
                        padding: .4em 0;

                        &-desc {
                        }

                        &-sum {
                            margin-left: auto;
                            display: inline-block;
                            background-color: #2495E1;
                            padding: .1em .6em;
                            border-radius: 40px;
                            color: #FFF;
                            font-size: 12px;

                            &.danger {
                                background-color: #FF5656;
                            }
                            &.warning {
                                background-color: #FFD00D;
                            }
                            &.normal {
                                background-color: #06D9B1;
                            }
                        }
                    }

                    &__action {
                        display: flex;
                        justify-content: center;
                        background-color: #F8F9FA;

                        a img {
                            height: 13px;
                            padding: .6em;

                            &:hover {
                                opacity: .7;
                            }
                        }
                    }
                }

                .stats__title  {
                    display: flex;
                    padding-bottom: .5em;
                    border-bottom: 1px solid #F1F2F4;
                    text-transform: uppercase;
                }
            }
        }

        &__AP__badge, &__AR__badge, &__delays__badge {
            font-weight: 100;
            cursor: pointer;
            background-color: $gray1;
            padding: .1em .9em;
            border-radius: 32px;
            color: #FFF;
            img {
                height: 15px;
                padding-right: .1em;
            }
        }

        &__AP, &__AR {
            &:hover {
                .btb-folder-settings__stats__AR__badge,
                .btb-folder-settings__stats__AP__badge {
                    background-color: #2495E1;
                }
            }
        }
        &__delays__badge {
        
            &.danger {
                background-color: #FF5656;
            }
            &.warning {
                background-color: #FFD00D;
            }
            &.normal {
                background-color: #06D9B1;
            }
        }

        
    }
   
}

.wtb-default-channel {
    text-transform: capitalize;
    background-color: #d5d5d5 !important;
    border-radius: 6px;
    font-weight: 100;

    &:empty {
        display: none;
    }
    &.off {
        color: #FFF !important;
        background-color: #FF5656 !important;
    }

    &.warning {
        color: #FFF !important;
        background-color: #FFD00D !important;
    }

    &.on {
        background-color: #2495E1 !important;
        color: #FFF;
    }
}