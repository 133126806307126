@import "variables.css";
@import "utils/_checkbox.pcss";
@import "utils/_ttp-datatable.pcss";
@import "utils/_buttons.scss";
@import "layouts/_page-header.scss";
@import "menus.pcss";
@import "notification.pcss";
@import "release.pcss";
@import "cross-sharing.pcss";
@import "organizations/contacts/dashboard.pcss";
@import "organizations/hq/main.pcss";
@import "profil/covers-photos.pcss";
@import "profil/user-profil.pcss";
@import "btb-settings.pcss";
@import "forms/forms.pcss";
@import "dialogs.pcss";
@import "community/select-community.pcss";
@import "profil/social-network.pcss";
@import "onboarding/main.pcss";
@import "import/import.pcss";
@import "ttp-stepper.pcss";
@import "profil/newsletter-subscription.pcss";
@import "rgpd/rgpd.pcss";
@import "utils/tagsinput.pcss";

$main: 80,129,190;

$offset: 187;
$offset4: 46.75;
@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

@keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
        stroke-dashoffset: $offset4;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform:rotate(450deg);
    }
}

 html,body {
    margin:0;
    padding:0;
    height:100%;
    color: hsla(213, 37%, 24%, 0.88);
 }
 .mdl-layout__content {
 	  padding-bottom:135px;
 }
 .mdl-layout {
     position: static !important;
 }


 #ttp-root.home-page .mdl-layout {
     position: absolute !important;
 }
 #ttp-root.home-page .mdl-layout__content {
     padding-bottom: 0;
 }

.ttp-label {
    font-size   : 1.5em;
    font-family : sans-serif;
    padding     : 1em 2em;
    text-align  : center;
}

.ttp-label-folders, .ttp-label-vas {
    padding     : .1em .5em;
    margin-right: 20%;
}

#ttp-root .ttp-data-table .ttp-grid-start.ttp-wheretobill-cell, #ttp-root .ttp-data-table .ttp-grid-start.ttp-billtobox-cell{
    display: inline-flex;
    justify-content: right !important;
    flex-direction: row;
    .ttp-label-folders {
        @extend .ttp-label-folders;
        margin-right: .5em;
    }
}

.ttp-label-shared {
    display : block;
    padding : .2em .5em;
    /*width   : 80%;*/
    /*border: 1px solid #ccc;*/
    font-size: .7em !important;
}

.ttp-data-table-center-th tr {
th, td {
    text-align: center;
}
}

.ttp-label-success {
    @extend .ttp-label-shared;
    background : $ttp-label-success;
}
.ttp-select-success {
    background-color : $ttp-label-success!important;
    background-size: 5px 5px, 5px 5px, 0rem 2.5rem!important;
    padding-left: .4rem;
}

.ttp-label-warning {
    @extend .ttp-label-shared;
    background : $ttp-label-warning;
}

.ttp-select-warning {
    background-color : $ttp-label-warning!important;
    background-size: 5px 5px, 5px 5px, 0rem 2.5rem!important;
    padding-left: .4rem;
}

.ttp-label-danger {
    @extend .ttp-label-shared;
    background : $ttp-label-danger;
}

.ttp-select-danger {
    background-color : $ttp-label-danger!important;
    background-size: 5px 5px, 5px 5px, 0rem 2.5rem!important;
    padding-left: .4rem;
}

.ttp-label-dropdown {
    font-size : 18px;
    height    : 25px;
    position  : absolute;
    top       : 17px;
}
.div-search{
    width: 80%;
    margin-left: 30%;
    display: inline-block;
}
.label-search{
    width: 200px;
    margin: 5px;
    display: inline-block;
}
.input-or-select-search{
    margin: 5px;
    width: 200px;
}
.ttp-mini{
    width: 404px;
}

@custom-media --small-viewport (max-width: 736px);

@media (--small-viewport) {
    body {
        font-size: 12px;
    }
}

.ttp-width {
    max-width: $maxWidth;
    width: 100%;
    margin: auto;
}

#ttp-root {

.nav-filter{
    display: flex;
    justify-content: flex-start;
    margin: 45px 0 10px 0;
    .ttp-button {
        padding-left: $padding1;
        padding-right: 14px;
        font-weight: 500;
        background: #FFFFFF;
        border: 0.5px solid rgba(109, 127, 146, 0.5);
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
    }
    .nav-item {
        position: relative;
    }
    .mdl-textfield {
        width: 200px;
        padding: 0px $padding1;
/*        padding-left: $padding1;
        padding-right: $padding1;*/

        select {
            height: 40px;
        }
    }
    .blacklisted{
        background-color: #FF866A;
        color: #ffffff;
    }
}

.ttp-display-none {
    display: none !important;
}

.mdl-switch__track{
    background: #D8DDE2;
}

.mdl-switch__thumb{
    background: #B2BCC6;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
}

.mdl-switch.is-checked .mdl-switch__track {
    background-color: #C7E8FE;
}
.mdl-switch.is-checked .mdl-switch__thumb {
    background: #18A0FB;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
}

.mdl-radio__inner-circle {
    width: 17px;
    height: 17px;
    background: $blue1;
    top: 7px;
    left: 3px;
}
.mdl-radio__outer-circle {
    width: 23px;
    height: 23px;
    border-width: 1px;
}
.mdl-radio.is-checked .mdl-radio__outer-circle {
    border-color: $blue1;
}

h1 {font-size: 3em;}
h2 {font-size: 2.6em;}
h3 {font-size: 2em;}
h4 {font-size: 1.6em;}
h5 {font-size: 1.2em;}
h1,h2,h3,h4,h5 {
    font-weight: 300;
}

h1.ttp-bread-title {
    border-left: 5px solid $orange1;
    font-weight: 500;
    font-size: 1.2rem;
    margin: $margin1 0;
    color: $black07;
}
h3,h4,h5,h6 {
    margin: $margin1 0;
}
.ttp-config.mdl-dialog {
    width: 58%;
    padding: $padding3;
input {
    height: 50px;
}
}
.mdl-dialog.ttp-config {
#dir-settings {
.ttp-config-bottom-section {
    padding-top: $padding2;
.ttp-button{
    margin:0 5px;
}
.ttp-button{:last-child
margin-right:0;
}
.ttp-button:hover i.material-icons {
    color: $white1;
}
.ttp-alert {
    margin: 0  5px 0 0;
}
}
}
}
i.ttp-icon-close{
    cursor: pointer;
    color: $blue1;
    position: absolute;
    right: 17px;
    top: 17px;
    z-index: 99;
}
.mdl-dialog{
i.ttp-icon-close:hover{
    color: $blueDark
}
}

.ttp-config {
    width: 90%;
    margin: auto;
h3,h4,h5 {
    margin: 0;
}
.ttp-config-section.ttp-config-section__app {
    width: 100%;
}
/*#dir-settings {
    form {
        padding-top: $padding2;
    }
    h3,.material-icons {
        color: $blue1;
        text-transform: lowercase;
    }
    .doc-icons {
        background-image: url('/dist/assets/images/efff.png');
        background-repeat: no-repeat;
        background-size: 35px 35px;
        size: 35px 35px;
    }
}*/
/*.ttp-config-channels {
    margin: $margin2 auto;
    width: 84%;
    padding: $padding2;
    background-color: rgba(80,129,190, .02);
    border: 1px solid $blue05;
    .ttp-config-section:last-child {
        .ttp-config-content {
            padding-bottom: 0;
        }
    }
}
.ttp-config-content {
    padding: $padding2 $padding1;
}
.ttp-config-mail__content {
    position: relative;
    .ttp-config-mail__save {
        position: absolute $padding2 $padding1 * *;
    }
}*/
.ttp-config-head {
    padding: $padding1;
    background-color: $blue005;
    font-weight: 100;
    margin-bottom: 15px;
}
.ttp-config-head.app_activated {
    background-color: $blue005;
}

.ttp-config-head.app_deactivated {
    /*background-color: $black01;*/
    opacity: .7;
    &:hover {
    	opacity: 1;
    }
}

.ttp-config-head__title {
    font-size: 1.5rem;
}
.ttp-config-head__desc {
    margin-top: $margin1;
    color: $grayDark;
    font-style: italic;
}

.ttp-apps .ttp-valign:nth-child(2) {
    width: 100%;
}
.ttp-apps .ttp-channel__icon-large {
    width: 175px;
}
.ttp-apps .directory {
    background-image: url(/dist/assets/images/wheretobill_logo.png);
    background-size: 130px;
}
.ttp-apps .emailing {
    background-image: url(/dist/assets/images/apps/emailing.png);
}
.ttp-apps .blog {
    background-image: url(/dist/assets/images/apps/blog.png);
}

.ttp-apps .payment {
    background-image: url(/dist/assets/images/payment.png);
}

.ttp-apps .membership {
    background-image: url(/dist/assets/images/membership-logo.png);
    background-size: 55px;
}

.ttp-apps .survey {
    background-image: url(/dist/assets/images/apps/survey.png);
}

.ttp-apps .flux {
    background-image: url(/dist/assets/images/apps/e-news.png);
}
.ttp-apps .chat {
    background-image: url(/dist/assets/images/apps/talk.png);
}

.ttp-apps .portal {
    background-image: url(/dist/assets/images/apps/portal.png);
    background-size: contain;
}

#ttp-blog-button, #ttp-flux-button, #ttp-chat-button, #ttp-payment-button, #ttp-survey-button, #ttp-membership-button {
  margin-left: 80px;
}

}

.ttp-on-off {
    color: $grayDark;
}
.ttp-on-off::after {
    background-color: $gray1;
}
.ttp-on-off::before {
    background-color: $redOff;
    color: $white1;
}
.ttp-on-off.on::after {
    background-color: $greenOn;
    color: $white1;
}
.ttp-on-off.on::before {
    background-color: $gray1;
    color: $grayDark;
}
.ttp-on-off::before,  .ttp-on-off::after{
    content: "OFF";
    padding: 6px 0;
    display: inline-block;
    text-align: center;
    size: 60px 18px;
    cursor: pointer;
    font-weight: 700;
    transition-property: background-color, color;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
}
.ttp-on-off::after {
    content: "ON";
}

.mdl-textfield:not(.is-invalid) .mdl-textfield__input {
    border-bottom-color: $blue02;
}

.mdl-textfield:not(.is-invalid) .mdl-textfield__input.is-highlighted,
.mdl-textfield:not(.is-invalid) .ttp-select.is-highlighted{
    background-color: $orangeLite;
}

.mdl-textfield:not(.is-invalid) .ttp-select.is-highlighted{
    background-position: calc(100% - 10px) 1.4em,calc(100% - 5px) 1.4em,100% 2.8em;
}

.ttp-textarea-container {

    & > div {
        max-height: 200px;

        & > .mdl-textfield__textarea {
            padding : 0;
            resize  : none;
            height : 150px;
        }

        & > label {
            top : -8px;
        }
    }
}

.ttp-welcome-message {
    display         : flex;
    align-items     : center;
    justify-content : center;
    font-size       : 2.8rem;

    h1 {
        font-weight: 300;
    }
}

#privacy-and-terms-page {
    h5{
        font-weight : 500;
        color: #3C4E64;
    }
}

.ttp-home-lang {
    position : absolute;
    top : 0;
    right    : 0;
    margin-right   : 20px;

    & > ul {
        padding : 0;

        & > li {
            display: flex;
        }
    }
}

#form-container {
    padding-bottom: 25px;
}


/*.mdl-menu__container li:hover,
.headerType .tt-cursor,
.headerType span:hover
{
    background-color: $blue01;
}*/
.headerType span,
.headerType h4,
.mdl-menu__container a {
    text-decoration: none;
    display: block;
    width: auto;
    padding: 0 16px;
    text-transform: uppercase;
    font-size: 12px;
}
.mdl-menu__container {
    li {
        height: 40px;
        line-height: 40px;
        padding: 0;
        background: none;
    }
    .mdl-ripple {
        background-color: $blue05;
    }
}

.mdl-layout__header .mdl-layout__drawer-button {
    color: $blue1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdl-layout__obfuscator.is-visible {
    background-color: $blue02;
}

.ttp-container {
    margin: auto;
}

.ttp-main {
    padding-top: 15px;
    min-height: 95%;
}

#ttp-root {
    .ttp-welcome-message h1{
        letter-spacing: 12px;
    }
}

.ttp-capitalize {
    text-transform: capitalize;
}

.ttp-home {
    display: flex;
    background-image: url('/dist/assets/images/home-background.jpg');
    background-size: cover;
    background-position: center;
    min-height: inherit;
    padding-top: 0;
    a {
        padding-bottom: 2px;
    }

    min-height: inherit;
    padding-top: 0;

    .ttp-def {
        padding-top: $padding3;
        font-family: "Roboto", "Helvetica","Arial",sans-serif;
        letter-spacing: 1.2px;
        color: $white1;
        .mdl-textfield__label {
            color: rgba(255,255,255, 0.7)
        }
        a, .ttp-newto {
            font-weight: 100;
            color: $white1;
        }
        .ttp-newto {
            margin:40px 0 20px 0;
            a {
                padding-bottom: 4px;
            }
        }
        .ttp-newto_strong {
            font-size: $fontSize1-05;
            font-weight: 300;
            letter-spacing:normal;
        }
        a {
            border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        }
        a:hover {
            border-bottom-color: #fff;
        }
        h1,h2,h3,h4,h5 {
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
        }
        h1:first-child, h5:first-child {
            margin-top: 0px;
        }
        input {
            color: $white1;
        }
    }
    .mdl-textfield {
        width: 100%;
    }
    .mdl-textfield__label {
        color: $white04;
        font-weight: 300;
    }
    .mdl-textfield .mdl-textfield__input {
        border-bottom-color: $white04;
    }
    .mdl-textfield__label::after {
        background-color: $white1;
        height: 1.5px;
    }
}

.ttp-grid, .ttp-grid-start, .ttp-grid-end, .ttp-grid-center,
.ttp-grid-center_margin1, .ttp-grid-end_margin1 {
    display: flex;
    justify-content: space-between;
}

.ttp-grid-start {
    justify-content: flex-start;
}

.ttp-grid-end, .ttp-grid-end_margin1 {
    justify-content: flex-end;
}


.ttp-grid-center, .ttp-grid-center_margin1 {
    justify-content: center;
}

    .ttp-button, .ttp-bluedark-button, .ttp-blue-button,
    .ttp-button-orange,
    .ttp-button-danger,
    .ttp-button-success
     {
        color: $blue1;
        background-color: transparent;
        border: 1px solid $blue08;
        border-radius: 2px;
        height: 38px;
        line-height: 38px;
        font-size: .9rem;
        font-weight: 300;
        padding-left: 20px;
        padding-right: 20px;
        letter-spacing: .085rem;
        transition: background-color .2s ease-out,color .2s ease-out;
        margin-left:2px;
    }


.ttp-grid-end_margin1 {
    * {
        margin-left: $margin1;
    }
}

.ttp-grid-center_margin1 {
    * {
        margin: 0 $margin1;
    }
}

.ttp-grid-center_margin05 {
    * {
        margin: 0 $margin05;
    }
}

.ttp-button, .ttp-bluedark-button, .ttp-blue-button,
.ttp-button-orange,
.ttp-button-danger,
.ttp-button-success
{
    color: $blue1;
    background-color: transparent;
    border: 1px solid $blue08;
    border-radius: 2px;
    height: 38px;
    line-height: 38px;
    font-size: .9rem;
    font-weight: 300;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: .085rem;
    transition: background-color .2s ease-out,color .2s ease-out;
}

.ttp-button-disabled {
    opacity: 0.4;
    transition: opacity .5s ease-in-out;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
         color: $blue1 !important;
         background-color: transparent !important;
    }
}

.ttp-icon-disabled {
    opacity: 0.4;
    transition: opacity .5s ease-in-out;
    &:hover {
         color: $blue1 !important;
         background-color: transparent !important;
     }
}

.ttp-button.ttp-button-danger {
    border-color: $redOff;
    color: $redOff;
}

.ttp-button.ttp-button-danger:hover {
    background-color: $redOff;
    color: $white1;
}

.ttp-button.ttp-button-success {
    border-color: $greenOn;
    color: $greenOn;
}

.ttp-button.ttp-button-success:hover {
    background-color: $greenOn;
    color: $white1;
}

.ttp-dropbox-content , .ttp-onedrive-content, .ttp-googledrive-content{
    font-size: $fontSizeForm;
    margin: $margin2 0 $margin3 0;
div:last-child {
    margin-left: $margin2;
}
}

.ttp-text-success {
    color: $greenOnDark !important;
}

.ttp-text-gray {
    color: $grayDark !important;
}

.ttp-text-error {
    color: $redOff !important;
}

.ttp-text-warning {
    color: $orange1 !important;
}

.ttp-text-normal {
    font-size: $fontSize1-2;
    font-weight: 300;
}

.ttp-disabled {
    opacity: 0.4;
    transition: opacity .5s ease-in-out;
}

.ttp-label {
     color: $white1;
     font-size: 0.9rem;
     /*padding: 2px 10px;*/
     /*text-shadow: 1px 1px 1px $grayTextShadow;*/
}
.ttp-label.warning {
    background-color: $orange1;
}
.ttp-label.on {
    background-color: $greenOn;
}
.ttp-label.off {
    background-color: $redOff;
}
.ttp-label.neutral {
    background-color: hsl(0, 0%, 49%);
}
.ttp-button.ttp-link-dropbox {
    background-image: url('/dist/assets/images/dropbox.png');
    background-repeat: no-repeat;
    background-position: $padding1 50%;
    background-size: 20px 20px;
    padding-left: $padding3;
    padding-right: $padding2;
    line-height: 45px;
    height: 45px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .5s linear;
    font-weight: 500;
}

.ttp-button.ttp-link-onedrive {
    background-image: url('/dist/assets/images/onedrive.png');
    background-repeat: no-repeat;
    background-position: $padding1 50%;
    background-size: 20px 20px;
    padding-left: $padding3;
    padding-right: $padding2;
    line-height: 45px;
    height: 45px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .5s linear;
    font-weight: 500;
}

.ttp-button.ttp-link-googledrive {
    background-image: url('/dist/assets/images/googledrive.png');
    background-repeat: no-repeat;
    background-position: $padding1 50%;
    background-size: 20px 20px;
    padding-left: $padding3;
    padding-right: $padding2;
    line-height: 45px;
    height: 45px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .5s linear;
    font-weight: 500;
}

.ttp-link-dropbox.ttp-button-danger {
    background-image: url('/dist/assets/images/dropbox-red.png');
}

.ttp-button.ttp-link-dropbox:hover {
    background-image: url('/dist/assets/images/dropbox_white.png');
}

.ttp-link-onedrive.ttp-button-danger {
    background-image: url('/dist/assets/images/onedrive-red.png');
}

.ttp-button.ttp-link-onedrive:hover {
    background-image: url('/dist/assets/images/onedrive-white.png');
}

.ttp-link-googledrive.ttp-button-danger {
    background-image: url('/dist/assets/images/googledrive.png');
}

.ttp-button.ttp-link-googledrive:hover {
    background-image: url('/dist/assets/images/googledrive.png');
}

.ttp-button {
i, span {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}
}
.ttp-button i{
    margin-right: 5px;
    font-size: 1.2em;
}

.ttp-button-sm{
    padding: 0 8px;
}

.ttp-button:hover, .ttp-button.is-active {
    background-color: $blue1;
    color: $white1;
    box-shadow: none;
    border: none;
}

.ttp-button-orange:hover {
    background-color: $orange1;
    color: $white1;
}

.ttp-button-orange {
    border-color: $orange1;
    color: $orange1;
}

.ttp-bluedark-button {
    background-color: $blueDark;
    border-color: $whiteCyan;
    color: $white1;
}

.ttp-button--download {
    display: block;
    width : 300px;
    text-align: center;
    margin: auto;
    margin-top: 100px;
}

.ttp-blue-button {
    background-color: $blue1;
    color: $white1;
}

.ttp-valign {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ttp-center {
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.mdl-layout__drawer {
    padding-top: 60px;
    background-color: #5081be;
    padding-left: 10px;
    box-shadow: inherit;
    border: none;
    a, span {
      	color: #fff;
      	font-size: 20px;
      	font-weight: 300;
      	padding: 0;
      	i {
   	    	position: relative;
	    	top: -2px;
	    	left: -4px;
      	}
    }
    span {
   		font-weight: 500;
   		i {
   			top: 3px;
   		}
   	}
    .mdl-navigation{
        padding-top: 0;
    }
    .mdl-navigation > ul > li {
        color: $blueDark;
        padding-left: 20px;
        height: 57px
    }
    ul.ttp-menu__dropdown{
        padding-left: 0
    }
    ul.ttp-menu__dropdown li{
        padding: 0;
        list-style: none;
    }
    .ttp-admin-link .material-icons{
        display: none;
    }
    ul.ttp-menu__dropdown li a{
        display: block;
        padding: 10px 40px 10px 75px;
        font-size: 14px;
    }
    .mdl-navigation .mdl-navigation__link:hover,
    ul.ttp-menu__dropdown li a:hover
     {
      background-color: #5081be;
    }
    .mdl-layout-title {
      font-size: 1.2rem;
      background-color: $blue01;
      line-height: 50px;
      .material-icons {
          font-size: 1.4rem;
          position: relative -1px * * *;
          vertical-align: middle;
      }
    }

    > .mdl-layout-title {
        padding-left: 22px;
        background: #fff;
        color: #5081be;
    }
    i.ttp-icon-close {
        color: #fff;
        font-size: 22px;
    }
    .mdl-list {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
    }
}



.mdl-textfield__error , .ttp-right{
    right: 0;
}

.ttp-left{
    left: 0;
    text-align: left;
}

.mdl-checkbox .mdl-textfield__error {
    right: auto;
    left: 20px ;
}

.mdl-textfield__label::after {
    background-color: $blue1;
    height: 1px;
}

.ttp-done-icon{
    position: absolute 15px -25px * *;
    padding: 20px 0;
}

.ttp-input-icon{
    position: absolute 15px -65px * *;
    padding: 20px 0;
}

.passwordStrength {
    width: 100%;
    position: absolute;

    /*styles for overlaying progress bar on top of password input*/
    height: 5px; /* = 24px (input heigth) + 14px (input padding-top) + 11px (input padding-bottom)*/
    z-index: -1;

& > .progressbar {
        background-color: $red1;
    }
& > .bufferbar {
        /*background-image:linear-gradient(to right,$whitegray,$whitegray),linear-gradient(to right,$whitegray ,$whitegray)*/
        background: none;
    }
}

.ttp-password.success .mdl-textfield__label::after {
    background-color: $greenDark;
}

.ttp-password.success {
.passwordStrength > .progressbar {
    background-color: $green1;
}
}

#ttp-root #iban-group{
    height: auto;
}
#ttp-root #iban-group .ttp-textfield{
    width: 96%
}
#iban-group{
    position: relative;
.iban-bloc{
    position: relative;
    margin-top: 12px;
}
.ttp-textfield {
input {
    text-indent: 35px;
}
}
.remove-iban{
    position: absolute 34px -35px * *;
    font-size: 2em;
    cursor: pointer;
    display: block;
    color: $black04;
    top: 16px;
}
.check-iban {
    position: absolute 30px * * 0;
    margin: 0;
    z-index: 4;

    margin-left: -.50em;
    top: 16px;
}
}

.ttp-snackbar {
    position: fixed 0 * * 50%;
    padding: 20px 40px;
    background-color: $blue08;
    opacity: 0;
    color: $white1;
    font-size: 1.2em;
    font-weight: 500;
    z-index: 1001;
    border-radius: 2px;
    transform: translate(-50%, -100%);
    transition: .25s cubic-bezier(.4,0,1,1);
}

.ttp-snackbar.ttp-snackbar-active {
    opacity: 1;
    transform: translate(-50%, 100px);
    transition: transform .25s cubic-bezier(0,0,.2,1);
}

.ttp-snackbar.warning {
    background-color: $orange08;
}

.ttp-snackbar.success {
    background-color: $greenDark08;
}

.ttp-snackbar.error {
    background-color: $red08;
}

.ttp-alert {
    padding: $padding1;
    font-size: $fontSize1-05;
    border-width: 1px;
    border-style: solid;
    margin: $margin1 0;
}
.ttp-alert.warning {
    background-color: rgba(252, 248, 227, 0.35);
    border-color: rgba(245, 231, 158, 0.35);
    color: #8a6d3b;
}
.ttp-alert.info {
    background-color: rgba(217, 237, 247, 0.25);
    border-color: rgba(154, 207, 234, 0.25);
    color: #31708f;
}

#ttp-group-tag{
    width: 358px;
}

.ttp-data-table-row-hover {
    background-color: $blue01;
    transition-duration: .28s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.ttp-data-table-row-unhover {
    background-color: white !important;
    transition-duration: .28s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.ttp-clear-field {
    position: absolute 31px 5px * *;
    font-size: 2.1rem;
    color: $redOff !important;
    cursor: pointer;
    opacity: .2;
    transition: opacity .4s;
}
.ttp-clear-field:hover {
    opacity: .8;
}

.nav-item #filter.ttp-filter, 
.nav-item #exclude.ttp-filter, 
.nav-item #other-filter.ttp-filter,
.nav-item #timeFilter.ttp-filter
{
    position: absolute;
}

#filter.ttp-filter, #exclude.ttp-filter, #other-filter.ttp-filter, #timeFilter.ttp-filter{
    padding: 0;
    margin-bottom: 15px;
    position: relative;

    .ttp-employee_filter-bloc {
        .ttp-typeahead-loader {
            top: 5px;
        }
    }

    .wrapper{
        padding: 1rem;
        left: 0;
        width: 100%;
        display: flex;
        /*box-shadow: 0px 1px 2px 0px $gray08;*/
        background-color: $blue005;
        border-top-right-radius: 0;
        border: 1px solid $blue01;
    }
    .close-refresh {
        position: absolute;
        top: -33px;
        right: -1px;
        border: 1px solid $blue01;
        /*box-shadow: 0px -1px 2px -2px $blue01;*/
        background-color: $blue005;
        border-bottom: none;
    /*    border-top-right-radius: 5px;
        border-top-left-radius: 5px;*/
    }
    .ttp-filter-input:first-child{
        padding-bottom: 0;
    }
    .ttp-filter-input:nth-child(2){
        border-bottom: 1px solid #eee;
    }
    .ttp-filter-footer {
        border: 1px solid $blue01;
        background-color: $blue005;
        padding: 10px 10px 10px 0;
        width: inherit;
        height: 35px;
        margin: 10px 10px 0;
    .mdl-textfield {
        padding: 0 0 0 10px
    }
    .active {
        font-weight: bold;
    }
}

.ttp-form-elem{
    margin: 0;
    position: relative;
}
h5{
    color: $blue1;
    font-size: 13px;
    margin: 0 0 6px;
    /* text-align: center; */
}
.mdl-textfield{
    padding: 2px 12px;
}
.mdl-checkbox__label{
    font-size: 1rem;
}
.mdl-textfield input{
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 2px;
    color: $black07;
    font-size: 12px;
    padding: 5px;
    width: 96%;
}
.ttp-btn-filter{
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #9e9e9e;
    cursor: pointer;
    padding: 8px 9px;
}
.ttp-filter-footer div:last-child{
    text-align: right;
}
.icon{
    float: left;
    font-size: 20px;
    line-height: 14px;
    margin-right: 2px;
}
}

a {
    color: $blue1;
    text-decoration: none;
}
.mdl-layout__header-row > a {
    display: flex;
}

.mdl-data-table {
    border: none;
}
.pagination {
    .mdl-button {
        min-width: 35px;
        line-height: 30px;
        height: 30px;
        border: none;
        font-weight: 500;
        font-size: .9rem;
    }
    .mdl-button:hover {
        box-shadow: 0px 0px 10px $gray1;
    }
}

@media (--small-viewport) {
    .ttp-hide-on-phone {
        display: none;
    }
    .mdl-layout__drawer {
        width: 100%;
        transform: translateX(-100%);
    }
    .mdl-layout__drawer.is-visible {
        transform: translateX(0);
    }
    .ttp-snackbar {
        padding: 10px 20px;
        width: 70%;
    }
}

.mdl-tabs__tab-bar {
    height: 64px;
    border-bottom: 1px solid $blue05;
}
.mdl-tabs__tab {
    color: $black04;
    transition: color .15s linear;
    height: 64px;
    line-height: 64px;
}

.ttp-tabs__tab-content span{
    transition: opacity .5s ease-in-out;
}

.ttp-tabs__tab-disabled {
.ttp-tabs__tab-content span{
    opacity: .4;
}
}

.ttp-inactive {
    color: $black1;
    margin-top: 5px;
    font-weight: 700;
    font-size: 0.8rem;
}

.ttp-channel-invalid {
    color: $orange08;
    margin: 0;
    font-size: 1em;
}

.ttp-channel-valid {
    color: $greenDark08;
    margin: 0;
    font-size: 1em;
}

.ttp-channel-error {
    color: $redOff;
    margin: 0;
    font-size: 1em;
}

.ttp-text-right {
    text-align: right;
}

.ttp-tabs__footer {
    padding: 0 0 $padding1 0;
h6 {
    margin-top: 0;
    padding-top: 0;
}
}

.mdl-tabs__tab-bar {
    border-bottom-color: $blue02;
}
.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active::after {
    background-color: $blue1;
}
.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
    background-color: $orangeLite;
}

.mdl-tabs__panel {
    padding: $padding2;
}

#ttp-footer {
    background: rgba(255, 255, 255, 0.95);
    font-size: 0.8rem;
    font-weight: 100;
    text-align: center;
    letter-spacing: .1rem;
    width:100%;
    bottom:0;
    left:0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: none;

    .footer-div {
        padding: 1px 0;
    }

.ttp-powredby {
    font-size: 0.75rem;
& > span {
        letter-spacing: .2rem;
        font-weight: 500;
    }
}
div {
    padding: 5px 0;
}
a {
    color: $blue1;
    font-weight: 300;
    transition: color .25s;
}
a:hover {
    color: $blueDark;
}

}

.profil {
    margin: 0 13rem;
}

#profile-img {
    .ttp-photo-preview {
        height: 218px;
        width: 218px;
        position: relative;
        border-radius: 50%;
        background: #fff;
        &.ttp-avatar {
            border-radius: 50%;
            border: 8px solid #FFFFFF;
        }
    }
    .ttp-photo-edit {
        position: absolute;
        display: flex;
        justify-content: center;
        cursor: pointer;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        transform: translate(-50%);
        &:hover {
             background: #F1F2F4;
        }

        img {
            height: 25px;
            padding-top: 7px;
        }
    }
    .ttp-profile-organization-container gfi.ttp-photo-button {
        top: 50px;
        right: 50px;
    }
}

.ttp-photo-crop-container {
    padding: 0 !important;
img {
    border-radius: 0px;
}
.cropit-preview-image-container {
    cursor: move;
}
.cropit-preview-background {
    opacity: .2;
}
.cropit-preview, .ttp-photo-crop-zoom {
    margin: 0 auto;
}
.cropit-preview {
    background-color: #f8f8f8;
    background-size: cover;
    border: 1px solid #ccc;
    margin-top: 7px;
    width: 280px;
    height: 280px;
}
.ttp-photo-crop-zoom {
    padding-top:5px;
    margin-top:40px;
    margin-left: 180px;

.mdl-slider {
&:active::-webkit-slider-thumb,
&::-webkit-slider-thumb {
     background: $blue1;
 }

&:active::-moz-range-thumb,
&::-moz-range-thumb {
     background: $blue1;
 }

&:active::-ms-thumb,
&::-ms-thumb {
     background: $blue1;
 }
}
.mdl-slider__background-lower {
    background: $blue1;
}
}
}
.mdl-textfield {
    vertical-align: top;
    max-height: 90px;
}

.mdl-tooltip {
    max-width: 400px;
    will-change: inherit;
}

/* TTP Twitter Typeahead */
.twitter-typeahead {
    width: 100%;
}
.tt-menu,
.ttp-filter .ttp-menu {
    width: 100%;
    min-width: 200px;
    max-height: 240px;
    overflow-y: auto;
    box-shadow: 0px 8px 20px $gray1;
    border-radius: 2px;
    background-color: $white1;
    padding-bottom: 8px;
    padding-top: 0;
}

.ttp-filter  .ttp-menu {
    padding: $padding1 $padding1 $padding3;
    overflow: hidden;
    z-index: 2;
    .ttp-filter-checkbox {
        overflow-y: auto;
        max-height: 200px;
    }
    ul {
        padding: 0;
        margin: .5rem 0 0;
    }
    li {
        display: block;
        padding: 5px;
        height: 25px;
    }
    li:hover {
        background: $blue01;
    }
    .mdl-checkbox__label {
        font-size: 1rem;
        line-height: inherit;
    }
}


.ttp-tt-suggestion {
    display: block;
    cursor: pointer;
    padding: 10px 14px 10px 15px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
}

.ttp-tt-suggestion:hover, .tt-cursor {
    background-color: $blue01;
    color: $blueDark;
}

.ttp-tt-header{
    font-family: Roboto;
    border-bottom: 1px solid $blue01;
    line-height: 24px;
    margin: 10px 0;
    padding: 0 0 8px 8px;
}

.ttp-typeahead-loader {
    display: inline-block;
    position: absolute;
    right: 4px;
    top: 31px; /* = 20px (mdl textfield padding-top) + 25px (full mdl textfield width) - 14px (mdl spinner heigth/2) */
}

/* /TTP Typeahead */
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
    background: $orange1;
}

#ttp-profile-email, #ttp-profile-phone {

.ttp-row {
    padding-bottom: 20px;
}
.ttp-email-field {
    position: relative;
.mdl-textfield {
    padding: 0;
}
.mdl-textfield__label::after {
    bottom: 0;
}
}

.mdl-textfield__label {
    top: inherit;
    font-size: inherit;
    padding-bottom: 10px;
}
}

.ttp-checkbox-newsletter {
    padding: 3rem 1rem 1rem;
    background-color: rgba(239,131,24, .035);
    width: 100% !important;
    .check-box {
        margin-right: 2rem;
        border: unset;
        display: inline;
    }
    .check-box::before {
        left: calc(-36.3rem * .41);
        top: calc(15px * .72);
    }
    .check-box::after {
        left: calc(-3.87rem * 4);
        top: calc(4px * .72);
    }
    .checkbox-title {
        top: -2rem;
    }
    .checkbox-label {
        left: -15.5rem;
        font-size: .9rem;
    }
    .mdl-checkbox__box-outline {
        border-color: #F7953E;
    }
    .mdl-checkbox__label {
        line-height: 28px;
    }
}

.ttp-email-panel {
.mdl-cell--6-col, .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(48% - 16px);
}
}

.show-import-data.import-users {
    width: 53%;
}
.show-import-data {
    width: 90%;
    top: 0;
form {
    padding: 20px 20px 0;
    overflow: hidden;
}

.mdl-dialog__content {
    max-height: 400px;
    overflow: auto;
    padding: 0 0 10px;
}

th {
    position: relative;
}
.mdl-tooltip {
    background-color: $red08;
    font-size: 0.9rem;
    white-space: normal;
    word-wrap: break-word;
}
.mdl-tooltip:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid $red08;
    position: absolute;
    bottom: -10px;
    right: 45%;
}
.error-row {
td {
    border-bottom: 2px solid $red06;
}
.error-val {
    color: $red08;
    background: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 4px,
    $red02 1px,
    $red02 5px
    );
    background-repeat: no-repeat;
    background-size: 99% 99%;
    background-position: center;
}
}
    .notif-row {
        .notif-val {
            color: $grayDark;
            background: repeating-linear-gradient(
                45deg,
                #fff,
                #fff 4px,
                $gray02 1px,
                $gray08 5px
            );
            background-repeat: no-repeat;
            background-size: 99% 99%;
            background-position: center;
        }
    }
    .mdl-tooltip-notif {
        background-color: $gray08;
        color: $grayTextShadow;
        &::before{
            border-top: 10px solid $gray1;
        }
    }
.warning-row {
td {
    border-bottom: 2px solid $orange08;
}
}
.warning-val {
    color: $blue08
        /*background-color: $gray02;*/
}
.line-error:hover {
    background-color: $blue01;
}
}

#ttp-send-document-form{
    position: relative;
    width: 100%;
}
#modalError {
    display: none;
    color: $red08;
    font-size: 1.3rem;
    padding-bottom: 15px;
i {
    font-size: 1.3rem;
    position: relative;
    top: 3px;
    padding-right: 10px;
}
}

/* ---- ttp mini select ---- */
.ttp-mini-select {
    display: flex;
.drop-down {
    position: relative;
}
.arrow_drop_down {
    font-size: 1.5rem;
    position: relative;
    top: 1px;
    left: 3px;
}
.mdl-list {
    box-shadow: 0 1px 4px 2px rgba(0,0,0,.05),0 1px 1px -1px rgba(0,0,0,.12),0 24px 38px 3px rgba(0,0,0,.2);
    position: absolute;
    background: #fff;
    z-index: 1;
    top: 7px;
    display: none;
.mdl-list__item {
    color: $black06;
    transition: background-color .15s linear;
    background: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: 0px 20px;
    min-height: 35px;
}
.mdl-list__item:hover {
    background: $blue01;
}
}
}
/* ---- END ttp mini select ---- */
/* ---- Certification / Association bloc ---- */
.see-more {
    font-size: 0.8rem;
    color: $blue1;
    cursor: pointer;
}
.full-text {
    display: none;
}
.hide {
    display: none;
}
#add-certification-form {
.mdl-switch {
    margin-top: 20px;
}
}

#ttp-profile-association {
.ttp-profile-column__period {
    width: 18%;
}

}#ttp-profile-association {
.ttp-profile-column__period {
    width: 18%;
}
}

.ttp-profile-list {
    display: table;
    width: 100%;
    padding-bottom: 16px;
.tr-form {
    padding: 10px 10px 25px;
    background-color: $gray01;
}
.ttp-profile-row__content{
    border: 1px solid #F8F9FA;
    border-radius: 4px;
    &:hover {
        background-color: $alice-pass;
        cursor: pointer;
    }
}
.ttp-profile-row {
    display: table-row;
.ttp-profile-column {
    display:table-cell;
    padding: $padding2 $padding1;
    border-top: 1px solid $alice-pass;
    position: relative;
    font-size: 1.43rem;
    vertical-align: top;
}
.ttp-profile-column:first-child {
    width: 2%;
i {
    position: relative -5px * * *;
}
}
p {
    margin: 0;
}
.ttp-profile-column__title {
    color: $blue1;
.ttp-certifiant-name {
    font-weight: bold;
}
}
.ttp-certification-title {
span, i {
    display: inline-block;
    vertical-align: top;
    margin-left: 0;
}
}
.ttp-profile-column__period {
    width: 28%;
span:first-child {
    margin-right:5px;
}
span:last-child {
    margin-left:5px;
}

.ttp-address-fullname-container {
    padding: .5rem 0 !important;
    border: none !important;
}
}
.ttp-profile-column__detail {
    width: 30%;
    line-height: 1.2;
p {
    font-weight: bold;
}
.ttp-certification-numero {
    font-weight: 100;
    margin-left: $margin1;
}
.ttp-quote-bookmark {
    color: $orange1;
    font-size: 1.4rem;
}
.ttp-address {
    display : flex;
    span {
        padding-left: .5rem;
    }
}
}
.ttp-profile-column__description {
    font-weight: 100;
p {
    position: relative;
    color: $black04;
    font-size: 1.2rem;
.ttp-quote-description {
    position: absolute -8px * * -8px;
    color: $black02;
    font-size: 1rem;
}
}
}
.ttp-profile-row__edit-form .ttp-form-button{
    padding: 0 0 $padding2 $padding1;
}
}

.ttp-main-certif {
    background-color: $blue01;
}

.ttp-profile-row:first-child .ttp-profile-column {
    border-top: none;
}

textarea {
    font-size: 1rem;
    line-height: 1.5;
    padding: $padding1;
    margin-top: 5px;
}
}
/* ---- END Certification / Association bloc ---- */
.icon-custom {
    transition: border .15s linear;
    width: 29px;
    height: 29px;
    display: block;
    position: relative;
    cursor: pointer;
}
.icon-plus {
    border: 1px solid $blue1;
    border-radius: 50%;
    outline: none;
}
.icon-custom::before, .icon-custom::after {
    transition: background .15s linear;
    content: "";
    display: block;
    position: absolute 0 0 0 0;
    width: .072rem;
    height: 18px;
    background: #95959f;
    margin: auto;
}
.icon-plus::after {
    transform: rotate(90deg) translateZ(0);
}
.icon-minus::after {
    transform: rotate(90deg) translateZ(0);
    height: 14px;
}
.icon-minus::before {
    size: 0;
    background: transparent;
}

.icon-x::after {
    transform: rotate(45deg) translateZ(0);
}
.icon-x::before {
    transform: rotate(135deg) translateZ(0);
}
.icon-x.large {
    position: absolute;
    top: $margin2;
    right: $margin2;
}
.icon-x.large::after, .icon-x.large::before {
    height: 2rem;
    width: 1.5px;
}
.icon-x.large:hover:after, .icon-x.large:hover:before {
    width: 1.5px;
}
.mdl-spinner {
    display: none;
}
.mdl-spinner__layer.mdl-spinner__layer-1 {
    border-color: $gray1;
}
.mdl-spinner__layer.mdl-spinner__layer-2 {
    border-color: $blue1;
}
.mdl-spinner__layer.mdl-spinner__layer-3 {
    border-color: $orange1;
}
.mdl-spinner__layer.mdl-spinner__layer-4 {
    border-color: $blue1;
}
.mdl-cell--6-col,
.mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(46% - 16px);
}

.tooltip-help, .tooltip-error{
    color: $blue08;
}
.tooltip-valid{
    color: $green1;
}

.tooltip-help, .tooltip-error, .tooltip-valid{
    padding-right: 25px;
}
.tooltip-vas{
    background: none;
    color: $blue08 !important;
    border: 1px solid $blue08;
    height: 20px;
}
.ttp-label-vas {
    display: block;
    /* margin-top: .4em; */
    margin-right: .2em;
}


.mdl-tooltip {
    font-size: 1rem;
    max-width: 200px;
    padding: 8px 12px;
    background: $blue08;
    text-align: left;
    line-height: 1.5;
}


.mdl-textfield__label {
    font-size: .8rem !important;
    text-transform: uppercase;
    top: 4px;
}
.mdl-textfield:not(.is-invalid) .mdl-textfield__label
{
    color: $black04;
}
.mdl-textfield {
    width: 100%;
    outline: none;
}

.mdl-textfield__input {
    font-size: 20px;
    padding: 14px 0 11px;
    color: $black07;
}

input {
    height: 24px;
    line-height: 24px;
    outline: none;
}

.ttp-form .mdl-cell:first-child h4 {
    margin-top: 0;
}

.ttp-form .ttp-form-button {
a, button {
    margin-left:3px;
}
a {
    height: 36px !important;
}
}

.mdl-checkbox__box-outline {
    border: 1px solid rgb($main);
    size: 20px;
}
.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border: none;
}
.mdl-checkbox.is-indeterminate .mdl-checkbox__box-outline {
    border: none;
}
.mdl-checkbox.is-invalid .mdl-checkbox__box-outline {
    border-color: #d50000;
}
.mdl-checkbox__label {
    line-height: 30px;
}

#ttp-root {
.mdl-checkbox__tick-outline {
    mask: none !important;
}
.mdl-checkbox .mdl-checkbox__tick-outline {
    background-position: 10px 10px;
    background-repeat: no-repeat;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline{
    background-color: transparent;
    background-image:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz4KPHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMiAzMiIgaGVpZ2h0PSIzMnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjcuNzA0LDguMzk3Yy0wLjM5NC0wLjM5MS0xLjAzNC0wLjM5MS0xLjQyOCwwICBMMTEuOTg4LDIyLjU5bC02LjI4Mi02LjE5M2MtMC4zOTQtMC4zOTEtMS4wMzQtMC4zOTEtMS40MjgsMGMtMC4zOTQsMC4zOTEtMC4zOTQsMS4wMjQsMCwxLjQxNGw2Ljk5OSw2Ljg5OSAgYzAuMzksMC4zODYsMS4wMzksMC4zODYsMS40MjksMEwyNy43MDQsOS44MTFDMjguMDk5LDkuNDIxLDI4LjA5OSw4Ljc4NywyNy43MDQsOC4zOTdDMjcuMzEsOC4wMDYsMjguMDk5LDguNzg3LDI3LjcwNCw4LjM5N3oiIGZpbGw9IiM1MDgxYmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgaWQ9IkNoZWNrIi8+Cjwvc3ZnPgo=");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.mdl-checkbox.is-indeterminate .mdl-checkbox__tick-outline{
    background-image: radial-gradient(rgba($main, .8), rgba($main, .8));
    background-color: transparent;
    background-position: 2px 2px;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    border: inherit;
    height: 20px;
    display: inline-block;
    transition: opacity 1s;
}

.mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
    border: 1px solid $gray08;
}
.mdl-checkbox.is-disabled .mdl-checkbox__tick-outline {
    background: none;
}
}

/* DATE PICKER*/
.mddtp-picker .mddtp-picker__header {
    background-color: $blue1 !important;
}
.mddtp-picker__body .mddtp-picker__viewHolder .mddtp-picker__grid .mddtp-picker__tr span.mddtp-picker__cell:hover, .mddtp-picker__body .mddtp-picker__viewHolder .mddtp-picker__grid span.mddtp-picker__cell--selected {
    background-color: $orange1 !important;
}
.mddtp-picker-date .mddtp-picker__left:focus, .mddtp-picker-date .mddtp-picker__left:hover, .mddtp-picker-date .mddtp-picker__right:focus, .mddtp-picker-date .mddtp-picker__right:hover {
    background-color: $blue02 !important;
}
#mddtp-date__ok, .ttp-color {
    color: $blue1;
}
.mddtp-button:focus, .mddtp-button:hover {
    background-color: $blue01 !important;
}
#ttp-root .mdl-cell > h4, h1.ttp-bread-title {
    color: $blue1;
    font-size: 1.3em;
    font-weight: 300;
    height: 20px;
    line-height: 20px;
    padding-left: 22px;
    text-transform: uppercase;
    position: relative;
}

#ttp-root .ttp-form .mdl-cell > h4::before, h1.ttp-bread-title::before {
    width: 0;
    border-bottom: 13px solid #ff866a;
    border-right: 13px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    bottom: 4px;
}

#ttp-root .ttp-reset-password-container > h4{
    text-transform: capitalize;
    border-left: 0px;
    padding-left: 0px;
span {
    text-transform: none;
}
}

.mdl-checkbox.is-invalid .mdl-textfield__error {
    visibility: visible;
}
/* ------  Dir doc history ----- */
#doc-history{
.mdl-data-table td {
    text-align: left;
}
.mdl-data-table th {
    text-align: center;
}
.error {
    color:$red08;
}
.success {
    color:$blue08;
}
.warning {
    color:$orange1;
}
.pushed {
    color: $greenOn;
}
.waiting {
    color: $black04;
}
tr td:last-child {
    max-width: 40%;
    white-space: normal;
}
}

.ttp-datatable-action {
i {
    /* color: #F7953E; */
    color: #29394D!important;
    font-size: 1.5rem;
    margin: 0 $margin1;
    transition: color .5s;
}
.ttp-directory-cc {
    position: relative;
}
i:hover {
    color: $blueDark;
    opacity: .7;
}
}

.dataTables_empty{
    text-align:center !important;
}

/* ---- END Dir doc history ----- */
/* ------  Dir debug request ----- */
.code {
    margin-bottom: 40px;
/*overflow: hidden;*/
.codeBloc {
    padding: 10px 20px;
    background-color: #eee;
    overflow-x: auto;
    width: 115%;
.sf-dump {
    width: 180%;
    margin: 0;
    background-color: #eee;
}
}
}
/* ---- END Dir debug request ----- */


/*=====  ttp select  ====*/

.ttp-select {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $blue02;
    font-size: 20px;
    background-color: #fff;
    background-image: linear-gradient(45deg, transparent 50%, $blue08 60%), linear-gradient(135deg, $blue08 40%, transparent 50%), linear-gradient(to right, #fff, #fff);
    background-position: calc(100% - 10px) calc(1.4em), calc(100% - 5px) calc(1.4em), 100% 0;
    background-size: 5px 5px, 5px 5px, 1.5rem 2.5rem;
    background-repeat: no-repeat;
    appearance: none;
    height: 50px;
    cursor: pointer;
    outline: none;
    border-radius: 0;
}

.ttp-select.ttp-select-mini {
	width: auto;
	font-size: 1rem;
	height: 20px;
	background-position-y: 8px;
	color: $black04;
    border: 1px solid $blue06;
    padding-right: 20px;
}

.ttp-select.ttp-select--secondary {
    border-bottom-color: #D7DCE0;
    background-image: linear-gradient(45deg, transparent 50%, #D7DBE0 60%), linear-gradient(135deg, #D7DBE0 40%, transparent 50%), linear-gradient(to right, #fff, #fff);
}

.ttp-email-field > div {
    outline:none;
}

.pac-item{
    padding:10px 5px;
}
.pac-item:hover{
    background-color:$blue01;
}
.pac-item-selected{
    background-color:$blue01;
}

.ttp-filter-input {
    width: 132px;
    font-size: 0.85rem;
    height: 20px;
    background-position-y: 8px;
    border: 1px solid rgba($blue,.8);
    color: $black04;
    text-align: justify;
}

.ttp-default{
    background-color: #fff;
}

/*===  END  ===*/
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(251, 255, 232) inset;
    -webkit-text-fill-color: $black07 !important;
}

@keyframes height-expand {
    0% {
        opacity: 0;
        height:0;}
    100% {
        opacity: 1;
        height: 100%;}
}

@keyframes center-expand {
    0% {
        width:0px;
        opacity: 0;
        left: 50%;
        right: 50%;
    }
    100% {
        width: auto;
        opacity: 1;
        left: 0;
        right: 0; }
}

#ttp-root {

.ttp-channel__icon, .ttp-channel__icon-large {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    width: 20px;
    margin-right: $margin1;
}

.ttp-channel__icon-large {
    display: block;
    background-size: 60px;
    size: 64px 70px;
    margin: 0;
}

.ttp-channel__icon-large.billtobox, .ttp-channel__icon-large.billtoboxfiles {
    border-color: #4c6e6a;
    background-size: 73px;
}

.ttp-channel__icon.ftp, .ttp-channel__icon-large.ftp {
    background-image: url("/dist/assets/images/ftp.png");
}
.ttp-channel__icon.gmail {
    background-image: url("/dist/assets/images/gmail.png");
}
.ttp-channel__icon.mail, .ttp-channel__icon-large.mail {
    background-image: url("/dist/assets/images/mail.png");
}
.ttp-channel__icon.dropbox, .ttp-channel__icon-large.dropbox {
    background-image: url("/dist/assets/images/dropbox-l.png");
}
.ttp-channel__icon.onedrive, .ttp-channel__icon-large.onedrive {
    background-image: url("/dist/assets/images/onedrive-l.png");
}
.ttp-channel__icon.googledrive, .ttp-channel__icon-large.googledrive {
    background-image: url("/dist/assets/images/googledrive-l.png");
}
.ttp-channel__icon.billtobox, .ttp-channel__icon-large.billtobox {
    background-image: url("/dist/assets/images/btb-new-2.svg");
}
.ttp-channel__icon.billtoboxfiles, .ttp-channel__icon-large.billtoboxfiles {
    background-image: url("/dist/assets/images/btb-new-2.svg");
}
.ttp-channel__icon-large.coda {
    background-image: url("/dist/assets/images/coda-l.png");
}
.ttp-channel__icon-large.efff {
    background-image: url("/dist/assets/images/efff-l.png");
}

.ttp-channel__icon-large.billtoboxfilesvas {
    background-image: url("/dist/assets/images/btb-new-2.svg");
    background-size: 65px;
}
.ttp-channel__icon.billtoboxfilesvas {
    background-image: url("/dist/assets/images/btb-new-2.svg");
    background-size: 17px;
}
.ttp-channel__icon-large.oneavas {
    background-image: url("/dist/assets/images/onea-vas.png");
}
.ttp-channel__icon.oneavas {
    background-image: url("/dist/assets/images/onea-vas.png");
}
.ttp-channel__icon-large.yukivas {
    background-image: url("/dist/assets/images/yuki-vas.png");
    background-size: 90px;
    width: 100px;
}
.ttp-channel__icon-large.admvas {
    background-image: url("/dist/assets/images/adm_logo.png");
    /* background-size: 90px; */
    /* width: 100px; */
}
.ttp-channel__icon.yukivas {
    background-image: url("/dist/assets/images/yuki-vas.png");
}
.ttp-channel__icon.admvas {
    background-image: url("/dist/assets/images/adm_logo.png");
    background-size: 15px;
}

.ttp-channel {
    padding: $padding1;
input {
    box-sizing: content-box;

}
.mdl-tabs.is-upgraded .mdl-tabs__tab:after {
    size: 2px 0;
    display: block;
    content: " ";
    position: absolute 0 0 inherit inherit;
    background: $blue1;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
    animation: height-expand .2s cubic-bezier(.4,0,.4,1).01s alternate forwards;
}
.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
    background-color: $blue02;
}
.mdl-tabs {
    height: 600px;
}
.mdl-tabs__tab-bar.ttp-channel__bar {
    size: 28% 100%;
    position: relative;
    display: block;
    border: 1px solid $blue02;
    box-sizing: border-box;
    height: auto;
    > div{
      overflow-y: auto;
      height: 570px;
    }
.mdl-tabs__tab {
    float: none;
    padding: $padding1 5px;
    outline: none;

    height: 45px;
    box-sizing: content-box;
    border-bottom: 1px solid $blue02;
    text-transform: none;
}
.ttp-channel__bar-tab-name {
    text-align: left;
    line-height: normal;
span:first-child {
    font-size: $fontSize1-2;
    color: $black07;
}
span:nth-child(2) {
    font-size: 0.9rem;
}
}
.mdl-tabs__tab.is-active {
    background-color: $blue005;
}
.mdl-tabs__tab:hover{
    background: $blue005;
    transition: background .2s ease;
}
.ttp-channel_control {
    position: absolute * * 0 0;
    size: 100% 2rem;
    border-top: 1px solid $blue02;
    overflow: hidden;
.icon-custom {
    border: 0;
}
.icon-custom::before, .icon-custom::after {
    background: $blue1;
}

span, a:first-child {
    width: 2rem;
    border-right: 1px solid $blue02;
    outline: none;
}
span:hover, a:hover {
    background: $blue005;
    transition: background .2s ease;
}
span:active, a:active {
    background: $blue005;
    box-shadow: 0px 0px 4px 0px $blue02 inset;
}
}
}
.mdl-tabs__panel {
    padding-top: 0;
}
.ttp-channel__content {
    width: 80%;
section {
    width: 80%;
    margin: $margin2 auto;
}
}
.ttp-channel__ftp {
.port {
    width: 20%;
    margin-left: $margin3;
}
.port input {
    color: $gray1;
    text-align: center;
}
}

}
}

#ttp-root {

.ttp-channel-doc {
    text-align: center;
    margin: $margin3 0;
.ttp-channel__icon-large {
    background-size: 100px;
    size: 100px 74px;
}
.mdl-checkbox {
    width: 24px;
    margin-top: $margin1;
}
}
.ttp-channel__store {

.ttp-channel__icon-large {
    margin: auto;
}

.ttp-grid-start {
    flex-wrap: wrap;
}
.ttp-channel__store-product {
    position: relative;
    /* width: 18%; */
    width: 27%;
    height: 200px;
    padding: $margin2 1%;
    border: 1px solid transparent;
    margin-right: 40px;
    z-index: -1;

    .ttp-apps-validation-status-container {
        position: absolute;
        top: 2%;
        left: 86%;
    }
}

.ttp-channel__store-product:not(.ttp-app-disabled):hover {
    border: 1px solid $blue05;
    /*background: rgba($blue, .03);*/
}

.ttp-channel__store-product:not(.ttp-app-disabled):hover:after {
    size: 0 3px;
    display: block;
    content: " ";
    position: absolute * * -1px 0;
    background: $blue1;
    animation: center-expand .2s cubic-bezier(.4,0,.4,1).01s alternate forwards;
}

.ttp-channel__store-info {
    height: 54px;
    font-size: 1.3rem;
    text-align: center;
    color: $blue1;
    margin: $margin1 0;
div:first-child {
    margin-bottom: $margin1;
}
}

.ttp-channel__store-desc {
    text-align: center;
    font-size: 1rem;
    color: $black04;
    font-weight: 500;
}

.ttp-channel__store-purchase {
    position: absolute * 0% $margin2 0%;
    text-align: center;
}
}
}

#ttp-root {

.ttp-apply-channel {
article {
    width: 33.33333%;
    margin-bottom: $margin2;
}
article > div:nth-child(2) {
    margin: 0 $margin1;
}
}

.ttp-item-title {
    font-size: 1.3rem;
    /*color: $blue1;*/
}

.ttp-item-desc{
    font-size: 1rem;
    color: $black04;
    font-weight: 500;
}
}

#ttp-root {
.ttp-tabs {
    display: block;
.ttp-tabs__tab-bar+.ttp-tabs__panel {
    display:block;
}
}

.ttp-tabs__tab-bar {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    align-items: flex-start;
    height: 76px;
    padding: 0 0 0 $padding3;
    margin: 0;
    border-bottom: 1px solid $gray1;
}
.ttp-tabs__tab {
    text-decoration: none;
    size: 135px 75px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    color: $black07;
    outline: none;
    font-size: 0.85rem;
    border-radius: 0;
    border: 1px solid $gray1;
    border-right: none;
    overflow: visible;
    position: relative;
    z-index: 0;
    padding: 0;
    > div{
      background: $gray01;
      width: 100%;
      height: 52px;
    }
    &.is-active > div {
        background: $white1;
        z-index: 1;
    }
    span {
        display: block;
        line-height: normal;
        margin: auto;
    }
}
.ttp-tabs__tab-icon {
    background-repeat: no-repeat;
    background-size: 35px;
    size: 35px 31px;
    background-position: center;
    margin-right: $margin1;
}
.ttp-tabs__tab-icon.efff {
    background-image: url("/dist/assets/images/efff-l.png");
}
.ttp-tabs__tab-icon.efffs {
    background-image: url("/dist/assets/images/efff-l.png");
}
.ttp-tabs__tab-icon.coda {
    background-image: url("/dist/assets/images/coda-l.png");
}
.ttp-tabs__tab-icon.pdf {
    background-image: url("/dist/assets/images/pdf-2.png");
    background-size: contain;
    height : 36px;
}
.ttp-tabs__tab:last-child {
    border-right: 1px solid $gray1;
}
.ttp-tabs__tab.is-active:after {
    size: 0 2px;
    display: block;
    content: " ";
    position: absolute * * -1px 0;
    background: $white1;
    animation: center-expand .2s cubic-bezier(.4,0,.4,1).01s alternate forwards;
}
.ttp-tabs__panel {
    display: none;
    padding: $padding1 $padding1 $padding1 0;
}
#ttp-app-tabs-content{
    .ttp-tab-header-title{
        background: $blue05;
        color: $white1;
        padding: $padding0 0;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 13px;
    }
    .is-active .ttp-tab-header-title{
        background: $blue1;
    }
}


.ttp-margin-left {
    margin-left: 40px;
}

.ttp-icon-large {
    font-size: 2.3rem;
}

.ttp-icon-small {
    vertical-align: middle;
    font-size: 0.9rem;
}

.ttp-icon-vas-small {
    vertical-align: middle;
    font-size: 1.2rem;
    padding-bottom: .2em;
    padding-right: .3em;
}

.ttp-icon-has-followed-webinar {
    font-size: 1.5rem;
    vertical-align: bottom;
    color: $greenOn;
}

.ttp-icon-has-not-followed-webinar {
    /* font-size: 1.3rem;
    color: $orange08; */
    visibility: hidden;
}

.mdl-layout__container {
    height: auto;
    min-height:100%;
    /*position:relative;*/
    position:absolute;
}
}

dl {
    padding:0em;
}
dt {
    color: $black04;
    font-weight: 300;
    font-size: .8rem !important;
    text-transform: uppercase;
    padding-bottom: 10px;
}
dd {
    font-size: 20px;
    color: $black07;
    margin: 0 0 0 0px;
}
.dt-border-bottom{
    border-bottom: 1px solid $blue02;
}


.import-textarea {
    font-size: 12px;
    word-wrap: inherit;
    white-space: nowrap;
}

.import-open-dialog{
    width: 70%;
    height: 580px;
}

#ttp-root {
        .mdl-chipfield {
            cursor: text;
            padding-bottom: 0;
            /* margin-bottom: 20px; */
            border-bottom: 1px solid $blue02;
            .mdl-chip {
                margin: 2px;
            }
        }

        .mdl-chipfield .mdl-textfield__input {
            border-bottom: none;
            margin-left: 4px;
        }

        .mdl-chipfield:after {
            position: absolute;
            bottom: -1px;
            content: '';
            size: 10px 0;
            left: 50%;
            transform: translateX()-50%;
            transition-duration: .2s;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            background: $blue1;
            visibility: hidden;
        }

        .mdl-chipfield:hover:after, .mdl-chipfield:focus:after {
            size: 0 1px;
            display: block;
            content: " ";
            position: absolute * * -1px 0;
            background: $blue1;
            visibility: visible;
            animation: center-expand .6s cubic-bezier(.4,0,.4,1).02s alternate forwards;
        }

    #ttp-input-tag {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-wrap: wrap;
        padding: 10px 0 10px 10px;
        /* position: absolute; */
        display: flex;
        /* margin-top: -44px; */
        /* width: 47%; */
        .ttp-typeahead::placeholder {
            font-size: 1rem;
            color: $grayDark;
            font-weight: 300;
        }
        .inputs {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            .mdl-textfield__input {
                padding: 0 *;
            }
        }
        .ttp-typeahead-loader {
            top: 0;
        }
        &.minify{
             position: absolute;
             margin-top: -32px;
             width: 80rem;
             padding: 18px 0 4px 5px;
         }
    }

    .ttp-datatable-group {
        text-align: center;
        width: 420px;
    }

    .mdl-chipfield.mdl-textfield {
        max-height: inherit;
    }

    .ttp-chips_container {
        display:flex;
        flex-wrap:wrap;
        padding-right: 70px;
        justify-content: flex-end;
    }

    .ttp-editable-tag {
        transition: width ease .3s;
        button {
            display: none;
        }
        &:hover button{
             display: inline-block;
         }
    }

    .ttp-group-more {
        position: absolute 12px 0 * *;
        font-weight: 300;
        line-height: 24px;
        color: $grayDark;
        cursor: pointer;
    }
    .ttp-group-more:hover {
        color: #777;
        transition: all 0.25s ease;
    }
}

.ttp-group-more_before {
    font-size: 1.3rem;
}

.ttp-group-container_hidden {
    display: none;
    white-space: normal;
}

.mdl-chip .material-icons.state {
    position: absolute 50% * * 3px;
    font-size: 0.7rem;
    transform: translateY(-50%)
}

.mdl-chip.open .material-icons {
    color: $greenOn;
}

.mdl-chip.locked .material-icons {
    color: $grayDark;
    font-size: 0.8rem;
}

.mdl-chip.locked {
    color: $grayDark;
    border-color: $grayDark;
    .mdl-chip__action {
        display: none;
    }
}

.mdl-chip.locked:hover {
    background: transparent;
    color: $grayDark;
}

.mdl-chip {
    position: relative;
    height: 22px;
    line-height: 22px;
    border-radius: 12px;
    border: 1px solid $blue05;
    margin: 1px;
    padding-left: 15px;
    background: transparent;
    color: $blue1;
    transition: all .2s ease;
    .mdl-chip__text {
        font-size: 0.9rem;
        font-weight: 300;
    }
    .mdl-chip__action {
        position: relative;
        right: -2px;
        size: 18px;
        font-size: 10px;
        margin-left: 1px;
        opacity: 0.6;
        .material-icons {
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            color: $redOff;
            background: #fff;
            border-radius:50%;
        }
    }
}

.mdl-chip:hover {
    background: $blue1;
    color: #fff;
    transition: all .2s ease;
    .mdl-chip__action {
        opacity: .95;
    }
}

.ttp-filtered-tag{
    background-color : $orangeLite05 !important;
    border-color: $orangeLite !important;
    .mdl-chip__text {
        color: #777 !important;
    }
}

.ttp-filtered-tag.locked {
    .material-icons {
        color: #777;
    }
}

#ttp-root #ttp-top-nav {
    background: #fff;
    box-shadow: none;
    & > div {
        padding: 0;
      }
    #mainMenu {
        box-shadow: none;
    }
    nav {
        margin: auto;
    }
    .mdl-navigation .mdl-cell > a.ttp-landing-page-link-fiduciary {
        top: 17px;
    }
}

#ttp-root #ttp-top-nav .mdl-navigation .mdl-cell > a.ttp-landing-page-link-folder {
    top: 17px;
}

#ttp-root #ttp-top-nav .mdl-navigation .mdl-cell > a.ttp-landing-page-link-folder-without-organization {
    top: 0px;
}

#ttp-root #ttp-top-nav .mdl-navigation .mdl-cell > a.ttp-dir-logo-link {
    top: 0px;
    color: rgb(80,129,190);

/*    .ttp-logo::after {
        content: "Repertoire.be";
        font-size: 1.8em;
        letter-spacing: 1px;
        font-weight: 300;
        margin: 0 0 0 65px;
    }*/

    .ttp-logo {
        background: url(/dist/assets/images/wheretobill_logo.png) no-repeat;
        background-size: 150px;
        width: 150px;
        height: 48px;
    }
}
#ttp-fiduciary-social-networks {
    margin-top: 15px;
}

#ttp-profile-social-networks-header {
    margin-top: 175px;
    img {
        margin: 0 7px;
    }
}

.ttp-social-network-li-item{
    img{
        padding:2px;
        margin:5px;
        border:2px solid #FF866A;
    }
    i{
        vertical-align: middle;
    }
}

.ttp-unselectable-text{
    user-select: none;
}

.ttp-filtred-groups-list, .ttp-excluded-groups-list {
    color: $grayDark;
    display: flex;
    align-items: center;

    .ttp-groups-list-items {
        padding-left: 5px;
        display: inline-block;
        span {
            .mdl-chip__action {
                display: inline;
            }
        }
    }
    .ttp-groups-list-title {
        display: none;
    }
}

#ttp-root .nav-item .ttp-filter-buttons-wrapper {
    .ttp-button {
        &.ttp-filter-button {
            margin-right : -2px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-style: none;
        }
        &.ttp-filter-clear {
            margin-left : -2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            width: 30px;
            min-width: 30px;
            padding-left: 5px;
            padding-right: 2px;
        }
    }
}

.ttp-search-group{
    width: 80%;
    border:1px solid $gray;
}

.ttp-organization-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {
        position : relative;
        left: -18px;
    }
}

.ttp-emailing-config{
    margin: $margin1 $margin2;
    padding: $padding2;
    input[type='email']{
        display: inline-block;
        width: 93%;
    }
    .ttp-register-mail{
        background-color: #FFF9C4;
        padding: 16px;
        border-radius: 2px;
    }
    .error {
        color:$red08;
    }
    .success {
        color: $greenDark08;
    }
}

.ttp-talk-config {
    margin: 10px 20px;
    padding: 20px;

    .help {
        margin-top: 4px;
        color: #aaa;
        font-style: italic;
        display: block;
    }
}

#returnto {
	position: fixed;
    top: 15rem;
    right: 1rem;
    font-size: 2rem;
    color: transparent;

	.bubble {
		background-color: #373c44;
	    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.25);
	    color: #fff;
	    border-radius: 55%;
	    height: 3.8rem;
	    width: 3.8rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    transition: 0.5s all ease-in-out;
	    //transform: translateX(130%);
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	    &:hover {
	    	overflow: visible;
	    }
	}
	i {
		z-index: 1;
	}
	.bubble_msg {
		position: absolute;
	    right: 47%;
	    background-color: #373c44;
	    width: 12rem;
	    font-size: .9rem;
	    text-transform: uppercase;
	    text-align: center;
	    padding: 1.2rem 2rem;
	    border-radius: 2rem 0 0 2rem;
	    letter-spacing: 1px;
	}
}


.ttp-dir-transfer-upload-my-dropzone .dz-default.dz-message{
    font-weight: 300;
    letter-spacing: 0.085rem;
    font-size: 0.9rem;
    color:  $blue1;
    margin-top: 13%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.ttp-dir-transfer-upload-my-dropzone .dz-error-mark{
    display: none;
}

.ttp-dir-transfer-upload-my-dropzone .dz-success-mark{
    display: none;
}

.ttp-dir-transfer-upload-my-dropzone{
    border: solid;
    border-color : $blue1;
    background-color: $bluelight;
    width: 40%;
    height: 170px;
    margin-top: 7%;
    margin-bottom: 2%;
    border-width: 1px;
}

.ttp-centerd-header{
    margin: auto !important;
    text-align:center;
}

#ttp-root .ttp-card {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 4px 4px 20px rgba(194, 194, 194, 0.2);
    border-radius: 20px;
    padding: 3.5rem 7.5rem;
    width: 50%;
    margin: 2.5rem auto;
    opacity: .9;
    &__header {
        font-size: 30px;
        color: #29394D;
        margin: 0 0 3rem;
        text-align: center;
    }
    &__text {
        font-size: 16px;
    }
    &__body {
       padding-bottom: 1rem;
    }
    &__title {
        font-weight: bold;
        font-size: 1rem;
        margin: .8rem 0 .5rem;
    }
    &__actions {
        margin-top: 2rem;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #F1F2F4;
        padding: 2rem 0 0;
        .ttp-button {
            border-radius: 4px;
            &:hover {
                color: #fff;
            }
        }
        .danger {
            width: 50%;
        }
        .success {
            width: 70%;
            color: #02AF8E;
            border: 1px solid #06D9B1;
            &:hover {
                background: #06D9B1;
            }
        }
        .cancel {
            width: 25%;
            color: #6D7F92;
            border: 1px solid rgba(109, 127, 146, 0.5);
            &:hover {
                background: rgba(109, 127, 146);
            }
        }
    }
}

/*can be usefull later .. to manage many add-on apps config view in tabs dialog*/
#ttp-root .ttp-addon-apps-dialog {

    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;

    .vertical-mdl-tabs .mdl-tabs__tab-bar {
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-bottom: 35px;
        height: inherit;
        border-bottom: none;
        border-right: 1px solid rgba(10, 11, 49, 0.20);
    }

    .vertical-mdl-tabs .mdl-tabs__tab {
        width: 100%;
        line-height: 35px;
        box-sizing: border-box;
        letter-spacing: 2px;
    }

    .vertical-mdl-tabs .mdl-tabs__tab:hover {
      background: rgba(158, 158, 158, 0.34);
      color: black;
    }

    .vertical-mdl-tabs.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
        content: inherit;
        height: 0;
    }

    .vertical-mdl-tabs.mdl-tabs .mdl-tabs__tab {
        text-align: left;
    }

    .ttp-addon-apps-tabs {

        .ttp-addon-apps-tabs__tab-bar {
            margin-top: 25px;
        }

        .ttp-addon-apps-tabs__tab-bar_wrapper {

            background-color: rgba($blue, 0.05);

            .mdl-tabs__tab-bar {
                height: 150px;
                border-bottom: none;
                border-right: none;
                .mdl-tabs__tab {
                    line-height: 50px;
                    padding: 0 5px;
                    border-bottom: 1px solid $blue05;
                    border-left: 1px solid $blue05;
                    border-right: none;
                    background-color: $white1;
                }

                .mdl-tabs__tab.is-active {
                    border-left: 3px solid #ED462F;
                }

                .directory-tab-link .mdl-tabs__ripple-container.mdl-js-ripple-effect {
                    background: url(/dist/assets/images/wheretobill_logo.png) no-repeat;
                    background-size: 70%;
                    width: 100%;
                    line-height: 40px;
                    background-position-y: center;
                    background-position-x: center;
                }
                .billtobox-tab-link .mdl-tabs__ripple-container.mdl-js-ripple-effect {
                    background: url(/dist/assets/images/billtobox_logo.png) no-repeat;
                    background-size: 60%;
                    width: 100%;
                    line-height: 40px;
                    background-position-y: center;
                    background-position-x: center;
                }
                .auths-tab-link {

                }
            }
        }
    }
}
/*end*/

.ttp-roles-select_wrapper {
    padding: 7px 3px;
    .ttp-input-tag {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-wrap: wrap;
        padding: 5px 0 10px 10px;
        .ttp-typeahead::placeholder {
            font-size: 1rem;
            color: $grayDark;
            font-weight: 300;
        }
        .inputs {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            .mdl-textfield__input {
                padding: 0 *;
            }
            .ttp-typeahead-loader {
                top: -10px !important;
            }
        }

        .mdl-chip {
            padding-left : 10px;
        }

        .ttp-tag-list {
            max-height: 280px;
            overflow-y: auto;
        }

        .inputs__wrapper {
            display:flex;
            width: 100%;
            align-items: center;


            .ttp-roles-clear-selected-tags {
                border: 1px solid $redOff;
                border-radius: 50%;
                color: $redOff;
                font-size: 16px;
                flex-shrink: initial;
                height: 16px;
                opacity: .6;
                cursor: pointer;
                &:hover{
                    opacity: 1;
                    border-width: .1em;
                    background-color: $blue1;
                    border-color: $blue1;
                    color: #FFF;
                }
            }
        }

    }
}

.ttp-roles-dialog {
    .readonly-warning {
        margin-right: auto;
        color: #F7953E;

        i {
            font-size: 1rem;
        }
    }
}

.ttp-roles-list__wrapper {
    /* padding-top: 20px; */
    position: relative;
    .ttp-app-auths__wrapper {
        padding:5px;
        border-top: 1px solid rgba($blue, 0.52);
        span.ttp-item-title {
            line-height: 40px;
        }

        span.ttp-roles-title {
            padding-top: 30px;
        }
    }
    .ttp-roles-list {
        /*padding-left: 150px;*/
        border: 1px solid rgba($blue, 0.17);
        padding: 10px;
        padding-top: 0px;
        article {
            margin-top: 20px;

            .ttp-item-title {
                padding-top: .3em;
                font-size: 1.1rem !important;
            }

            /* ul {
                list-style-type: none;
            } */

            .ttp-auths-auths {
                ul {
                    list-style-type: none;
                }

                .mdl-tooltip {
                    max-width: 700px !important;
                    ul.ttp-role-info-app {
                        display: flex !important;
                    }
                    ul.ttp-role-info-app-auths {
                        list-style-type: initial;
                    }
                }
            }
        }
    }

    .ttp-warning-empty-select  {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        background-color: #FFF;
        opacity: .8;
        color: $blue1;

        .ttp-roles-warning-msg {
            display: inline-block;
            padding: .6em;
            /* padding-left: 3em; */

            button {
                display: block;
                margin-left: 20%;
                margin-top: 1em;
            }
        }
    }

}

.ttp-roles-title {
    padding-top: 30px;
    font-size: 1.4em;
}

.js-roles-select-users-tmpl, .js-roles-select-folders-tmpl {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.ttp-wheretobill-white-icon {
    background-image: url('/dist/assets/images/logo-WhereToBill-no-Text-White.png');
    background-repeat: no-repeat;
    background-position: $padding1 50%;
    background-size: 20px 16px;
    padding-left: $padding3;
    /*padding-right: $padding2;*/
    /*line-height: 45px;*/
    /*height: 45px;*/
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .5s linear;
    font-weight: 500;
}

.ttp-wheretobill-black-icon {
    background-image: url('/dist/assets/images/logo-WhereToBill-no-Text-Black.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 25px 20px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: background-color .5s linear;
    transition: background-color .5s linear;
    font-weight: 500;
    /* height: 60px; */
    display: inline-block;
    padding-left: 50px;
}
.ttp-billtobox-white-icon {
    background-image: url('/dist/assets/images/logo-BillToBox-no-Text-White.png');
    background-repeat: no-repeat;
    background-position: $padding1 50%;
    background-size: 17px 17px;
    padding-left: $padding3;
    /*padding-right: $padding2;*/
    /*line-height: 45px;*/
    /*height: 45px;*/
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .5s linear;
    font-weight: 500;
}

.ttp-billtobox-black-icon {
    background-image: url('/dist/assets/images/logo-BillToBox-no-Text-Black-2.svg');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: background-color .5s linear;
    transition: background-color .5s linear;
    font-weight: 500;
    /* height: 60px; */
    display: inline-block;
    padding-left: 50px;
}
.ttp-talk-white-icon {
    background-image: url('/dist/assets/images/logo-Tamtam-no-Text-White.png');
    background-repeat: no-repeat;
    background-position: $padding1 50%;
    background-size: 17px 17px;
    padding-left: $padding3;
    /*padding-right: $padding2;*/
    /*line-height: 45px;*/
    /*height: 45px;*/
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .5s linear;
    font-weight: 500;
}

.ttp-talk-black-icon {
    background-image: url('/dist/assets/images/logo-Tamtam-no-Text-Black.png');
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: background-color .5s linear;
    transition: background-color .5s linear;
    font-weight: 500;
    /* height: 60px; */
    display: inline-block;
    padding-left: 50px;
}

.ttp-role-info-app-auths {
    font-size: 1em;
    font-weight: 100;
    margin-left: 10px;
}


.margin-top-bic{
	margin-top: -14px;
}

.margin-left-bic{
left : 30px;
}

.margin-left-iban{
left : 30px;
}

.input-bic-css{
	width: 70%;
	margin-left: 7%;
	text-indent: 0% !important;
}

.input-iban-css{
	margin-left: 30px;
	text-indent: 0% !important;
}

#ttp-root .ttp-default-channel {
    & > h6 {
          font-weight : 300;
    }
    & > hr {
          margin: 0;
    }
    .mdl-radio__label {
        line-height: 30px;
    }
    h6 {
        display: inline;
        line-height: 30px;
        padding-right: 5px;
        padding-bottom: 0px;
        margin-right : 10px;
    }
    .mdl-radio {
        padding-bottom: 35px;
        padding-right: 5px;
        margin-top: 10px;
    }
    select.ttp-select {
        width : 50%;
    }
}

.ttp-box-center {
    display : flex;
    align-items: center;
    justify-content : center;
}

.ttp-25-hr{
    width: 25% !important;
}

#ttp-root .ttp-dir-top-nav {
    display: flex;
    align-items: center;
}

.ttp-align-item-center {
    align-items: center;
}

.ttp-justify-content-left {
    justify-content : left;
}

#apps-validation-status-help-:focus {
    outline: none !important;
    border:1px solid #fff;
}

article.ttp-app-disabled {
    background-color : $blue015;
    cursor: not-allowed;
    /* pointer-events: none; */
    opacity: .7;
    /* border : 1px solid $blue1 !important; */
    /* border-bottom: 2px solid $blue1 !important; */

    &:not(:last-child) {
         margin-right: 4px;
     }

    .ttp-app-checkbox {
        pointer-events: none;
    }
}

.ttp-position-relative {
    position : relative;
}

.ttp-position-absolute {
    position : absolute;
}

.ttp-zero-index {
    z-index : 0;
}

.ttp-margin-top-0 {
    margin-top : 0 !important;
}

.ttp-padding-bottom-0 {
    padding-bottom : 0;
}

.ttp-max-height-none {
    max-height: none !important;
}

label[tabindex="-1"] {
    outline: none;
}

.ttp-apps-validation-status-container-apply-config {
    position : absolute;
    top      : -30px;
    right    : -40px;
}

#ttp-root #ttp-footer.ubi-footer {
    background-color: #FFF;
    display: block;
    height: 200px;
    z-index:0;
    .ttp-grid-center {
        padding-bottom: 30px;
    }
}

#ttp-root .ubi-check-email-container {
    padding-top: 80px;
    text-align: center;
    width:400px;

    .ubi-gdbr-container {
        text-align: center;
        label {
            width: 250px;
        }
    }
}

.ubi-footer-app {
    width:10%;
    height: 80px;
    margin-top: auto;
    margin-left: 15px;
    margin-right: 2%;
    margin-left: 2%;
    background-repeat: no-repeat;
    max-width: 130px;
}

.ubi-footer-app.tamtam {
    background-image: url(/dist/assets/images/tamtam_logo.png);
    background-size: 100%;
    height: 29px;
}

.ubi-footer-app.billtobox {
    background-image: url(/dist/assets/images/logo_btb_2.png);
    background-size: 100%;
    height: 31px;
    width:8%;
}

.ubi-footer-app.ubi_and_be_accountants {
    background-image: url(/dist/assets/images/unified_by_invoicing_itaa.svg);
    background-size: 100%;
    width: 24%;
    height: 150px;
    margin-bottom: 20px;
    margin-right: 0;
    margin-left: 0;
    max-width: 340px;
}

.ubi-footer-app.wheretobill {
    background-image: url(/dist/assets/images/wheretobill_logo.png);
    background-size: 100%;
    height: 30px;
}

.ubi-footer-app.unified_post {
    background-image: url(/dist/assets/images/unified_post_logo.jpg);
    background-size: 100%;
    height: 40px;
    width:12%;
}

.ubi-onboarding-steps {
    display: flex;
    list-style-type: none;

    .ubi-step-item {
        display: flex;
        width: 170px;
        height: 56px;
        background-color: #345f8e;
        line-height: 56px;
        font-size: 1.2em;
        font-weight: 500;
        color: #FFF;
        margin-left: -25px;
        .ubi-step-text {
            background-color: #345f8e;
            width: 100%;
            text-align: center;
        }
    }

    .ubi-step-item::before {
        content: '';
        top: 0;
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 28px solid #FFF;
    }



    .ubi-step-item-elem {
        display: flex;
    }
    .ubi-step-item-elem.is-active {
        .ubi-step-text  {
            background-color: #95b393;
        }
        .ubi-step-item {
            background-color: #95b393;
        }
    }

    .ubi-step-item-elem::after {
        content: '';
        top: 0;
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 28px solid #345f8e;
        margin-right: .5em;
    }
    .ubi-step-item-elem.is-active::after {
        border-left: 28px solid #95b393;
    }

}

.ubi-fiduciary-validate {
    &__container {
        p {
            font-size: 20px;
            width: 600px;
            padding-right: 30px;
            padding-top:20px;
            text-align: center;
        }
    }
    &__title {
        text-align: center;
    }
}

.ubi_tuto {
        text-align: center;
        p {
            font-size: 20px;
            max-width: 600px;
            padding-right: 30px;
            padding-top:20px;
            text-align: center;
        }

        label {
            font-size: 18px;
            width: 600px;
            padding-right: 30px;
            padding-top:20px;
            padding-left: 30px;
            text-align: center;
        }

        #ubi_tuto_video__wrapper {
            width: 600px;
            text-align: center;
            padding-bottom: 30px;
            margin-bottom: 40px;
        }

    &__start_button {
        font-size: 1.2em !important;
        padding: .4em 1em;
        margin-top: 50px;
    }
    &__start_button:hover {
        background-color: white !important;
        color: $blue1 !important;
    }
}

.ttp-canal-fiduciary {
    flex-wrap: nowrap;

    article {
        padding: 20px 20px 20px 10px;
        flex: 1;

        & > div:nth-child(2) {
              margin: 0 10px;
        }
    }
}

.ttp-tabs__title {
    padding-right: 15px;

    & > h6 {
       margin-bottom: 0 !important;
       font-weight : 300;
    }

    & > hr {
       margin: 0;
    }
}


/*.ttp-subscriptions-list{*/
/*    &__item{*/
/*        .material-icons{*/
/*            font-size: 1.8rem;*/
/*        }*/
/*        &-primary{*/
/*            display: block !important;*/
/*        }*/
/*        &-subscription{*/
/*            padding: .7rem 0 0 4rem;*/
/*            li {*/
/*                background-color: #fbfbfb;*/
/*                margin: .7rem;*/
/*                height: auto;*/
/*                .mdl-list__item-primary-content{*/
/*                    height: auto;*/
/*                }*/
/*            }*/
/*        }*/
/*        .mdl-checkbox{*/
/*            height: 2.2rem;*/
/*        }*/
/*        .mdl-checkbox__label {*/
/*            margin-left: .3rem;*/
/*        }*/
/*    }*/
/*    .ttp-subscription-content{*/
/*        div{*/
/*            margin-top: .5rem;*/
/*            height: auto;*/
/*            font-weight: 300;*/
/*        }*/
/*        &:hover{*/
/*            transform: scale(1.05);*/
/*        }*/
/*        .note{*/
/*            font-size: .8rem;*/
/*            font-style: italic;*/
/*            margin-top: .8rem;*/
/*            font-weight: 300;*/
/*        }*/
/*        .mdl-checkbox.is-checked .mdl-checkbox__tick-outline{*/
/*            background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFXSURBVGhD7dTNSsQwEMDxelb89gX04EsslG3aJKBPJXj26qJrUmUvPoJ3EfET8Qn25ln0uk67GamNLtoeOsH5Qw4bAjM/ChtxHMdxHMdx/7xJNNc/VtvuV6ABQhh5KIx6TXPdc7eBhQirJuUJEgOIxKjBJ8IduHvJTrN594p4PyGsfBO5St0r4jGCSoygEiOoxAgqMaJpxVAr98RQbLqbdnX1JWDIwXSQGrfGdIYwcv/rwBaYrhDxebwAkGd/cANMVwhMD/UGDHvyF/gDpmsEVmBg8KO/yC8wVBBYPNpdh8Uf/IVmYKghMJGLtcTIe3+xbzBUEZg8kqvCyjt/wQpmBiI5kVn5hkK90c4KLHbrL6rGfau3gkBgsYmXYeGb+sLwd/1evyOLwNJBuiSMuq4vXj3kEViBgWWvgkZg5Zex6jJoBFbFBIvA9JleBMhF0AiO4ziuWVH0AVHKNz5/WR6GAAAAAElFTkSuQmCC") !important;*/
/*        }*/
/*    }*/
/*    .mandatory {*/
/*        color: hsl(122.7, 38.3%, 45.1%);*/
/*    }*/
/*    .see-all, .see-less {*/
/*        text-transform: uppercase;*/
/*        cursor: pointer;*/
/*        font-size: 1rem;*/
/*        margin-top: 1.5rem;*/
/*        color: #AAA;*/
/*        &:hover {*/
/*            color: $blue1;*/
/*            font-weight: 600;*/
/*        }*/
/*    }*/
/*}*/

.ttp-folder-langue_preferences{
    position: relative;
    &>div{
        padding-bottom: 0.5rem;
    }
    &_wrapper{
        display: flex;
        justify-content: space-between;
        height: 20px;
        cursor: pointer;
    }
    .tt-menu{
        z-index: 1;
        position: absolute;
        display: none;
    }
    .material-icons{
        font-size: 20px;
        color: $blue08;
    }
    .drop-up{
        display: none;
    }
    li{
        padding: .3rem 0;
        list-style-type: none;
    }
    &-label{
        padding-left: .5rem;
    }
}

.ttp-social-network-widget {
    margin-bottom: 20px;
    border: 0;
    background-color: #fff;
    border-radius: 4px;
    height:100%;
    min-height: 170px;
}

.ttp-facebook-grid .ttp-social-network-widget {
    color: #fff;
    background-color: #3b5998;
}

.ttp-twitter-grid .ttp-social-network-widget {
    color: #fff;
    background-color: #1da1f2;
}

.ttp-linkedin-grid .ttp-social-network-widget {
    color: #fff;
    background-color: #0077b5;
}

.ttp-facebook-pages-section,.ttp-linkedin-pages-section {
    background-color: #3a3f51;
    color: #fff;
    margin: 0px;
    padding: 3px;
    border-radius: 0px 0px 5px 5px;
}

.ttp-social-network-widget .ttp-social-network-cell-item {
    padding: 5px 0px 0px;
    position: relative;
}

.ttp-social-network-image {
    background-color: #e1e2e3;
    border: 1px solid #e1e2e3;
    padding: 2px;
    height: 50px;
    width: 50px;
}

.ttp-image-circle {
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

.ttp-image-square {
    border-radius: 1px;
}

.ttp-justify-content-center {
    display: flex;
    justify-content: center;
}

.ttp-align-items-center {
    display: flex;
    align-items: center;
}

.ttp-justify-content-right {
    display: flex;
    justify-content: flex-end;
}


.white-icon {
    color: #ffffff;
}

.ttp-white-link .material-icons, .ttp-white-link span {
    color: #fff;
}

.ttp-facebook-pages, .ttp-linkedin-pages {
    display: none;
}

.ttp-close-widget {
    display: none;
    position: absolute;
    right: 5px;
}


.mdl-cell--6-col-desktop-fix {
    width: calc(50% - 16px);
}

.ttp-multi-menu-margin{
    margin-right: 24px !important;
}

.ttp-member-email-phone{
    margin-bottom: 10px;
    padding: 0 .8rem .8rem;
    background-color: $bluelight;
    .mdl-textfield {
        padding:0;
    }
    .mdl-textfield:first-child {
        padding-top:20px;
    }
    .add-row {
        margin-top:20px;
        margin-bottom:10px; 
        margin-left:calc(50% - 15px);
        text-align:center;
    }
    .mdl-radio {
        .mdl-radio__outer-circle {
            width: 18px !important;
            height: 18px !important;
        }

        .mdl-radio__inner-circle {
            width: 12px !important;
            height: 12px !important;
        }    
    }
    .ttp-select.ttp-select-mini {
        float:right;
        clear:left;
    }

    &__row {
         max-height: none !important;
        select{
            margin-top: 1rem;
        }
    }
    &__item{
         display: flex;
         align-items: center;
         margin-top: .7rem;
         clear:right;
        label{
            margin-right: .3rem;
        }
        .tooltip-error{
            padding-right: 0;
        }
    }
}

.ttp-contacts-data-table{
    tbody tr{
        &:hover .ttp-member-actions{
            display: inline-block !important;
        }
        .ttp-actions{
            padding: 0;
            margin-left: 5px;
        }
    }
}

.ttp-ubi-card-container {
    margin-top: 100px;

    .ttp-ubi-card {
        height: 200px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue005;
        position: relative;

        .ttp-box-body {
            text-align: center;
            color: $blue1;

            span {
                position: absolute;
                left: 20px;
                bottom: 20px;
                font-size: 18px;
            }

            .ttp-subtitle {
                font-size: 55px;
                font-family: cursive, "Helvetica", serif;
                font-weight: 500;
            }
        }
    }
}

.ttp-landing-container {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
        margin-top: 30px;
        text-align: center;
        display: inline-block;
    }

    h4 {
        text-align: center;
    }

    .ttp-landing-buttons-container {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .ttp-landing-group {
            padding-bottom: 30px;
            margin: 0 10px;
            flex-direction: column;
            width: 332px;

            .ttp-landing-next-action {
                min-height: 30px;
                margin-top: 10px;
                span {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    font-size: 11px;
                    line-height: 14px;
                    text-align: left;
                    margin-bottom: 6px;

                    i {
                        color:$blue1;
                        margin-right: 5px;
                    }
                }
            }

            .ttp-landing-image-container {
                margin-bottom: 5px;
                width: 332px;
                height: 111px;
                display: flex;
                justify-content: center;

                img {
                    width : 100%;
                    height : 100%;
                    max-height: 111px;
                    cursor: pointer;
                    border-radius: 2px;

                    &.ttp-event-logo-svg {
                        width: 80%;
                        height: auto;
                    }

                    &.ttp-ubi-logo-svg {
                        width: 65%;
                        height: auto;
                        margin-left : 1px;
                    }
                }
            }

            a.ttp-blue-button {
                font-size: 1.2em !important;
                padding: .4em 0 !important;
                margin : 0 !important;
                width : 332px;

                &:hover {
                    background-color: white !important;
                    color: $blue1 !important;
                }
            }
        }
    }
}

.new-feature-box{
    position: absolute;
    margin-top: 0.7rem;
    border-radius: 4px;
    width: 25rem;
    z-index: 2;
    background-color: #fff;
    padding: 1.5rem 2rem;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    color: black;

    &__text{
        color: #606060;
        font-size: 1rem;
        margin: .5rem auto;
    }
    &__title{
        font-weight: 700;
        font-size: 1rem;
    }
    .ttp-button{
        margin-top: .3rem;
        float: right;
    }
}


  .tooltip__arrow {
    width: 50px;
    height: 25px;
    position: absolute;
    bottom: 100%;
    left: 30%;
    transform: translateX(-50%);
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 100%;
        left: 50%;
        box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.6);
    }
  }



.folders-transfer-ownership-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .folders-transfer-ownership-folder {
        flex: 1;
        text-align: center;

        input[name="folder_name"] {
            text-align: center;
        }
    }

    .folders-transfer-ownership-icon {
        flex: 1;
        text-align: center;
        color: #FF866A;
        display: flex;
        flex-direction: column;
    }

    .folders-transfer-ownership-owner {
        flex: 1;
        text-align: center;

        input[name="owner_name"] {
            text-align: left;
        }
    }
}

.ttp-folders-table .wtb-tooltip {
    position : relative;
    top : 7px;
}

.ttp-folders-table {
    table-layout: auto;
    word-wrap:break-word;
}

.ttp-open-in-browser {
    margin-right : .2em !important;
}

.ttp-browser-support {
    text-align    : center;
    background    : #f2dede;
    color         : #a94442;
    padding       : 15px 35px 15px 15px;
    border        : 1px solid #ebccd1;
    border-radius : 0;
    margin-bottom : 20px;
    font-weight   : lighter;
    font-size     : 1.8em;
    line-height   : 1.6;

    strong {
        font-weight: 700;
    }

    .ttp-browser-support-browsers img {
        width          : 350px;
        vertical-align : middle;
        border         : 0;
    }

    .ttp-browser-support-close {
        position         : relative;
        top              : -2px;
        right            : -21px;
        color            : inherit;
        padding          : 0;
        cursor           : pointer;
        background-color : transparent;
        border           : 0;
        float            : right;
        font-weight      : 700;
        line-height      : 1;
        text-shadow      : 0px 1px 0px #fff;
        opacity          : 0.2;
    }

}

.ttp-label-folders-with-badge.mdl-badge[data-badge]:after {
    background-clip : padding-box;
    background      : #ff866a;
    width           : 16px;
    height          : 16px;
    position        : absolute;
    top             : -5px;
    right           : 18px;
    font-size       : .6rem;
    padding         : 0 5px;
    line-height     : 16px;
}

.ttp-open-in-browser {
    margin-right : 16px;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.mdl-mega-footer {
    padding: 12px 40px 2px 40px;
}

#ttp-root .home-container {
    align-self: center;

    .auth-zone {
        width: 70%;
        display: flex;
        justify-content: center;
        font-family: Roboto;
        align-items: center;
        color: #29394D;
        padding: 1rem;
        margin: 1rem auto;
        @media (min-width: 768px) and (max-width: 1024px) {
            width: 90%;
        }

        @media (min-width: 100px) and (max-width: 767px) {
            width: 95%;
            flex-direction: column-reverse;
        }

        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 50px #F2F2F2 inset;
            -webkit-text-fill-color: #333;
        }

        input:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 50px #F2F2F2 inset;
            -webkit-text-fill-color: #333;
        }

        .left-section {
            min-width: 40%;
            display: flex;
            align-items: center;

            @media (min-width: 100px) and (max-width: 767px) {
                max-width: 90%;
            }

            .content{
                background: #5081BE;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 7rem 0;
                border-radius: 0.5rem 0 0 0.5rem;

                @media (min-width: 100px) and (max-width: 767px) {
                    border-radius: 0 0 0.5rem 0.5rem;
                    padding: 1rem 0;
                }

                .title {
                    font-weight: 500;
                    line-height: normal;
                    font-size: 1.5rem;
                    text-align: center;
                    color: #FFFFFF;
                    padding: 0 3rem;
                    @media (min-width: 100px) and (max-width: 767px) {
                        font-size: 1.1rem;
                    }
                }

                .devices-image {
                    width: 80%;
                    margin: 2rem 0;

                    img {
                        width: 100%;
                    }

                    @media (min-width: 100px) and (max-width: 767px) {
                        width: 60%;
                        margin: 0;
                    }
                }

                .link {
                    background: #F2F2F2;
                    border: 2px solid #5081BE;
                    border-radius: 50px;

                    a {
                        font-weight: 400;
                        line-height: 3rem;
                        font-size: 1rem;
                        padding: 0 2rem;

                        color: #5081BE;
                    }
                }
            }
        }

        .right-section {
            min-width: 60%;

            @media (min-width: 100px) and (max-width: 767px) {
                min-width: 100%;
            }

            .content {
                background: rgba(255, 255, 255, 0.95);
                padding: 5rem 3rem;
                border-radius: 0 0.5rem 0.5rem 0;

                @media (min-width: 320px) and (max-width: 767px) {
                    border-radius: 0.5rem 0.5rem 0 0;
                    padding: 1rem 2rem;

                    h1 {
                        font-size: 2em !important;
                    }
                }

                @media (min-width: 100px) and (max-width: 319px) {
                    border-radius: 0.5rem 0.5rem 0 0;
                    padding: 0.7rem 1rem;

                    h1 {
                        font-size: 1.5em !important;
                    }
                }

                .form {
                    .form-input {
                        background: #F2F2F2;
                        border: 2px solid rgba(239, 239, 239, 0.3);
                        box-sizing: border-box;
                        box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
                        border-radius: 0.4rem;
                        padding: 0;
                        margin: 1rem 0;
                        .input-component{
                            display: flex;
                            flex-direction: row;
                            input {
                                border-bottom: none;
                                font-size: 1.1rem;
                                padding: 0.8rem 0.9rem;
                                border-left: 1px solid #F8F9FA;
                            }

                            input::placeholder {
                                font-size: 1rem;
                                text-transform: uppercase;
                                color: #6D7F92;
                            }

                            .input-logo {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width:19%;
                                img {
                                    height: 1.3rem;
                                }
                            }
                        }
                    }

                    .form-action {
                        display: flex;
                        justify-content: center;

                        .action-button {
                            cursor: pointer;
                            background: #F49649;
                            border-radius: 50px;
                            border: none;
                            font-weight: 400;
                            line-height: 3rem;
                            font-size: 1rem;
                            padding: 0 5rem;
                            margin: 1.5rem 0;
                            color: #fff;
                            text-transform: uppercase;
                            #sign-in-spinner {
                                visibility:hidden;
                            }

                            .spinner {
                                animation: rotator 1.4s linear infinite;
                                height: 20px;
                                width: 20px;
                            }

                            .path {
                                stroke-dasharray: $offset;
                                stroke-dashoffset: 0;
                                transform-origin: center;
                                stroke: white;
                                animation:
                                    dash 1.4s ease-in-out infinite;
                            }

                        }
                    }

                    .help-text {
                        display: flex;
                        justify-content: center;

                        #forgot-password-button {
                            font-weight: normal;
                            font-size: 0.9rem;
                            text-align: center;
                            text-decoration-line: underline;
                            cursor: pointer;
                            color: #6D7F92;
                        }
                    }

                    .info-text {
                        display: flex;
                        justify-content: center;
                        margin-top: 4rem;
                        border-top: 1px solid #DFDFDF;
                        font-size: 0.9rem;
                        font-weight: normal;
                        line-height:1.5rem;

                        @media (min-width: 100px) and (max-width: 767px) {
                            margin-top: 1rem;
                            font-size: 0.8rem;
                        }

                        .text {
                            text-align: center;
                            padding: 0.5rem 0;
                            color: #6D7F92;
                        }

                        .link {
                            text-align: center;
                            color: #6D7F92;
                        }
                    }
                }
            }
        }
    }
}
.ttp-tour-theme {
    .shepherd-footer {
        .shepherd-buttons {
            .shepherd-button {
                background-color: inherit !important;
                border: 1px solid $blue1 !important;
                color: $blue1 !important;

                &:hover {
                    color: $white1 !important;
                    background-color: $blue1 !important;
                }
            }
        }
    }
    .shepherd-text {
        p {
            text-align: left;
        }
    }
}

.help-button {
    margin-left: -1px;
    height: 38px;
    min-width: 0px;
    color: $blue1;
    padding-left: 3px !important;
    padding-right: 3px !important;
    box-shadow: 0px 0px 5px rgba(41, 57, 77, 0.1);
    border: 0.5px solid rgba(109, 127, 146, 0.5);
    i {
        font-size: 1.1em;
    }
    &:hover {
        background-color: inherit;
    }
}

.ttp-disabled-vas-channel {
    /* background-color : $blue015; */
    cursor: not-allowed;
}

.ttp-auths-infos {
    .ttp-auths-matrix-wrapper {
        overflow: scroll;
        max-height: 600px;
    }
    .ttp-auths-data-table {
        tbody {
            td {
                text-align: center;
            }
            td:first-child {
                max-width: 400px;
                white-space: pre-line;
                text-align: left;
            }

            tr.app-title-row {
                background-color: $blue005;
                td:first-child {
                    font-weight: 700 !important;
                    font-size: 12px !important;
                    text-transform: capitalize;
                }
            }

            i.ttp-auths-allowed, i.ttp-auths-not-allowed {
                font-size: 1.7rem;
            }
            i.ttp-auths-allowed {
                color: $greenOnDark;
            }
            i.ttp-auths-not-allowed {
                color: $redOff;
            }
        }
    }
}

.ttp-disabled-vas-channel {
    /* background-color : $blue015; */
    cursor: not-allowed;
}

.w-100 {
    width: 100%;
    position: relative;
}

.m-l-1 {
    margin-left: 1rem !important;
}

.m-r-1 {
    margin-right: 1rem !important;
}

.flex-container {
    display: flex;
}

.flex-column {
    flex-direction: column;
}
.tippy-popper[x-placement^=top] .tippy-arrow {
    border-top: 8px solid #4f4f4f40 !important;
}
.tippy-popper[x-placement^=bottom] .tippy-arrow {
    border-bottom: 8px solid #4f4f4f40 !important;
}
.tippy-popper[x-placement^=right] .tippy-arrow {
    border-right: 8px solid #4f4f4f40 !important;
}
.tippy-popper[x-placement^=left] .tippy-arrow {
    border-left: 8px solid #4f4f4f40 !important;
}

@keyframes around {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.align-center {
    text-align: center;
}

#ttp-root .ttp-data-table .ttp-label {
    border-radius: 6px;
    color: #FFF;
    font-weight: 100;

    &.on {
        background-color: #62DEB0;
    }

    &.off {
        background-color: #B6BFC8;
    }
}

#ttp-root .ttp-subscription-container {
    min-height: 70vh;
    .ttp-subscription-item, .ttp-unsubscribe-item {
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 4px 4px 20px rgba(194, 194, 194, 0.2);
        border-radius: 20px;
        padding: 3.5rem 7.5rem;
        width: 45%;
        margin: 4.5rem auto;
        text-align: center;
        .ttp-form {
            text-align: left;
        }
        .error {
            color:$red08;
        }
        .success {
            color:$greenOn;
        }
        .ttp-card__header {
            margin-bottom: 2rem;
        }
        img {
            width: 140px;
            height: 140px;
            margin-bottom: 1rem;
            border-radius: 50%;
            border: 10px solid white;
            box-shadow: 2px 2px 10px 5px rgba(200,200,200,0.1);
        }
    }
}

.ttp-btn {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #6D7F92;
    min-width: 180px;
    height: 44px;
    margin: 10px;
    border: 1px solid rgba(109, 127, 146, 0.5);
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    background: #FFFFFF;
    outline: none !important;
    cursor: pointer;

    &.primary {
         color: #FFFFFF;
         border: none;
         background: #F7953E;
    }

    &.success {
         color: #FFFFFF;
         border: none;
         background: #06D9B1;
    }
}

.m-0 {
    margin: 0 !important;
}

.notification-tabs {
    .mdl-tabs__panel {
        border: 1px solid rgba(239,131,24, .2);
        border-top: none;
    }
    .mdl-tabs__tab-bar {
        justify-content: left;
    }
}

.score-range-input {
    border: 0;
    color: $blue1;
    font-weight: bold;
    width: 5rem;
    background: none;
}
#slider_range {
    width: 20rem;
}

.duplicate-duplicate {
    border: 1px solid #f1c242;
    border-radius: 6px;
    background-color:rgba(239,131,24, .035);
    margin-bottom: 5px;
    color: #999999;
    &.manual {
        background: repeating-linear-gradient(
            45deg,
            #fef4da,
            #fef4da 10px,
            #f8f2e0 10px,
            #f8f2e0 20px
        );
    }
    .close {
        position: relative;
        top: -15px;
        right: -7px;
    }
}

.duplicate-duplicate a.light .material-icons, .duplicate-main a.light .material-icons {
    color: #CCCCCC !important;
    font-size:16px;
}

.duplicate-main {
    border: 1px solid #78adef;
    border-radius: 6px;
    background-color:rgba(212, 231, 251, 0.035);
    margin-bottom: 5px;
}

.duplicate-duplicate .mdl-chip__text, .duplicate-main .mdl-chip__text {
    display: inline-block;
    width:2.5rem;
}


.duplicate-add {
    .form-container {

        display:flex;
        justify-content: flex-end;
        align-items: center;
        gap:10px;
        .search-input {
            width: 320px;
            border-radius: 30px;
            border: none;
            outline: none;
            margin: 0 -27px 0 0;
            color: #3C4E64;
            line-height: 20px;
            font-size: 14px;
        }
        
        .search-container {
            display: flex;
            background: #FFFFFF;
            border: 1px solid #E8EAED;
            -webkit-box-sizing: border-box;
            box-sizing: content-box;
            border-radius: 30px;
            height: 32px;
            width: 357px;
            -webkit-justify-content: space-around;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            transition: width .3s ease-in-out;
            overflow:hidden;
            &::before {
                content: url("/dist/assets/svg/search.svg");
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-left: .8em;
                overflow: visible;
                display: inline-block;
                z-index: 1;
                line-height: 0;
            }
        }
        .dup-error {
            color: $redOff;
            transition: width .3s ease-in-out;
        }
    }
}


@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
}




.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.ttp-filter-time .active {
    color:#cccccc;
    font-weight: 900;
}

.ttp-member-email-phone__row {
    .mdl-textfield__label:after {  
      bottom: 0px;
    }
} 


.activities-totals {
    text-align: right;
}

.activities-year {
    background-color:rgb(73, 125, 188);
    padding: 13px;
    font-size: 2rem;
    color: white;
    font-weight: 100;
    .price {
        float: right;
        font-size: .8em
    }
}

.activities-title {
    text-align: center;
    border-bottom: rgb(73, 125, 188) 1px solid;
}