$search-border-radius: 30px;
$search-box-width: 357px;
$search-input-width: 300px;
$search-box-height: 32px;

#ttp-data-table_wrapper {
    /* overflow: auto; */
    overflow: visible;
    th, td {
        color: #29394D;
        font-size: 14px;
        text-align: left;
    }

    .ttp-sc {
        margin-left: 4rem;
    }

    thead tr{
        background: #F1F2F4;
        border-radius: 4px;
    }
    tbody{
        border-radius: 2px;
        td {
            border-color: #fff;
            &:last-of-type {
                border-radius: 0 2px 2px 0;
                border-left: 4px solid #ffffff;
                text-align: center;
                padding: 2px;
            }
        }
        tr {
            &:nth-child(2n) {
                background: #FAFBFB;
            }
            &:nth-child(2n+1) {
                background: #F8F9FA;
            }
            &:hover {
                background: #FFFAF3;
            }
            &.selected, &.highlighted-row {
                background: #FFFAF3;
            }
        }
    }
    .dataTables_paginate {
        text-align: center;
        cursor: pointer;
        margin: 1rem auto;
        .paginate_button {
            text-transform: uppercase;
            border-radius: 40px;
            display: inline-block;
            padding: .5rem 1.5rem;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin: 0 .3rem;
            background: #F8F9FA;
        }
        span {
            .paginate_button {
                background: #FFFFFF;
                border: 1px solid #F1F2F4;
                box-sizing: border-box;
                border-radius: 50%;
                display: inline-block;
                padding: .5rem 1rem;
                color: #3C4E64;
            }
            .paginate_button:hover, .current {
                background: #EF8318;
                color: #fff;
            }
        }
        .previous {

        }
        .next {
        }
        .disabled {
            cursor: not-allowed;
            opacity: .7;
            color: rgba(109, 127, 146, 0.5);
        }
    }
    .ttp-data-table__top {
        display: flex;
        justify-content: flex-end;
        margin: 1rem 0;
        .dataTables_filter {
            display: flex;
            background: #FFFFFF;
            border: 1px solid #E8EAED;
            box-sizing: border-box;
            border-radius: $search-border-radius;
            height: $search-box-height;
            width: $search-box-width;
            justify-content: space-around;
            align-items: center;
            position: relative;
            &::before {
                content: url("/dist/assets/svg/search.svg");
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-left: .8em;
                overflow: visible;
                display: inline-block;
                z-index: 1;
                line-height: 0;
            }
            input {
                width: $search-input-width;
                border-radius: $search-border-radius;
                border: none;
                outline: none;
                margin: 0 -1rem 0 0;
                color: #3C4E64;
                line-height: 20px;
                font-size: 14px;
                &::placeholder {
                    color: #B7BDC3;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .dataTables_length select {
            background: #F1F2F4;
            height: $search-box-height;
            border-radius: $search-border-radius;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #3C4E64;
            width: 79px;
            padding: 0 1.5rem;
            background-image: -webkit-linear-gradient(45deg, transparent 50%, rgba(255,134,106, .8) 60%), -webkit-linear-gradient(315deg, rgba(255,134,106, .8) 40%, transparent 50%), -webkit-linear-gradient(left, #fff, #fff);
            background-image: url("/dist/assets/svg/polygon.svg");
            background-origin: content-box;
            background-position: right -1rem center;
            background-repeat: no-repeat;
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
            border: none;
            cursor: pointer;
            margin: 0 0 0 1.2rem;
            appearance: none;
            outline: none;
        }
    }

    .tr-disabled {
        text-decoration: line-through;
        background: repeating-linear-gradient( 45deg, #fff, #fff 1px, #F001 1px, #F001 10px) !important;
        td {
            opacity: .6;
        }
        td:last-of-type {
            opacity: 1;
        }
    }

    .ttp-contacts-data-table {
        th.actions {
            background: white;
            width: 44px;
        }
        tbody {
            border-radius: 2px;
            td {
                border-color: #fff;
                .actions {
                    border-radius: 0 2px 2px 0;
                    border-left: 4px solid #ffffff;
                    text-align: center;
                    padding: 2px;
                }
            }
        }
    }
    .ttp-data-table-actions {
        li {
            &:hover {
                background-color: #eee;
            }
            a {
                display: flex;
                align-items: center;

                i.material-icons {
                    margin-right: 6px;
                }
            }
        }
    }
}

.ttp-data-table {
    border-collapse: separate;
    white-space: normal;
    tbody td {
        border-bottom: hidden;
        font-weight: 500;
        font-size: 1rem;
        border-color: #fff;
        overflow: visible;
        padding: 14px 18px;
        max-width: 250px;
    }
    td:first-of-type, th:first-of-type {
        padding-left: 24px;
    }
    .ttp-status {
        display: inline-block;
        height: 20px;
        padding: .1em .5em;
    }
    .no-break {
        white-space: nowrap;
    }
}

#result_filter input{
    border: none;
    border-bottom: 1px solid $blue1;
    color: rgba(0, 0, 0, .4);
}

#ttp-data-table_wrapper tbody tr {
    cursor: pointer;
}
