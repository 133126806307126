.profile-fiduciary-cross-sharing-container {

    .cross-sharing__hr {
        height: 3px;
        border-color: rgba(255, 134, 106, .5);
    }

    .cs-apps-container {
        .cs-follow-auto {
            text-align: right;
        }

        .cs-apps-card {
            border-radius: 1rem;
            background-color: rgba(255, 134, 106, .1);
            border: 1px solid rgba(255, 134, 106, .3);
            min-height: 23rem;
            margin-bottom: 1.5rem;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 2rem;
            position: relative;

            .cs-apps__button.unfollow {
                opacity: 0;
            }
            i {
                position: absolute;
			    left: 1rem;
			    top: -.5rem;
			    font-size: 3rem;
			    color: rgba(255, 134, 106);
			}
            .apps-follow-reveal {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 0;
                background-color: #ffffff;
                transition: all .5s ease;
                border-radius: 1rem;
                padding: 2rem;

                &.hover-show, &.hide {
                    display: none;
                }

                form {
                    height: 100%;
                }

                .apps-follow-app-config-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                    justify-content: space-between;
                }

                .apps-follow-app-config {
                    display: flex;
                    justify-content: space-between;
                    padding: .5rem .5rem 0 0;
                }

                .apps-follow-app-config__checkbox label {
                    text-align: left;
                }
            }

            &:hover .apps-follow-reveal.hover-show {
                display: block !important;
            }
        }

        .cs-apps__avatar {
            width: 10rem;
            display: flex;
            height: 8rem;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        .cs-apps-area {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .cs-apps__name {
            font-size: 1.2rem;
            font-weight: bold;
            color: #000;
            text-align: center;
            margin-bottom: 2rem;
        }

    }

    .cs-activated-apps-with-scope-container {
    //justify-content: space-around;

        .cs-apps-with-scope__card {
        //color: #373c54; border-radius: 1rem; border: 0; background-color: #fff; margin-bottom: 1.5rem; //color: #000; text-align: center; padding: 2rem; display: flex; align-items: center; border: 1px solid rgba(255, 134, 106, .5);
        }

        .cs-scopes-selects {
            text-align: left;
    		color: #aaa;
    		margin-top: 1.2rem;
    		text-transform: uppercase;
    		font-size: .8rem;
    		&:first-child {
    			margin-top: 0;
    		}
    		.ttp-select {
    			height: auto;
    			font-size: 18px;
    			background-position: calc(100% - 10px) calc(.5em), calc(100% - 5px) calc(.5em), 10% 100%;
    			height: 22px;
    			text-transform: capitalize;
    		}
        }

        .cs-apps-with-scope__icon-with-appName {
            margin-right: 2rem;
        }

        .cs-apps-with-scope__appName {
            font-size: 1.1rem;
		    font-weight: 700;
		    text-transform: uppercase;
		    margin: 0;
		    color: rgb(255,134,106);
        }

        .cs-apps-with-scope__icon {
            font-size: 4.5rem;
            color: rgba(255, 134, 106, .8);
        }
    }

    .cs-general-scope {
        label {
            font-size: .9rem;
            color: #aaa;
            text-transform: uppercase;
        //color: #373c54;
        }
    }
}

#cross-sharing {
    h3 {
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 3rem;
    }
}

#ttp-root .ttp-config .ttp-config-head {
    .mdl-switch.is-checked .mdl-switch__track {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);
    }

    .portal-activated-buttons {
        display: flex;

        > a {
            width: 220px;
        }
    }

    #activate-portal-client-button.show-css-buttons, #deactivate-portal-client-button.show-css-buttons {
        position: relative;
        background-color: #FF9C86;
        color: #fff;
        overflow: visible;
        border-color: #FF9C86;

        &:after {
            content: "";
            background-color: #FF9C86;
            height: 20px;
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -15px;
        }
    }

    .portal-cross-sharing-buttons.show-css-buttons {
        min-height: 100px;
        background-color: #FF9C86;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 2rem 1rem;
        color: #FFF;
        border: 1px solid rgba(255, 134, 106, .8);
        border-radius: 4px;

        .action-buttons {
            padding: 5px;
            margin-left: auto;

            button {
                border: 1px solid #fff;
                color: #fff;
            }

            .cs-save {
                background: #fff;
                color: #FF9C86;
                font-weight: 600;
            }
        }
    }
}
