.networks > div {
  font-family: Roboto;
  background: #ffffff;
  border: 1px solid #d8dde2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
  .social-facebook {
    .social-title {
      color: #3b5998;
    }
    .social-icon {
      background: #3b5998;
    }
  }
  .social-twitter {
    .social-title {
      color: #55acee;
    }
    .social-icon {
      background: #55acee;
    }
  }
  .social-linkedin {
    .social-title {
      color: #007ab9;
    }
    .social-icon {
      background: #007ab9;
    }
  }
  hr {
    border-top: 1px solid #f1f2f4;
    margin: 20px 0;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 23px !important;

    color: #29394d;
  }
  .social-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .social-account {
    display: flex;
    height: 62px;

    background: #f8f9fa;
    border-radius: 5px;
    margin-bottom: 8px;
    align-items: center;
    &:hover {
      background-color: #f1f2f4;
      & .social-actions {
        visibility: visible;
      }
    }
    &__btn {
      margin-right: 22px;
      flex: 1;
      cursor: pointer;
      text-align: center;
      label {
        cursor: pointer;
        text-align: center;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        margin-left: 12px;

        color: #6d7f92;
      }
    }
    &.add-account {
      border: 1px solid #c7e8fe;
      box-sizing: border-box;

      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
    }
  }

  .social-icon {
    width: 52px;
    height: 62px;
    left: 175px;
    top: 327px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    margin-right: 22px;
  }
  .social-avatar {
    width: 46px;
    height: 46px;
    background: #6d7f92;
    border-radius: 50px;
    margin-right: 11px;
    img {
      width: 46px;
      height: 46px;
      border-radius: 50px;
    }
  }
  .social-name {
    font-style: normal;
    font-weight: 500;
    color: #29394d;
    font-size: 14px;
    line-height: 16px;
  }
  .social-actions {
    margin: 0 15px 0 auto;
    visibility: hidden;
    display: flex;
    .action-delete,
    .action-refresh {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #ffffff;

      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      margin-left: 10px;
    }
    .action-delete {
      border: 0.5px solid rgba(254, 55, 69, 0.2);
    }
    .action-refresh {
      border: 0.5px solid rgba(24, 160, 251, 0.2);
    }
  }
  .tooltip-refresh {
    border: 1px solid red;
    color: red !important;
    border-radius: 26px;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
  }
  .wrap-linked-pages {
    background: #f8f9fa;
    border-radius: 5px;
    margin-bottom: 15px;
    &.expanded .linked-pages {
      display: block;
    }
    .linked-pages {
      padding: 12px 18px;
      display: none;
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }
      .tile-leading {
        width: 40px;
        height: 40px;
        border: 1px solid #b2bcc6;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 18px;
        img {
          width: 38px;
          height: 38px;
          border-radius: 5px;
        }
      }
      .tile-title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        color: #29394d;
      }
    }
    .linked-page__btn {
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      color: #6d7f92;
      background: #f1f2f4;
      border-radius: 5px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .add-account {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &__btn {
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 0.5px solid #c7e8fe;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 4px;
    }
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      color: #6d7f92;
    }
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #29394d;
  color: #c7e8fe;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-weight: 300;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
