$crumbs-back:#F1F2F4;
$enabled-crumb-bg: #F3FAFF;
$active-crumb-bg: #F7953E;
$crumbs-height: 54px;
#ttp-stepper {
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    ul {
        list-style: none;
        margin: 0;
        display: inline-table;
        padding-left: 2px;
        li {
            display: inline;
            cursor: pointer;
            span {
                float: left;
                height: $crumbs-height;
                background: $crumbs-back;
                text-align: center;
                padding: 0 3.5rem;
                position: relative;
                margin: 0 5px 0 0;
                font-size: 16px;
                text-decoration: none;
                color: #2495E1;
                border-radius: 5px 0 0 5px;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                .step-number {
                    position: absolute;
                    top: 50%;
                    right: 4px;
                    transform: translateY(-50%);
                    height: 28px;
                    width: 28px;
                    border: 3.5px solid white;
                    border-radius: 50%;
                    font-size: 12px;
                    line-height: 26px;
                }

                &:after {
                    content: "";
                    border-top: calc($crumbs-height/2) solid transparent;
                    border-bottom: calc($crumbs-height/2) solid transparent;
                    border-left: calc($crumbs-height/2) solid $crumbs-back;
                    position: absolute;
                    right: calc(-$crumbs-height/2);
                    top: 0;
                    z-index: 1;
                }
                &:before {
                    content: "";
                    border-top: calc($crumbs-height/2) solid transparent;
                    border-bottom: calc($crumbs-height/2) solid transparent;
                    border-left: calc($crumbs-height/2) solid #fff;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
        li.active span {
            background: $active-crumb-bg;
            color:#fff;
            &:after {
                border-left-color: $active-crumb-bg;
            }
        }
        li.enabled span {
            background: $enabled-crumb-bg;
            color: #2495E1;
            &:after {
                border-left-color: $enabled-crumb-bg;
            }
        }
        li.disabled {
            cursor: not-allowed;
        }
    }
    button {
        background: #FFFFFF;
        border: 0.5px solid #18A0FB;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #18A0FB;
        text-transform: uppercase;
        padding: 0 1rem;
        height: $crumbs-height;
        margin-left: .8rem;
        cursor: pointer;
        outline: none;
        &:hover {
            box-shadow: none;
        }
        &.disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }
}
