$bg-empty-avatar: linear-gradient(to bottom right, #DDD, #CCC, #C5C5C5, #BBB, #B5B5B5, #AAA);

#mainMenu {
    font-style: normal;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
    a {
        font-weight: normal;
    }
    & > div {
        display: flex;
    }
    .menu__logo {
        cursor: pointer;
        width: 15rem;
        align-items: center;
        display: flex;
        min-height: 64px;
        position: relative;
        margin: 0 18px;
        .material-icons {
            padding: 16px 8px 16px 16px;
            color: #3C4E64;
        }

        img {
            height: 50px;
        }
        span {
            font-family: Raleway;
            font-size: 18px;
            margin-left: 10px;
            color: #29394D;
        }
    }
    .menu__community {
        padding: 0 1.5rem;
        width: 15rem;
        font-size: 16px;
        margin-left: 0;
        box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
        background: #F8F9FA;
        font-weight: 300;
        .logo {
            display: inline-block;
            height: 5rem;
            width: 90%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50%;
            margin-bottom: 20px;
        }
        .menu__dropdown {
            padding: 0;
        }
    }
    .menu {
        position: relative;
        cursor: pointer;
        .show-less {
            display: none;
        }
        .show-more {
            display: inline;
        }
        &__link {
            padding: 1.5rem 0rem;
            display: flex;
            justify-content: space-between;
            a {
                color: #3C4E64;
            }
        }
        &__dropdown {
            position: absolute;
            width: 100%;
            right: 0;
            top: 100%;
            box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
            border-radius: 0px 0px 4px 4px;
            margin: 0;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s all ease-in-out;
            padding: 0 2rem 1rem;
            background: #fff;
            z-index: 6;

            .menu__sub-child{
                padding: 1rem;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                align-items: center;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    border-bottom: 1px solid #F8F9FA;
                    bottom: 0;
                    left: 0.5rem;
                    right: 0.5rem;
                    position: absolute;
                }
                &:hover {
                    background: #F8F9FA;
                    color: #18A0FB;
                }
            }
            a {
                width: 100%;
            }
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        &:hover {
            background: #FFF;
            .menu__link {
                color: #18A0FB;
            }
            .menu__dropdown {
                opacity: 1;
                visibility: visible;
            }
            .show-more {
                display: none;
            }
            .show-less {
                display: inline;
            }
        }
    }
  
    .menu__language {
        display: flex;
        margin: 0;
        align-items: center;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: 0 0.5rem;
            border: 1px solid #C7E8FE;
            font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
            font-weight: normal;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.2s font-size ease-in-out;
            background: #fff;
            a {
                color: #18A0FB;
            }
            &.selected, &:hover {
                background-color: #18A0FB;
                a {
                    color: #FFF;
                }
            }
        }
    }

    .right__side {
        .notifications {
            margin: 0 3px 0 0;
            align-items: center;
            & > li {
                flex: 0 0 auto;
                align-items: center;
                border-radius: 50%;
                width: 2.8rem;
                height: 2.8rem;

            }
        }

        .notif {
            .menu__dropdown, .social-links__wrapper {
                right: calc(-75px - 49px);
            }
        }

        .menu__profile {
            padding: 0 0.5rem;
            margin: 0 1.3rem;
            &-img {
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 50%;
                overflow: hidden;
                background-size: cover;
                background-position: center;
                flex-shrink: 0;
            }
            .menu__dropdown {
                width: 23rem;
                padding: 0;
                background: #F1F2F4;
                top: calc(100% + .6rem);
                right: -5px;
                .menu__profile-img {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 0.5rem;
                    margin-left: 0.5rem;
                }
                li {
                    margin: 1rem 0.25rem;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    &:first-child {
                        align-items: center;
                        justify-content: flex-start;
                    }
                }
            }

            .menu__parameters {
                font-size: 12px;
                width: 100%;
                position: relative;
                li.action {
                    list-style-type: none;
                    margin: 0;
                    padding: 1rem .25rem;
                    background: #fff;
                    position:relative;
                    a {
                        text-transform: uppercase;
                        font-weight: 500;
                        color: #29394D;
                    }
                    &:hover {
                        background: #F8F9FA;
                        a {
                            color: #f7953e;
                        }
                    }
                    &::after {
                        content: "";
                        display: block;
                        left: .75rem;
                        right: .75rem;
                        bottom: 0;
                        height: 1px;
                        position: absolute;
                        background: #F8F9FA;
                    }
                }
                
                &::before {
                    content: "";
                    display: block;
                    left: 0;
                    right: 0;
                    height: 1px;
                    position: absolute;
                    background: #F8F9FA;
                    top: -10px;
                }
            }              
            .languages {
                background: #fff;
            }
            .info {
                color: #29394D;
                display: flex;
                flex-direction: column;
                text-align: left;
                .email {
                    font-size: .9rem;
                    color: #6D7F92;
                    text-transform: capitalize;
                }
            }
            .action {
                padding: 0.25rem;
                text-transform: uppercase;
                a {
                    color: #18A0FB;
                }
            }
        }
        .menu {
            display: flex;
            list-style: none;
            align-items: center;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            &>li {
                position: relative;
                transition: 0.2s all ease-in-out;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
            li:hover.expandable::after {
                content: ' ';
                position: absolute;
                bottom: 0px;
                z-index: 6;
                bottom: -.6rem;
                left: calc(50% - 7px);
                z-index: 6;
                background: transparent;
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
                border-bottom: 7px solid #F1F2F4;
            }
        }
        .apps {
            .menu__dropdown, .social-links__wrapper {
                right: -75px;
            }

            .social-links__body {
                background: #fff;
                & > ul {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 1.3rem;

                    li {
                        margin: .5rem 0;
                        padding: 0 0.5rem;
                        flex: 0 0 calc(100%/3);
                    }

                    .app-wrapper {
                        padding: .5rem;
                        border: 0.5px solid #EEF6FF;
                        box-sizing: border-box;
                        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.05);
                        border-radius: 4px;
                        text-align: center;
                        text-transform: none;
                        cursor: pointer;
                        display: block;

                        img {
                            width: 42px;
                            height: 42px;
                            padding-bottom: .5rem;
                        }

                        .app-name {
                            font-style: normal;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: center;
                            color: #3C4E64;
                        }

                        &:hover {
                            background: #F3FAFF;
                            box-shadow: none;
                            border: none;

                            .app-name {
                                color: #2495E1;
                            }
                        }

                        &.disabled {
                            opacity: 0.35;
                            cursor: inherit;
                        }
                    }
                }
            }
        }
    }
    .empty-avatar {
        background: $bg-empty-avatar;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
        span {
            color: #fff;
            font-size: 1.1rem;
        }
    }
    .social-links {
        a {
            color: #29394D;
        }
    }
    .signup {
        padding: 1.64rem 2rem;
        display: inline-block;
        font-weight: 600;
        transition: 0.2s all ease-in-out;
        text-transform: uppercase;
        color: #29394D;
        &:hover {
            color: #5081be;
        }
    }
}


#subMenu,
.sub-menu {
    background: #29394D;
    height: 44px;
    position: relative;

    &:not(.vertical) {
        padding-left: 1rem;
    }

    .flip-menu {
        cursor: pointer;
        position: absolute;
        top: calc(100% - 30px/2);
        left: 1rem;
        width: 30px;
        height: 30px;
        background: #29394D;
        color: #fff;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 50%;
        z-index: 1;
        display: flex;
        transition: transform .2s, background-color .2s;

        i {
            margin: auto;
            font-size: 20px;
        }

        &:hover {
            transform: rotateZ(-90deg);
        }
    }

    .menu {
        display: flex;
        list-style-type: none;
        margin: 0;
        li {
            align-items: center;
            color: #FFFAF3;
            font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
            font-weight: normal;
            height: 44px;
            overflow: hidden;

            &.ttp-menu-multi {
                position: relative;
                z-index: 5;
                .title {
                    display: flex;
                }

                .ttp-menu__dropdown {
                    padding: 0;
                    position: absolute;
                    z-index: 1;
                    display: none;
                    right: 0;
                    left: 0;
                    top: 44px;
                    .title {
                        text-transform: unset;
                    }
                    .menu__link {
                        background: #29394D;
                        &:hover,
                        &.active {
                            background: #3C4E64;
                        }
                    }
                }

                i.material-icons {
                    transition: transform .2s;
                    margin-left: 20px;
                }

                &:hover {
                    overflow: visible;
                    .ttp-menu__dropdown {
                        display: block;
                    }

                    i.material-icons {
                        transform: rotate(180deg);
                    }
                }
            }

            .menu__link {
                color: #FFFAF3;
                display: flex;
                align-items: center;

                .title {
                    padding-left: .5rem;
                    text-transform: uppercase;
                    font-weight: 300;
                    white-space: nowrap;
                }

                &:hover,
                &.active {
                    background: #3C4E64;
                }
            }

            a {
                padding: 0.75rem 25px;
            }

            .spliter {
                width: 0px;
                height: 44px;
                border: 1px solid rgba(109, 127, 146, 0.2);
            }
        }
        &.profile {
            .menu__link {
                color: #C7E8FE;
            }
        }
    }

    &.vertical {
        padding-top: 1rem;
        width: 4rem;
        justify-content: center;
        display: flex;
        flex-shrink: 0;
        transition: width .5s cubic-bezier(0, 0, 0.2, 1);
        z-index: 5;
        height: auto;

        .flip-menu {
            visibility: hidden;
        }

        .menu {
            flex-direction: column;
            width: 100%;
            overflow: hidden;
            min-height: calc(100vh - 70px);
            padding: 0;

            li {
                margin: .25rem 0;
                min-height: 2rem;
                width: 100%;

                .menu__link {
                    border-left: 3px solid transparent;
                    padding: .75rem 1rem;
                    .title {
                        opacity: 0;
                        visibility: hidden;
                        display: none;
                        white-space: nowrap;
                        width: 0;
                    }

                    &:hover,
                    &.active {
                        border-left: 3px solid #f7953e;
                        background: #3C4E64;
                    }
                }

                &.ttp-menu-multi {
                    .ttp-menu__dropdown {
                        .menu__link {
                            &:hover,
                            &.active {
                                border-left: 3px solid transparent;
                            }
                        }
                    }
                }
               
                &.spliter {
                    height: 0;
                    min-height: 0;
                }

                &.ttp-menu-multi {
                    height: auto;
                    .ttp-menu__dropdown {
                        position: relative;
                        top: unset;
                    }
                }
            }
        }

        &:hover {
            width: 17rem;
            justify-content: flex-start;

            .flip-menu {
                visibility: visible;
                left: calc(100% - 30px/2);
                top: .6rem;
                transform: rotateZ(-90deg);
                transition: transform .6s ease-out;

                &:hover {
                    transform: rotateZ(-180deg);
                    transition: transform .2s;
                }
            }

            .menu li .menu__link .title {
                opacity: 1;
                visibility: visible;
                display: flex;
            }
        }
    }
}