
/* Colors*/
$blue: 239,131,24;
$blue1: #F7953E;
$bluelight: rgba($blue, .035);
$blue005: rgba($blue, .05);
$blue01: rgba($blue, .1);
$blue015: rgba($blue, .15);
$blue02: rgba($blue, .2);
$blue05: rgba($blue, .5);
$blue08: rgba($blue, .8);
$blueDark: #5275a3;

/*$orange1Logo: #F29D1E;*/
$orange1: #fbbd19;
$orangeLite: #fbe8aa;
$orange08 : rgba(251,189,25, .8);

$red1: rgba(230,20,10, 1);
$redOff:hsl(11, 63%, 51%);
$redOffDark:hsl(11, 63%, 45%);
/*$red08: rgba(250,20,10, .8);*/
$red02 :rgba(244, 67, 54, .2);
$red04 :rgba(244, 67, 54, .4);
$red06 :rgba(244, 67, 54, .6);
$red08 :rgba(244, 67, 54, .8);

$green1: #99D199;
$greenDark: rgb(76, 175, 80);
$greenDark08: rgba(76, 175, 80, .8);
$greenOn: hsl(95, 46%, 53%);
$greenOnDark: hsl(95, 46%, 45%);

$white1: #FFF;
$whiteCyan: #c86c33;
$white04: rgba(255, 255, 255, .4);
$whitegray: #f5f5f5;

$gray1: #d5d5d5;
$grayDark: #aaa;
$grayTextShadow: #444;
$gray01: rgba(213, 213, 213, .1);
$gray02: rgba(213, 213, 213, .2);
$gray08: rgba(213, 213, 213, .8);

$black1: rgb(0,0,0);
$black01: rgba(0, 0, 0, .1);
$black02: rgba(0, 0, 0, .2);
$black04: rgba(0, 0, 0, .4);
$black06: rgba(0, 0, 0, .6);
$black07: rgba(0, 0, 0, .7);

/* size, padding, margin*/
$maxWidth: 1480px;

$topNavHeight: 40px;

$padding3: 40px;
$padding2: 20px;
$padding1: 10px;
$padding0: 5px;

$margin3: 40px;
$margin2: 20px;
$margin1: 10px;
$margin05: 5px;

/* font */
$lineHeight0: 44px;
$fontSize3: 3rem;
$fontSize2-5: 2.5rem;
$fontSize2: 2rem;
$fontSize1-2: 1.2rem;
$fontSize1-05: 1.05rem;
$fontSizeForm: 1.43rem;

/* button width */
$buttonWith0: 150px;
$buttonPadding: 6px;

/* admin */
$adminLinkBgc : #5081be;
$adminLinkColor: #FFF;

/* ttp labels color : warning, , success */
$ttp-label-success : $greenOn;
$ttp-label-warning : $orange1;
$ttp-label-danger : $red1;
$ttp-label-neutral : $grayDark;



// Gray blue colors
$pickled-bluewood: #29394D;
$fiord: #3C4E64;
$slate-gray: #6D7F92;
$cadet-blue: #B2BCC6;

// Gray colors
$mischka : #D7DBE0;
$athens-gray : #F1F2F4;
$athens-gray-lighter : #F8F9FA;
$black-haze : #F1F2F4;

// Blue colors
$curious-blue: #2495E1;
$dodger-blue: #18A0FB;
$french-pass: #C7E8FE;
$alice-pass: #F3FAFF;

// Red colors
$coral-red: #FE3745;
$persimmon: #FF5656;
$cornflower-lilac: #FFABAB;
$cosmos: #FFD5D5;

// Green colors
$persian-green: #02AF8E;
$caribbean-green: #06D9B1;
$magic-mint: #B0EED7;
$foam: #F1FEF9;

// Orange colors
$tango : #EF8318;
$sea-buckthorn: #F7953E;
$bridal-heath: #FFFAF3;
$bianca: #FCFAF2;

